/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateInvitedUserAccountCommandParams } from '../models/CreateInvitedUserAccountCommandParams';
import type { GetTeamMembersQueryResult } from '../models/GetTeamMembersQueryResult';
import type { InviteUsersCommandParams } from '../models/InviteUsersCommandParams';
import type { Organization } from '../models/Organization';
import type { RegisterOrganizationCommandParams } from '../models/RegisterOrganizationCommandParams';
import type { UpdateBrandingParams } from '../models/UpdateBrandingParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationUsersService {

  /**
   * Creates a new account during registration process
   * @param requestBody
   * @returns Organization Organization created
   * @throws ApiError
   */
  public static registerUser(
    requestBody: RegisterOrganizationCommandParams,
  ): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/register',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation failed`,
      },
    });
  }

  /**
   * Updates organization branding information
   * @param organizationId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static updateBranding(
    organizationId: string,
    requestBody: UpdateBrandingParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/{organizationId}/branding',
      path: {
        'organizationId': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation failed`,
      },
    });
  }

  /**
   * Creates an account for the invited user within organization
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static register(
    requestBody: CreateInvitedUserAccountCommandParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/createUserAccount',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized invitation code`,
        422: `Params validation error`,
      },
    });
  }

  /**
   * Retrieves team members of an organization
   * @param organizationId ID of the organization containing team members
   * @returns GetTeamMembersQueryResult Team members or 401 Unauthorized
   * @throws ApiError
   */
  public static getTeamMembers(
    organizationId: string,
  ): CancelablePromise<GetTeamMembersQueryResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/organizations/{organizationId}/users',
      path: {
        'organizationId': organizationId,
      },
      errors: {
        401: `Unauthorized`,
        403: `You are not an organization owner.`,
        404: `Organization does not exist.`,
      },
    });
  }

  /**
   * Sends the invitation emails with invitation link to a specified list of email addresses.
   * @param organizationId ID of the organization to which user invitations will be sent
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static inviteNewOrganizationUser(
    organizationId: string,
    requestBody: InviteUsersCommandParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/{organizationId}/users/invite',
      path: {
        'organizationId': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Parameters validation error`,
      },
    });
  }

  /**
   * Deletes the invitation with a given ID
   * @param id ID of the invitation to remove
   * @param organizationId ID of the organization which sent the invitation
   * @returns void
   * @throws ApiError
   */
  public static deleteInvitation(
    id: string,
    organizationId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organizations/{organizationId}/users/invitations/{id}',
      path: {
        'id': id,
        'organizationId': organizationId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Resends the invitation with a given ID
   * @param id ID of the invitation to resend
   * @param organizationId ID of the organization which sent the invitation
   * @returns void
   * @throws ApiError
   */
  public static resendInvitation(
    id: string,
    organizationId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/{organizationId}/users/invitations/{id}/resend',
      path: {
        'id': id,
        'organizationId': organizationId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Deletes the user with a given ID associated with organization ID
   * @param id ID of the user to remove
   * @param organizationId ID of the organization which the user is assigned to
   * @returns void
   * @throws ApiError
   */
  public static deleteOrganizationUser(
    id: string,
    organizationId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/organizations/{organizationId}/users/{id}',
      path: {
        'id': id,
        'organizationId': organizationId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}