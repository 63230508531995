import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'src/app/hooks';
import { useIdleTimer } from 'react-idle-timer';
import { Box, Dialog, DialogContent, DialogTitle, Button } from '@mui/material';
import { logoutAsync, clearState, selectUser } from './userSlice';
import { toast } from 'react-toastify';

const TIMEOUT = 60 * 60 * 1000; // 60 minutes
const PROMPT_BEFORE_IDLE = 30 * 1000; // 30 seconds

function IdleTimer() {
  const dispatch = useAppDispatch();
  const { authData } = useAppSelector(selectUser);

  const [remaining, setRemaining] = useState<number>(TIMEOUT);
  const [promptOpen, setPromptOpen] = useState<boolean>(false);

  const onIdle = async () => {
    setPromptOpen(false);
    await dispatch(logoutAsync());
    await dispatch(clearState());
    toast.info('You have been logged out due to inactivity.', {
      autoClose: 5000,
    });
  };

  const onActive = () => {
    setPromptOpen(false);
  };

  const onPrompt = () => {
    setPromptOpen(true);
  };

  const handleStillHere = () => {
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const { getRemainingTime, activate } = useIdleTimer({
    disabled: !authData,
    onIdle,
    onActive,
    onPrompt,
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    throttle: 500,
    crossTab: true
  });

  return (
    <>
      <Dialog
        open={promptOpen}
        onClose={handleStillHere}
        PaperProps={{
          sx: {
            width: '470px',
          },
        }}
        fullWidth
      >
        <DialogTitle sx={{ mt: 4, pb: 0 }}>Are you still here?</DialogTitle>
        <DialogContent sx={{ textAlign: 'center', mb: 2 }}>
          <p>Logging out in {remaining} seconds due to inactivity...</p>
        </DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 1,
            mx: 1,
            mb: 4,
          }}
        >
          <Button
            variant='contained'
            onClick={handleStillHere}
            id='idle-timer-prompt-still-here-button'
          >
            I'm still here
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

export default IdleTimer;
