import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IStateWithStatus } from 'src/app/hooks';
import { OrganizationsService } from '../../services/api';
import { HttpService } from 'src/services/httpService';

const initialState: IStateWithStatus = {
  status: 'idle',
};

export const registerAsync = HttpService.wrapApiAction(
  'registration/submitForm',
  OrganizationsService.registerUser
);

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.errors = undefined;
      state.errorMessage = undefined;
      return state;
    },
  },
  extraReducers: (builder) => {
    HttpService.registerApiAction(builder, registerAsync);
  },
});

export const { clearState } = registrationSlice.actions;
export const selectRegistration = (state: RootState) => state.registration;
export default registrationSlice.reducer;
