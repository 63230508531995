import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PaymentSummary from './PaymentSummary';
import { PaymentComponent } from 'src/features/delivery/payment/Payment';
import useStyles from 'src/features/dashboard/Dashboard.styles';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  selectPayment,
  setParcelIds,
  setPaymentSuccess,
  setPaymentLoading,
  clearState,
  createPaymentIntentAsync,
} from 'src/features/delivery/payment/paymentSlice';
import { markReadyForCourierAsync } from 'src/features/dashboard/draft-orders/draftOrdersSlice';

export interface PaymentDialogProps {
  parcelsIds: string[] | null;
  onClose: () => void;
}

export const PaymentDialog = (props: PaymentDialogProps) => {
  const { payment, paymentStatus, paymentIntentStatus, parcels } =
    useAppSelector(selectPayment);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  useEffect(() => {
    if (paymentIntentStatus === 'failed') {
      toast.error(`Could not process the payment`, { hideProgressBar: true });
      dispatch(clearState());
    }
  }, [paymentIntentStatus]);

  const onPriceConfirmed = () => {
    if (props.parcelsIds) {
      dispatch(setPaymentLoading());
      setPaymentDialogOpen(true);
      dispatch(setParcelIds(props.parcelsIds));
      dispatch(createPaymentIntentAsync({ ids: props.parcelsIds }));
    }
  };

  const onPaymentSubmitSuccess = () => {
    toast.success('Payment succeeded. Dispatching..', {
      hideProgressBar: true,
    });
    dispatch(setPaymentSuccess());
    setPaymentDialogOpen(false);
    props.onClose();
    history.push('/dashboard');
  };

  const onPaymentSubmitError = (error?: string) => {
    dispatch(clearState());
    setPaymentDialogOpen(false);
    toast.error(`Could not process the payment: ${error}`, {
      hideProgressBar: true,
    });
  };

  const onPaymentDialogClose = () => {
    dispatch(clearState());
    setPaymentDialogOpen(false);
    props.onClose();
  };

  const onPaymentDialogCancel = () => {
    dispatch(clearState());
    setPaymentDialogOpen(false);
  };

  if (props.parcelsIds === null) return null;

  return (
    <>
      <Dialog
        open={!!props.parcelsIds}
        onClose={props.onClose}
        PaperProps={{
          sx: {
            minWidth: '400px',
            maxWidth: '470px',
            background: 'transparent',
          },
        }}
      >
        <DialogContent sx={{ margin: '0 !important' }}>
          <IconButton
            aria-label='close'
            onClick={props.onClose}
            className={classes.dialogExitIcon}
            sx={{ position: 'absolute', marginTop: -1 }}
          >
            <CloseIcon />
          </IconButton>
          <PaymentSummary
            parcelsIds={props.parcelsIds}
            disableSubmit={paymentStatus === 'loading'}
            onSubmit={onPriceConfirmed}
            salesAgreementSigned={false}
          />
        </DialogContent>
      </Dialog>
      <PaymentComponent
        onSuccess={onPaymentSubmitSuccess}
        payment={payment}
        onError={onPaymentSubmitError}
        paymentDialogOpen={paymentDialogOpen}
        onPaymentDialogClose={onPaymentDialogClose}
        onCancel={onPaymentDialogCancel}
      />
    </>
  );
};
