import {
  InviteUsersCommandParams,
  OrganizationUsersService,
  UpdateBrandingParams,
} from 'src/services/api';

export namespace OnboardingService {
  export interface BrandInformationRequest extends UpdateBrandingParams {
    organizationId: string;
  }

  export async function updateBrandInformation(
    request: BrandInformationRequest
  ): Promise<void> {
    return await OrganizationUsersService.updateBranding(
      request.organizationId,
      request
    );
  }

  export interface InvitationsRequest extends InviteUsersCommandParams {
    organizationId: string;
  }

  export async function sendInvitations(
    request: InvitationsRequest
  ): Promise<void> {
    return await OrganizationUsersService.inviteNewOrganizationUser(
      request.organizationId,
      request
    );
  }
}
