import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { HttpService } from 'src/services/httpService';
import { WarehouseService } from 'src/services/warehouseService';
import { OnboardingService } from './onboardingAPI';

type StateStatus = 'idle' | 'loading' | 'failed' | 'succeeded';

export interface OnboardingState {
  teamMembersCount: number;
  canSendInvitations: boolean;
  status: StateStatus;
  errorMessage?: string | undefined;
  errors?: any | undefined;
}

const initialState: OnboardingState = {
  teamMembersCount: 0,
  canSendInvitations: false,
  status: 'idle',
  errorMessage: undefined,
  errors: undefined,
};

export const updateBrandInformationAsync = HttpService.wrapApiAction(
  'onboarding/updateBrandInfo',
  OnboardingService.updateBrandInformation
);

export const createWarehouseAsync =
  WarehouseService.createWarehouseAsync('onboarding');

export const sendInvitationsAsync = HttpService.wrapApiAction<
  void,
  OnboardingService.InvitationsRequest
>('onboarding/sendInvitations', OnboardingService.sendInvitations);

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateTeamMembersCount: (state, action: PayloadAction<number>) => {
      state.teamMembersCount = action.payload;
      state.canSendInvitations = action.payload > 0;
    },
    clearState: (state) => {
      state.status = 'idle';
      state.errors = undefined;
      state.errorMessage = undefined;
      return state;
    },
  },
  extraReducers: (builder) => {
    HttpService.registerApiAction(builder, updateBrandInformationAsync);
    HttpService.registerApiAction(builder, createWarehouseAsync);
    HttpService.registerApiAction(builder, sendInvitationsAsync);
  },
});

export const { updateTeamMembersCount, clearState } = onboardingSlice.actions;
export const selectOnboarding = (state: RootState) => state.onboarding;
export default onboardingSlice.reducer;
