import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  AnyAction,
  Reducer,
} from '@reduxjs/toolkit';
import userReducer, { loadSessionAsync } from 'src/features/user/userSlice';
import registrationReducer from 'src/features/user/registrationSlice';
import deliveryReducer from 'src/features/delivery/deliverySlice';
import appUserReducer from 'src/features/user/appUserSlice';
import draftOrdersReducer from 'src/features/dashboard/draft-orders/draftOrdersSlice';
import paymentReducer from 'src/features/delivery/payment/paymentSlice';
import newDeliveryReducer from 'src/features/delivery/newDelivery/newDeliverySlice';
import editDeliveryReducer from 'src/features/delivery/editDelivery/editDeliverySlice';
import onboardingReducer from 'src/features/onboarding/onboardingSlice';
import warehouseReducer from 'src/features/warehouse/warehouseSlice';
import settingsReducer from 'src/features/settings/settingsSlice';
import acceptInvitationReducer from 'src/features/acceptInvitation/acceptInvitationSlice';
import organizationReducer from 'src/features/organization/organizationSlice';
import filterReducer from 'src/features/dashboard/filters/filtersSlice';

const combinedReducer = combineReducers({
  user: userReducer,
  registration: registrationReducer,
  delivery: deliveryReducer,
  draftOrders: draftOrdersReducer,
  payment: paymentReducer,
  appUser: appUserReducer,
  newDelivery: newDeliveryReducer,
  editDelivery: editDeliveryReducer,
  onboarding: onboardingReducer,
  warehouse: warehouseReducer,
  settings: settingsReducer,
  acceptInvitation: acceptInvitationReducer,
  organization: organizationReducer,
  filter: filterReducer,
});

const rootReducer: Reducer = (
  state: RootState | undefined,
  action: AnyAction
) => {
  if (action.type === 'logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

store.dispatch(loadSessionAsync());

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
