import { Address } from 'src/services/api';
import { PlaceType } from './types';

export const getLatLng = async (
  request: google.maps.GeocoderRequest
): Promise<{ lat: number; lng: number } & any> => {
  const geocoder = new window.google.maps.Geocoder() as google.maps.Geocoder;
  const { results } = await geocoder.geocode(request);

  if (results?.length === 0) return null;

  const lat: number = results[0].geometry.location.lat();
  const lng: number = results[0].geometry.location.lng();

  //TODO: unwind this piece of code
  const address = results[0].address_components.reduce(
    (seed: any, { long_name, types }: { long_name: string; types: any[] }) => {
      types.forEach((t) => {
        seed[t] = long_name;
      });

      return seed;
    },
    {}
  );

  return {
    lat,
    lng,
    ...address,
    formattedAddress: results[0].formatted_address,
  };
};

export const getPlaceTypeFromLatLng = async (
  oldAddress: Address
): Promise<PlaceType | null> => {
  const requestedLocation = {
    location: {
      lat: parseFloat(oldAddress.latitude as unknown as string),
      lng: parseFloat(oldAddress.longitude as unknown as string),
    },
    address: `${oldAddress?.line1}, ${oldAddress?.state} ${oldAddress?.postcode} ${oldAddress?.city} ${oldAddress?.country}`,
  };

  if (
    isNaN(requestedLocation.location.lat) ||
    isNaN(requestedLocation.location.lng)
  ) {
    console.error('Invalid geocoder data.');
    return null;
  }

  const geocoder = new window.google.maps.Geocoder() as google.maps.Geocoder;
  const results = (await geocoder.geocode(requestedLocation)).results;

  if (results?.length === 0) return null;

  const streetAddress = results.filter(
    (r) => r.types.filter((t) => t === 'street_address').length > 0
  );
  const result = streetAddress.length > 0 ? streetAddress[0] : results[0];

  //TODO: unwind this piece of code
  const address = result.address_components.reduce(
    (seed: any, { long_name, types }: { long_name: string; types: any[] }) => {
      types.forEach((t) => {
        seed[t] = long_name;
      });

      return seed;
    },
    {}
  );

  return {
    lat: oldAddress.latitude,
    lng: oldAddress.longitude,
    ...address,
    formattedAddress: result.formatted_address,
    description: result.formatted_address,
    location: {
      ...address,
      lat: oldAddress.latitude,
      lng: oldAddress.longitude,
      apartment: oldAddress.line2,
    },
  };
};
