import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import {
  CreatePaymentIntentResponse,
  CreatePaymentIntentCommandParams,
  PaymentsService,
} from 'src/services/api';
import { HttpService } from 'src/services/httpService';

type PaymentIntentStatus = 'pending' | 'failed' | 'succeeded';
type OperationStatus =
  | 'idle'
  | 'loading'
  | 'failed'
  | 'succeeded'
  | 'pendingPayment';

export interface PaymentState {
  parcels: string[];
  paymentStatus: OperationStatus;
  paymentIntentStatus: PaymentIntentStatus;
  errorMessage?: string | undefined;
  errors?: any | undefined;
  payment?: CreatePaymentIntentResponse;
}

const initialState: PaymentState = {
  parcels: [],
  paymentStatus: 'idle',
  paymentIntentStatus: 'pending',
  errorMessage: undefined,
  errors: undefined,
};

export const createPaymentIntentAsync = HttpService.wrapApiAction(
  'payment/createPaymentIntent',
  PaymentsService.getPaymentIntent
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    clearState: (state) => {
      state.parcels = [];
      state.paymentStatus = 'idle';
      state.errors = undefined;
      state.errorMessage = undefined;
      state.payment = undefined;
      return state;
    },
    setParcelIds: (state, action: PayloadAction<string[]>) => {
      state.parcels = action.payload;
      return state;
    },
    setPaymentLoading: (state) => {
      state.paymentStatus = 'loading';
      return state;
    },
    setPaymentSuccess: (state) => {
      state.paymentStatus = 'succeeded';
      state.errors = undefined;
      state.errorMessage = undefined;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createPaymentIntentAsync.pending, (state) => {
        state.payment = undefined;
        state.paymentIntentStatus = 'pending';
      })
      .addCase(createPaymentIntentAsync.fulfilled, (state, { payload }) => {
        state.paymentIntentStatus = 'succeeded';
        state.payment = payload;
      })
      .addCase(createPaymentIntentAsync.rejected, (state) => {
        state.paymentIntentStatus = 'failed';
      });
  },
});

export const {
  clearState,
  setParcelIds,
  setPaymentSuccess,
  setPaymentLoading,
} = paymentSlice.actions;
export const selectPayment = (state: RootState): PaymentState =>
  state.payment as any;
export default paymentSlice.reducer;
