import React, { useEffect, useState } from 'react';
import {
  GetPodByTrackingIdQueryResult,
  TrackingService,
} from 'src/services/api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { PodContent } from '../delivery/components/PodContent';

export default function PodWithoutAuth() {
  const { id } = useParams<{ id: string }>() || '';
  const [podData, setPodData] = useState<GetPodByTrackingIdQueryResult>();

  const fetchPodData = async (id: string) => {
    try {
      const podObject =
        await TrackingService.getProofOfDeliveryByTrackingId(id);
      podObject && setPodData(podObject);
    } catch (e: any) {
      console.error('Error', e.message);
      toast.error(
        `Could not get proof of delivery: ${e.message.toLowerCase()}`,
        { hideProgressBar: true }
      );
    }
  };

  useEffect(() => {
    if (id) {
      fetchPodData(id);
    }
  }, [id]);

  return <>{podData && <PodContent pod={podData} />}</>;
}
