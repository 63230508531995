import { makeStyles } from '@mui/styles';
import { IStyleCustomization } from 'react-dropzone-uploader/dist/Dropzone';

export default makeStyles((theme) => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 695,
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: 0,
    outline: 0,
    padding: '30px 35px',
  },
  modalCloseIcon: {
    right: '6px',
    top: '6px',
  },
  rejected: {
    textAlign: 'center',
    color: '#A72002',
    marginBottom: 0,
  },
  dropzoneIcon: {
    display: 'block',
    margin: '0 auto 10px',
    width: '35px',
    height: 'auto',
  },
  dropzoneTip: {
    '& a': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: 0,
      fontWeight: 500,
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#000',
    },
    '& img': {
      width: '13px',
      height: '13px',
      display: 'inline-block',
      marginLeft: '6px',
      transform: 'translateY(2px)',
    },
  },
  dropzonePreview: {
    display: 'flex',
    alignItems: 'center',
  },
  dropzonePreviewIcon: {
    height: '15px',
    width: '15px',
    objectFit: 'contain',
    objectPosition: 'center',
    display: 'inline-block',
    marginRight: '5px',
  },
  dropzoneFileName: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#000',
  },
  dropzoneProgress: {
    width: '100%',
    height: '35px',
    background: '#F5F4F4',
    borderRadius: '30px',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '20px',
  },
  dropzoneProgressInner: {
    position: 'absolute',
    left: 0,
    top: 0,
    maxWidth: 0,
    width: '100%',
    height: '100%',
    padding: '10px 20px',
    lineHeight: 1,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    transition: 'max-width 0.25s ease-in-out',
    '& a': {
      color: '#000',
    },
  },
}));

export const dropzoneStyles: IStyleCustomization<any> = {
  dropzone: {
    width: '100%',
    marginTop: '25px',
    border: '1px dashed transparent',
    borderRadius: '8px',
    textAlign: 'center',
    padding: '25px 20px 15px',
    borderColor: '#373737',
    backgroundColor: '#F5F4F4',
    transition: 'all 0.25s ease-in-out',
  },
  dropzoneActive: {
    borderColor: '#FFBC57',
    backgroundColor: '#FFEFD7',
  },
  dropzoneReject: {
    borderColor: '#A72002',
    backgroundColor: '#ffbab5',
  },
  dropzoneDisabled: {
    padding: '0 20px',
    borderColor: '#fff',
    backgroundColor: '#fff',
  },
  input: {
    display: 'none',
  },
};
