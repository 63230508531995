import * as React from 'react';

export interface DeliveriesTableProps<T> {
  rows: T[] | null;
  columns: GridColDef<T>[];
  idColumn: keyof T;
  groupBy?: keyof T;
  groupByColumns?: GridColDef<T>[];
  onSelectionChange?: (value: string[]) => void;
  deselectTrigger?: number;
  totalItems?: number;
  currentPage?: number;
  handleChangePage?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  rowsPerPage?: number;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  itemsPerPage?: number;
}

export interface GridColDef<T> {
  field: keyof T | string;
  headerName: string;
  width?: string | number;
  colspan?: number;
  labelClassName?: (row: T, groupIndex?: number) => string;
  valueGetter?: (
    row: T,
    groupIndex?: number
  ) => string | React.ReactFragment | null;
}

export class SpacerGridCol implements GridColDef<any> {
  constructor(public colspan: number) {}

  public static fieldName = 'group-spacer';

  public field = SpacerGridCol.fieldName;
  public headerName = 'Spacer';
}
