import { FirebaseOptions } from '@firebase/app';
import { getAuth } from 'firebase/auth';
import { OpenAPI } from './services/api';

export interface AddressPreferenceOptions {
  lat: number;
  lng: number;
  radius: number;
}

export interface Config {
  backendUrl: string;
  firebaseConfig: FirebaseOptions;
  addressPreference: AddressPreferenceOptions;
}

export const config: Config = {
  backendUrl: process.env.REACT_APP_BACKEND_URL || '',
  firebaseConfig: {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_APP_MEASUREMENT_ID,
  },
  addressPreference: {
    lat: parseFloat(process.env.REACT_APP_ADDRESS_PREFERENCE_LAT || ''),
    lng: parseFloat(process.env.REACT_APP_ADDRESS_PREFERENCE_LNG || ''),
    radius: parseFloat(process.env.REACT_APP_ADDRESS_PREFERENCE_RADIUS || ''),
  },
};

OpenAPI.BASE = config.backendUrl + '/api';
OpenAPI.TOKEN = async () => {
  return (await getAuth().currentUser?.getIdToken()) || '';
};
