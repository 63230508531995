import { useFormContext } from 'react-hook-form';

import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FormSection from 'src/components/form/FormSection';
import { FormValue } from 'src/components/form/FormValue';
import { PackageViewer } from '../components/PackageViewer';
import { EditIcon } from '../components/icons';
import { Warehouse } from 'src/services/api/models/Warehouse';
import SummaryBox from '../components/SummaryBox';
import { formatAddress } from 'src/components/table';
import { CreateParcelModel } from 'src/types/delivery/Delivery';
import { ParcelDeliveryType } from 'src/types/delivery/Delivery';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '90vh',
    paddingLeft: '12px',
  },
  mainSection: {
    width: '60%',
    marginRight: theme.spacing(4),
    overflowWrap: 'break-word',
  },
  header: {
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(3),
  },
  sideSummary: {
    width: 'calc(40% - 200px)',
    position: 'fixed',
    right: '24px',
  },
  editButton: {
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
    border: '1px solid #BFBFBF !important',
  },
  editIcon: {
    height: '18px !important',
    width: '18px !important',
    marginLeft: '8px',
  },
}));

export function DeliverySummary(props: {
  onSubmit: () => void;
  onEdit: (sectionKey: string) => void;
  disableSubmit: boolean;
  salesAgreementSigned: boolean;
  errors: Record<keyof CreateParcelModel, any>;
  selectedWarehouse: Warehouse | undefined;
}) {
  const classes = useStyles();

  const { getValues } = useFormContext<CreateParcelModel>();
  const { selectedWarehouse } = props;

  const value = getValues();

  const editButton = (sectionKey: string) => {
    return (
      <Button
        color='inherit'
        variant='outlined'
        className={classes.editButton}
        onClick={() => props.onEdit(sectionKey)}
        id={sectionKey + 'Edit'}
      >
        Edit <EditIcon className={classes.editIcon} />
      </Button>
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.mainSection}>
        <Box className={classes.header}>
          <Typography variant='h1'>Summary</Typography>
          <Typography variant='subtitle2'>
            Please check all delivery details and edit if anything is incorrect.
          </Typography>
        </Box>

        <FormSection
          title='Consignment details'
          headerChildren={editButton('consignmentDetails')}
          fullSize
        >
          <FormValue
            label='Reference number 1'
            value={value.referenceNumber1 || 'No data'}
          />
          <FormValue
            label='Reference number 2'
            value={value.referenceNumber2 || 'No data'}
          />
        </FormSection>

        <FormSection
          title='Pick up details'
          headerChildren={editButton('pickupDetails')}
          fullSize
        >
          <FormValue label='Pick up from'>
            <>
              {selectedWarehouse?.name}
              <br />
              {formatAddress(selectedWarehouse?.address)}
            </>
          </FormValue>

          <FormValue
            label='Comments'
            value={selectedWarehouse?.address?.hint || 'No comment'}
          />

          <FormValue label='Pick up time' value='Asap' />
        </FormSection>

        <FormSection
          title='Delivery details'
          headerChildren={editButton('deliveryDetails')}
          fullSize
        >
          <FormValue label='Delivery to'>
            <>
              {value.receiverData?.businessName && (
                <>
                  {value.receiverData?.businessName}
                  <br />
                </>
              )}
              {value.receiverData?.fullName}
              <br />
              {formatAddress(value.parcelLocation?.shippingAddress)}
            </>
          </FormValue>
          <FormValue
            label='Comments'
            value={value.parcelLocation.shippingAddress.hint || 'No data'}
          />
          <FormValue
            label='Premise type'
            value={
              value.parcelLocation.shippingAddress?.premiseType === 'Private'
                ? 'Residential'
                : 'Commercial' || 'No data'
            }
          />
          <FormValue
            label='Receiver phone number'
            value={value.receiverData?.phone}
          />
          <FormValue label='Receiver email' value={value.receiverData?.email} />
          <FormValue
            label='Delivery type'
            value={
              value.deliveryType === ParcelDeliveryType.Sameday
                ? 'Same-Day'
                : 'Next-Day'
            }
          />
        </FormSection>

        <FormSection title='List of parcels' fullSize>
          <PackageViewer value={value.parcels} onEdit={props.onEdit} />
        </FormSection>

        <FormSection title='Payment' fullSize>
          <FormValue
            label='Payment method'
            value='Instant payment by credit card'
          />
        </FormSection>
      </Box>
      <Box className={classes.sideSummary}>
        <SummaryBox
          onSubmit={props.onSubmit}
          disableSubmit={props.disableSubmit}
          delivery={value}
          salesAgreementSigned={props.salesAgreementSigned}
        />
      </Box>
    </Box>
  );
}
