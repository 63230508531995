import React from "react";

function getHighlightedText(value: string, searchText: string) {
  var parts = value.split(new RegExp(`(${searchText})`, "gi"));
  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part.toLowerCase() === searchText.toLowerCase() ? (
        <span style={{ backgroundColor: "#ffbb5f" }}>{part}</span>
      ) : (
        part
      )}
    </React.Fragment>
  ));
}

const HighlightedText = ({
  searchText,
  value,
}: {
  searchText: string | undefined;
  value: string;
}) => {
  if (!searchText) return <>{value}</>;
  return <span>{getHighlightedText(value, searchText)}</span>;
};

export default HighlightedText;