import { makeStyles, DefaultTheme } from '@mui/styles';

type Props = {
  color?: string;
};

export default makeStyles<DefaultTheme, Props>((theme) => ({
  timelineWrapper: {
    margin: 0,
    padding: '6px 0 !important',
    '& li': {
      '&:before': {
        padding: 0,
        flex: 0,
      },
    },
  },
  timelineSeparator: {
    transform: 'translateY(-1.5rem)',
  },
  timelineItem: {
    minHeight: '57px !important',
    '&:last-of-type': {
      '& $timelineDot': {
        backgroundColor: (props: Props) =>
          props.color + ' !important' || '#FDB13F !important',
      },
      '& $timelineBoxDate': {
        color: '#373737',
      },
      '& $timelineBoxStatus': {
        fontSize: '17px',
        lineHeight: '1.35',
        fontWeight: '800 !important',
        color: '#373737',
        '& a': {
          color: '#373737',
          transition: 'color 0.25s ease-in-out',
          '&:hover': {
            color: '#FDB13F',
          },
        },
      },
    },
    '&.delivered:not(.active)': {
      '& $timelineDot': {
        backgroundColor: '#BFBFBF !important',
        borderColor: '#BFBFBF !important',
      },
      '& $timelineDotIcon': {
        visibility: 'hidden',
      },
      '& $timelineLine': {
        backgroundColor: '#BFBFBF !important',
      },
    },
    '&:first-of-type': {
      '& $timelineLine': {
        visibility: 'hidden',
      },
    },
  },
  timelineDot: {
    backgroundColor: 'transparent !important',
    borderWidth: '2px',
    borderColor: (props: Props) =>
      props.color + ' !important' || '#FDB13F !important',
    boxShadow: 'none !important',
    padding: '2px !important',
    margin: '5px 0 !important',
  },
  timelineDotIcon: {
    width: '12px',
    height: '12px',
    objectFit: 'contain',
    objectPosition: 'center',
    '& path': {
      fill: (props: Props) => props.color || '#FDB13F',
    },
  },
  timelineLine: {
    backgroundColor: (props: Props) =>
      props.color + ' !important' || '#FDB13F !important',
  },
  timelineBoxDate: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#9F9F9F',
  },
  timelineBoxStatus: {
    fontSize: '14px',
    fontWeight: '600 !important',
    color: '#adadad',
  },
}));
