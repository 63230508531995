import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  tabs: {
    backgroundColor: '#FCFCFC',
    position: 'fixed',
    marginLeft: '-60px',
    paddingLeft: '60px',
    marginTop: '-20px',
    paddingTop: '20px',
    width: 'calc(100% - 220px)',
    zIndex: 100,
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 4,
      width: '20px',
      height: '20px',
    },
  },
  tabContentContainer: {
    marginTop: '90px',
  },
}));
