import { DependencyList, useEffect } from 'react';

/* eslint-disable react-hooks/exhaustive-deps */
export function useEffectAsync(
  asyncFunc: () => Promise<void>,
  deps?: DependencyList
) {
  return useEffect(() => {
    asyncFunc();
  }, deps);
}
