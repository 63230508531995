import { getAuth } from '@firebase/auth';
import { config } from 'src/config';
import { DispatchingService, ParcelsService } from 'src/services/api';
import { download } from 'src/utilities/utilities';

export async function printLabels(parcelIds: string[]): Promise<void> {
  try {
    const token = await getAuth()?.currentUser?.getIdToken();
    const res = await fetch(
      config.backendUrl + '/api/dispatching/print-labels',
      {
        method: 'POST',
        body: JSON.stringify({ parcelIds }),
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    );

    const blob = await res.blob();

    if (res.status > 299) throw Error('Could not print labels.');

    download(blob, 'dip-labels.pdf');
  } catch (e) {
    console.error("Could not successfully invoke 'printLabels'.", e);
    throw Error('Could not print labels.');
  }
}

export async function markReadyForCourier(parcelIds: string[]): Promise<void> {
  try {
    await DispatchingService.ship({ parcelIds: parcelIds });
  } catch (e) {
    console.error("Could not successfully invoke 'markReadyForCourier'.", e);
    throw Error('Could not dispatch parcels.');
  }
}

export async function removePackage(parcelId: string): Promise<void> {
  try {
    await ParcelsService.remove(parcelId);
  } catch (e) {
    console.error("Could not successfully invoke 'removePackage'.", e);
    throw Error('Could not remove package.');
  }
}
