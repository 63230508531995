import { Grid } from '@mui/material';
import AddressForm from 'src/components/form/AddressForm';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import { PlaceType } from 'src/components/maps';
import { WarehouseService } from 'src/services/warehouseService';

export interface WarehouseWriteFormProps {
  selectedPlace: { value: PlaceType | null; set: (value: PlaceType) => void };
}

export function WarehouseWriteForm(props: WarehouseWriteFormProps) {
  const onPlaceSelect = (place: PlaceType) => {
    props.selectedPlace.set(place);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitledFormTextField<WarehouseService.WarehouseWriteModel>
          id={'name'}
          TextField={{
            variant: 'outlined',
            type: 'text',
            label: 'Warehouse/pickup point name',
            placeholder: 'Enter the name',
          }}
          rules={{
            required: 'Please provide warehouse/pickup point name.',
            minLength: {
              value: 2,
              message:
                'Warehouse/pickup point name must have at least 2 characters.',
            },
            maxLength: {
              value: 16,
              message:
                'Warehouse/pickup point name must have less than 16 characters.',
            },
          }}
        />
      </Grid>

      <AddressForm<WarehouseService.WarehouseWriteModel>
        idPrefix='address'
        value={props.selectedPlace.value}
        onPlaceSelect={onPlaceSelect}
        addressSearch={{ placeholder: 'Search for address' }}
      />

      <Grid item xs={12}>
        <TitledFormTextField<WarehouseService.WarehouseWriteModel>
          id='address.hint'
          TextField={{
            multiline: true,
            rows: 4,
            type: 'text',
            label: 'Instructions for the courier',
            placeholder: 'Information on how to get there...',
          }}
          rules={{
            maxLength: {
              value: 125,
              message: 'Instructions must have less than 125 characters.',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
