import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  BrandingInformation,
  BrandingStepDefinition,
} from './steps/BrandingInformation';
import { PickupPoint, PickupPointStepDefinition } from './steps/PickupPoint';
import { TeamMembers, TeamMembersStepDefinition } from './steps/TeamMembers';
import { OnboardingModel } from './types';
import { ArrowRightIcon } from '../delivery/components/icons';
import { PlaceType } from 'src/components/maps';
import { useHistory } from 'react-router-dom';
import {
  clearState,
  createWarehouseAsync,
  selectOnboarding,
  sendInvitationsAsync,
  updateBrandInformationAsync,
} from './onboardingSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import LoadingButton from 'src/components/LoadingButton';
import LoadingScreen from 'src/components/LoadingScreen';
import {
  fetchOrganizationInfo,
  selectOrganization,
} from 'src/features/organization/organizationSlice';
import {
  fetchWarehousesAsync,
  selectWarehouse,
} from 'src/features/warehouse/warehouseSlice';

const steps = [
  BrandingStepDefinition,
  PickupPointStepDefinition,
  TeamMembersStepDefinition,
];

export function Onboarding() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | null>(null);
  const [onboardingInProgress, setOnboardingInProgress] =
    useState<boolean>(false);

  const { organization } = useAppSelector(selectOrganization);
  const { warehouses } = useAppSelector(selectWarehouse);
  const { status, errorMessage, canSendInvitations } =
    useAppSelector(selectOnboarding);

  const form = useForm<OnboardingModel>({
    defaultValues: { teamMembers: [{ value: '' }] },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  useEffect(() => {
    dispatch(fetchWarehousesAsync());
    dispatch(fetchOrganizationInfo());
  }, [dispatch]);

  useEffect(() => {
    if (
      organization &&
      (warehouses || []).length > 0 &&
      !onboardingInProgress
    ) {
      history.push('/dashboard');
    } else if (organization) {
      setOnboardingInProgress(true);

      form.setValue(
        'displayName',
        organization.branding?.displayName || organization.organizationName
      );
      form.setValue(
        'brandEmail',
        organization.branding?.email || organization.companyEmail
      );
      form.setValue('brandPhone', organization?.branding?.phone || '');
    }
  }, [organization, history, form, warehouses, onboardingInProgress]);

  const toastError = useCallback(() => {
    toast.error(
      errorMessage === 'validation_error'
        ? 'Please correct your form and try again.'
        : 'An unhandled error occured.',
      { hideProgressBar: true }
    );
    dispatch(clearState());
  }, [dispatch, errorMessage]);

  useEffect(() => {
    if (status === 'succeeded') {
      switch (step) {
        case 0:
          toast.success('Brand information has been updated.', {
            hideProgressBar: true,
          });
          break;
        case 1:
          toast.success('Pick-up point created.', { hideProgressBar: true });
          break;
        case 2:
          toast.success('Invitiations sent.', { hideProgressBar: true });
          break;
      }

      dispatch(clearState());

      if (step < steps.length - 1) {
        setStep(step + 1);
      } else {
        history.push('/dashboard');
      }
    } else if (status === 'failed') {
      toastError();
    }
  }, [status, dispatch, history, errorMessage, toastError, step]);

  const onSubmit = () => {
    validateForm(() => {
      const formValue = form.getValues();
      if (step === 0) {
        dispatch(
          updateBrandInformationAsync({
            email: formValue.brandEmail,
            displayName: formValue.displayName,
            phone: formValue.brandPhone,
            organizationId: organization!.id,
          })
        );
      }

      if (step === 1) {
        dispatch(
          createWarehouseAsync({
            ...formValue,
            organizationId: organization!.id,
          })
        );
      }

      if (step === 2) {
        dispatch(
          sendInvitationsAsync({
            emails: form.getValues().teamMembers.map((v) => v.value),
            organizationId: organization!.id,
          })
        );
      }
    });
  };

  const validateForm = (onValid: () => void): Promise<void> => {
    return form.handleSubmit(onValid, () =>
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      })
    )();
  };

  const onBack = () => {
    setStep(step - 1);
  };

  const onForward = () => {
    if (isForwardDisabled()) return;

    onSubmit();
  };

  const onSkipStep = () => {
    history.push('/dashboard');
  };

  const isForwardDisabled = () => {
    return steps.length === step || (step === 2 && !canSendInvitations);
  };

  if (!organization) return <LoadingScreen />;

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          width: '470px',
        },
      }}
    >
      <DialogTitle sx={{ marginTop: '24px' }}>{steps[step].title}</DialogTitle>
      <DialogContent sx={{ overflow: 'visible' }}>
        <DialogContentText sx={{ marginBottom: 2 }}>
          {steps[step].subtitle}
        </DialogContentText>
        <FormProvider {...form}>
          {step === 0 && <BrandingInformation />}
          {step === 1 && (
            <PickupPoint
              selectedPlace={{ value: selectedPlace, set: setSelectedPlace }}
            />
          )}
          {step === 2 && <TeamMembers />}
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} sx={{ display: 'flex', gap: '20px' }}>
            {step > 0 && step < steps.length - 1 && (
              <Button
                id='onboardingBackButton'
                sx={{ flexGrow: 1 }}
                color='inherit'
                variant='outlined'
                onClick={onBack}
              >
                Back
              </Button>
            )}
            <LoadingButton
              id='onboardingNextButton'
              color='primary'
              variant='contained'
              sx={{ flexGrow: 3 }}
              loading={status === 'loading'}
              disabled={isForwardDisabled()}
              onClick={onForward}
            >
              {steps[step].submitButtonText || (
                <>
                  Next step <ArrowRightIcon />
                </>
              )}
            </LoadingButton>
          </Grid>

          {steps[step].skipable && (
            <Grid
              item
              xs={12}
              mt={2}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Link
                id='teamMembersSkipLink'
                color='inherit'
                sx={{ cursor: 'pointer' }}
                onClick={onSkipStep}
              >
                Skip step
              </Link>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              gap: '8px',
              justifyContent: 'center',
              marginTop: '24px',
            }}
          >
            {steps.map((s, i) => {
              if (i === step)
                return (
                  <Box
                    key={s.title}
                    sx={{
                      border: '2px solid #494949',
                      width: '20px',
                      height: '8px',
                    }}
                  ></Box>
                );

              if (i < step)
                return (
                  <Box
                    key={s.title}
                    sx={{
                      background: '#494949',
                      width: '20px',
                      height: '8px',
                    }}
                  ></Box>
                );

              return (
                <Box
                  key={s.title}
                  sx={{ background: '#DFDFDF', width: '20px', height: '8px' }}
                ></Box>
              );
            })}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
