import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import {
  CircularProgress,
  Divider,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';

import LoadingButton from 'src/components/LoadingButton';

import { useEffectAsync } from 'src/components/hooks/useEffectAsync';
import {
  PriceCalculationResult,
  PricingService,
  OrderParams,
} from 'src/services/api';
import { useState } from 'react';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    zIndex: 5,
    minWidth: '300px',
    background: 'white',
    border: '1px solid #DFDFDF',
    borderRadius: '8px',
    boxSizing: 'border-box',
    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.12)',
    padding: '16px 24px 24px',
  },
  circularProgress: {
    display: 'block !important',
    marginTop: '2em',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  summaryTitle: {
    padding: '0 !important',
    fontSize: '20px !important',
    fontWeight: '800 !important',
  },
  submitButton: {
    boxShadow: '0px 4px 16px 0px #0000001F',
  },
  submitDescription: {
    marginTop: theme.spacing(1),
    fontSize: '11px',
    color: '#5B5B5B',
    textAlign: 'center',
  },
}));

function PriceRow(props: {
  label?: string;
  value: number;
  extraValue?: string;
  valuePrefix?: string;
  priceSx?: SxProps<any>;
  id?: string;
}) {
  return (
    <>
      <Grid
        item
        xs={8}
        sx={{
          color: props.value < 0 ? '#EC1D05' : '#000',
        }}
      >
        {props.label}
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          textAlign: 'right',
          color: props.value < 0 ? '#EC1D05' : '#000',
          fontWeight: 700,
          ...props.priceSx,
        }}
        id={props.id}
      >
        {props.valuePrefix}
        {props.value < 0 && '- '}$ {Math.abs(props.value).toFixed(2)}
        {props.extraValue && (
          <>
            <br />
            <span style={{ fontSize: '14px', fontWeight: 400 }}>
              {props.extraValue}
            </span>
          </>
        )}
      </Grid>
    </>
  );
}

function GridDivider() {
  return (
    <Grid item xs={12}>
      <Divider />
    </Grid>
  );
}

export interface SummaryBoxProps {
  onSubmit: () => void;
  disableSubmit: boolean;
  delivery: OrderParams;
  salesAgreementSigned: boolean;
}

export default function SummaryBox({
  disableSubmit,
  onSubmit,
  delivery,
  salesAgreementSigned,
}: SummaryBoxProps) {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [pricing, setPricing] = useState<PriceCalculationResult | null>(null);

  useEffectAsync(async () => {
    if (salesAgreementSigned) return;
    setLoading(true);
    PricingService.getPricingForOrder({
      items: delivery.parcels.map((parcel: any) => ({
        weight: parseFloat(parcel.weight),
        width: parseFloat(parcel.dimension_x),
        height: parseFloat(parcel.dimension_y),
        depth: parseFloat(parcel.dimension_z),
      })),
      deliveryType: delivery.deliveryType!,
    })
      .then((r) => {
        setPricing(r);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Could not fetch pricing information.', {
          hideProgressBar: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [salesAgreementSigned, delivery.parcels]);

  return (
    <Box className={classes.summaryContainer}>
      <Typography variant='h6' className={classes.summaryTitle}>
        Delivery Summary
      </Typography>
      <Grid container spacing={1} mt={1}>
        {salesAgreementSigned ? (
          <div style={{ padding: 10 }}>
            {delivery.parcels.length + ' x parcel shipping'}
          </div>
        ) : (
          <>
            {!pricing && (
              <CircularProgress
                color='secondary'
                className={classes.circularProgress}
                size={22}
              />
            )}
            {pricing && (
              <>
                <PriceRow
                  id='order-summary-number'
                  label={pricing.itemsPrices.length + ' x parcel shipping'}
                  valuePrefix={`${pricing.itemsPrices.length} x `}
                  value={pricing.unitPrice[delivery.deliveryType!]!.value}
                />
                <GridDivider />
                <PriceRow
                  id='order-summary-summary'
                  label='Summary'
                  value={
                    pricing.itemsPrices.length *
                    pricing.unitPrice[delivery.deliveryType!]!.value
                  }
                />
                {pricing.totalDiscount.value > 0 && (
                  <PriceRow
                    id='order-summary-discount'
                    label='Discount'
                    value={-pricing.totalDiscount.value}
                  />
                )}
                <GridDivider />
                <PriceRow
                  id='order-summary-total'
                  label='Total price'
                  value={pricing.totalPrice.value}
                  extraValue={`inc. GST $ ${pricing.totalTax.value.toFixed(2)}`}
                  priceSx={{
                    fontSize: '20px',
                    lineHeight: '22px',
                  }}
                />
              </>
            )}
          </>
        )}
        <Grid item xs={12} mt={2}>
          <LoadingButton
            id='order-confirm'
            className={classes.submitButton}
            loading={disableSubmit}
            disabled={loading}
            type='submit'
            onClick={onSubmit}
            variant='contained'
            color='primary'
            fullWidth
          >
            {salesAgreementSigned ? 'Confirm & Add to bill' : 'Confirm'}
          </LoadingButton>
        </Grid>
      </Grid>
      {/* } */}
    </Box>
  );
}
