import { makeStyles } from '@mui/styles';
import bg from 'src/assets/bg.png';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none !important',
    background: 'transparent !important',
  },
  title: {
    flexGrow: 1,
  },
  companyLogo: {
    height: '34px',
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  avatarContainer: {
    display: 'flex',
    padding: '0.75rem',
    width: '100%',
    paddingLeft: '1rem',
  },
  avatar: {
    width: '2.5rem',
    height: '2.5rem',
    marginRight: '1rem',
  },
  avatarLabel: {
    display: 'flex',
    alignItems: 'center',
  },
}));
