import { Box, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormValue } from 'src/components/form/FormValue';
import { ParcelInformation } from 'src/features/delivery/components/ParcelInformation';
import { OrderParcelDetails } from 'src/services/api';
import { EditIcon, FragileIcon, PercentIcon } from './icons';
import { GetVolume } from './packageUtils';

const useStyles = makeStyles((theme) => ({
  packagesContainer: {
    marginBottom: theme.spacing(3),
  },
  packageContainer: {
    padding: theme.spacing(2),
    flexBasis: '100%',
    border: '1px solid #DFDFDF',
    borderRadius: theme.spacing(1),
    marginTop: '12px',
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  packageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  packageHeaderButton: {
    padding: '11px 14px !important',
    border: '1px solid #BFBFBF !important',
    minWidth: '24px !important',
  },
  packageHeaderButtonIcon: {
    width: '18px !important',
    height: '18px !important',
  },
  inlineInformation: {
    display: 'flex',
    alignItems: 'center',
  },
  summaryHeader: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  summaryRow: {
    marginTop: '12px',
  },
  summarysubHeader: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  summaryValue: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 700,
  },
}));

export function PackageViewer(props: {
  value: OrderParcelDetails[];
  onEdit: (sectionKey: string) => void;
}) {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.packagesContainer}>
        {props.value.map((field, i) => (
          <Box className={classes.packageContainer} key={i}>
            <Box className={classes.packageHeader}>
              Parcel {i + 1}
              <Button
                variant='outlined'
                id={'parcel' + i + 'Edit'}
                className={classes.packageHeaderButton}
                onClick={() => props.onEdit('parcel' + (i + 1))}
              >
                <EditIcon className={classes.packageHeaderButtonIcon} />
              </Button>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <FormValue label='Weight' value={`${field.weight} kg`} />
              </Grid>
              <Grid item xs={3}>
                <FormValue
                  label='Dimensions'
                  value={`${field.dimension_x} x ${field.dimension_y} x ${field.dimension_z} cm`}
                />
              </Grid>
              <Grid item xs={2}>
                <FormValue
                  label='Volume'
                  value={`${GetVolume(field.dimension_x, field.dimension_y, field.dimension_z)} m3`}
                />
              </Grid>
              <Grid item xs={5}>
                <FormValue label='Information'>
                  <ParcelInformation value={field} />
                </FormValue>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
      <Box>
        <Box className={classes.summaryHeader}>Summary</Box>
        <Box className={classes.summaryRow}>
          <Box component='span' className={classes.summarysubHeader}>
            Weight:{' '}
          </Box>
          <Box component='span' className={classes.summaryValue}>
            {props.value
              .map((v) => v.weight)
              .reduce(
                (a: any, b: any) => parseFloat(a) + parseFloat(b),
                0.0
              )}{' '}
            kg
          </Box>
        </Box>
        <Box className={classes.summaryRow}>
          <Box component='span' className={classes.summarysubHeader}>
            Volume:{' '}
          </Box>
          <Box component='span' className={classes.summaryValue}>
            {props.value
              .map((v) =>
                GetVolume(v.dimension_x, v.dimension_y, v.dimension_z)
              )
              .reduce(
                (a: any, b: any) => parseFloat(a) + parseFloat(b),
                0.0
              )}{' '}
            m3
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
