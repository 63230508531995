/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrderCommandParams } from '../models/CreateOrderCommandParams';
import type { CreateOrderResponse } from '../models/CreateOrderResponse';
import type { GetAllOrdersResponse } from '../models/GetAllOrdersResponse';
import type { IResponse } from '../models/IResponse';
import type { UpdateOrderCommandParams } from '../models/UpdateOrderCommandParams';
import type { WebParcelProjection } from '../models/WebParcelProjection';
import type { WebParcelProjectionV2 } from '../models/WebParcelProjectionV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrdersService {

  /**
   * @param requestBody
   * @returns CreateOrderResponse Created
   * @throws ApiError
   */
  public static create(
    requestBody: CreateOrderCommandParams,
  ): CancelablePromise<CreateOrderResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/orders',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Parameters validation error`,
      },
    });
  }

  /**
   * @returns GetAllOrdersResponse
   * @throws ApiError
   */
  public static getAllOrders(): CancelablePromise<GetAllOrdersResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/orders',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Returns details and validation result of a given order ID.
   * @param id ID of the order
   * @returns IResponse Ok
   * @throws ApiError
   */
  public static getOrderValidationResult(
    id: string,
  ): CancelablePromise<IResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/orders/{id}/validation',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Unauthorized`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * Updates an order.
   * @param id ID of the order to update
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static update(
    id: string,
    requestBody: UpdateOrderCommandParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/orders/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        404: `Entity not found`,
        422: `Parameters validation error`,
      },
    });
  }

  /**
   * @param id
   * @returns WebParcelProjection
   * @throws ApiError
   */
  public static getOrder(
    id: string,
  ): CancelablePromise<Array<WebParcelProjection>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/orders/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * @param warehouseId
   * @returns any Created
   * @throws ApiError
   */
  public static importCsv(
    warehouseId: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/orders/import/{warehouseId}',
      path: {
        'warehouseId': warehouseId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Entity not found`,
        422: `Umprocessable entity`,
      },
    });
  }

  /**
   * @returns GetAllOrdersResponse
   * @throws ApiError
   */
  public static getAllOrdersV2(): CancelablePromise<GetAllOrdersResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/orders',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * @param id
   * @returns WebParcelProjectionV2
   * @throws ApiError
   */
  public static getOrderV2(
    id: string,
  ): CancelablePromise<Array<WebParcelProjectionV2>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/orders/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}