import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import Copyright from '../../components/Copyright';
import useStyles from './NoToolbarBlank.styles';
import { Container } from '@mui/material';

export default function BlankNoToolbarLayout(props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container className={classes.contentWrapper}>
        {props.children}
        <Copyright />
      </Container>
    </div>
  );
}
