import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';

export interface FilterState {
  warehouseId?: string;
}

const initialState: FilterState = {
  warehouseId: '',
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    changeWarehouseFilterSelection: (state, action: PayloadAction<string>) => {
      state.warehouseId = action.payload;
    },
    clearState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { changeWarehouseFilterSelection, clearState } =
  filterSlice.actions;
export const selectFilter = (state: RootState) => state.filter;
export default filterSlice.reducer;
