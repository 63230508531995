import { Link } from '@mui/material';
import { useHistory } from 'react-router-dom';

export interface MuiLinkProps {
  disabled?: boolean;
  onClick?: () => void;
  children: JSX.Element | string;
  id?: string;
}

export const MuiLink = (props: MuiLinkProps) => {
  return (
    <Link
      id={props.id}
      className={props.disabled ? 'disabled' : ''}
      onClick={props.disabled || !props.onClick ? () => {} : props.onClick}
      sx={
        props.disabled
          ? {
              color: '#BFBFBF !important',
              cursor: 'default',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'none',
              },
            }
          : {
              cursor: 'pointer',
            }
      }
    >
      {props.children}
    </Link>
  );
};

export interface MuiNavigationLinkProps extends MuiLinkProps {
  navigationTarget: string;
}

export const MuiNavigationLink = (props: MuiNavigationLinkProps) => {
  const history = useHistory();

  return (
    <MuiLink
      {...props}
      onClick={() => {
        props.onClick && props.onClick();
        history.push(props.navigationTarget);
      }}
    >
      {props.children}
    </MuiLink>
  );
};
