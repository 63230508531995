import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
  limit,
  orderBy,
  startAfter,
  getCountFromServer,
} from '@firebase/firestore';
import { useAppSelector } from 'src/app/hooks';
import { selectOrganization } from 'src/features/organization/organizationSlice';
import { selectFilter } from 'src/features/dashboard/filters/filtersSlice';

export const useParcelSubscriptionForOrg = <T>(
  path: string,
  itemsPerPage?: number,
  cursor?: string,
  additionalConditions?: any[]
) => {
  const { orgId } = useAppSelector(selectOrganization);
  const { warehouseId } = useAppSelector(selectFilter);

  const conditions = [
    { field: 'organizationId', operator: '==', value: orgId },
    {
      field: 'parcelLocation.pickupWarehouseId',
      operator: '==',
      value: warehouseId,
    },
  ];
  const order = orderBy('creationDate', 'desc');

  additionalConditions && conditions.push(...additionalConditions);

  return useSubscription<T>(path, conditions, itemsPerPage, cursor, order);
};

type Condition = { field: string; operator: any; value: string | undefined };

export const useSubscription = <T>(
  path: string,
  conditions: Condition[],
  itemsPerPage?: number,
  cursor?: string,
  order?: any
): {
  value: T[];
  count: number;
  isLoading: boolean;
  path: string;
  lastUpdateTimestamp: number;
} => {
  const [data, setData] = useState<T[] | null>(null);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (!path || !conditions) {
      return;
    }

    const queryConstraints: any = [];
    order && queryConstraints.push(order);
    conditions &&
      conditions.forEach((condition) => {
        condition.value &&
          queryConstraints.push(
            where(condition.field, condition.operator, condition.value)
          );
      });
    setIsLoading(true);
    getCountFromServer(
      query(collection(getFirestore(), path), ...queryConstraints)
    ).then((result: any) => {
      result && setCount(result.data().count);
    });

    cursor && queryConstraints.push(startAfter(cursor));
    itemsPerPage && queryConstraints.push(limit(itemsPerPage));

    const q = query(collection(getFirestore(), path), ...queryConstraints);

    return onSnapshot(
      q,
      (querySnapshot) => {
        const data: T[] = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data() as T);
        });
        setData(data);
        setLastUpdate(moment().valueOf());
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [path, itemsPerPage, JSON.stringify(conditions), cursor]);

  return {
    value: data || [],
    count,
    isLoading,
    path,
    lastUpdateTimestamp: lastUpdate,
  };
};
