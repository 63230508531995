import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import _ from 'lodash';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  CreateParcelModel,
  ParcelStatus,
  UpdateParcelModel,
} from 'src/types/delivery/Delivery';
import { getAuth } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  clearState,
  selectEditDelivery,
  fetchOrderAsync,
  updateOrderAsync,
} from './editDeliverySlice';
import { useHistory, useParams } from 'react-router-dom';
import { getPlaceTypeFromLatLng, PlaceType } from 'src/components/maps';
import { toast } from 'react-toastify';
import { useEffectAsync } from 'src/components/hooks/useEffectAsync';
import { ReceiverDetails } from 'src/features/delivery/components/ReceiverDetails';
import { Box, Button, Grid, RadioGroup } from '@mui/material';
import LoadingButton from 'src/components/LoadingButton';
import { getObjectPaths } from 'src/utilities/GetObjectPaths';
import { getCountryRestrictions } from 'src/components/form/AddressForm';
import PackageListEditor from 'src/features/delivery/components/PackageListEditor';
import { OrdersService, Warehouse } from 'src/services/api';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Radio from '@mui/material/Radio/Radio';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import Typography from '@mui/material/Typography/Typography';
import { ParcelDeliveryType } from 'src/types/delivery/Delivery';
import FormSection from 'src/components/form/FormSection';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import { WarningDialog } from '../components/WarningDialog';
import React from 'react';
import { selectWarehouse } from 'src/features/warehouse/warehouseSlice';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 15,
    paddingBottom: 15,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(7),
  },
  circularProgressBox: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
  },
  bottomBar: {
    position: 'fixed',
    display: 'flex',
    left: 0,
    bottom: 0,
    background: '#ffffff',
    padding: '16px',
    boxShadow: '0px -4px 16px 0px #0000000A',
    width: '100%',
    marginLeft: '240px',
    paddingRight: '270px',
    justifyContent: 'flex-end',
    gap: '20px',
  },
}));

export default function EditDelivery() {
  const classes = useStyles();
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const history = useHistory();
  const { orderId } = useParams() as any;
  const auth = getAuth();
  const dispatch = useAppDispatch();

  const { status, fetchStatus, errorMessage, errors, order } =
    useAppSelector(selectEditDelivery);
  const parcels = order?.filter(
    (parcel) =>
      parcel.status === ParcelStatus.NeedsLabel ||
      parcel.status === ParcelStatus.FixRequired
  );
  const { selectedWarehouseId, warehouses } = useAppSelector(selectWarehouse);
  const selectedWarehouse: Warehouse | undefined = warehouses.find(
    (w) => w.id === selectedWarehouseId
  );

  const form = useForm<UpdateParcelModel>({
    defaultValues: { parcels: [{}] },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });
  const firstParcel = parcels && parcels[0];

  const { setValue, clearErrors, setError } = form;

  const setSelectedPlaceValue = (value: PlaceType | null) => {
    setSelectedPlace(value);
    // @ts-ignore
    setValue(`parcelLocation.shippingAddress.isAmbiguous`, false);
    clearErrors(`parcelLocation.shippingAddress`);
  };

  useEffect(() => {
    if (!firstParcel) return;
    setValue('referenceNumber1', firstParcel.referenceNumber1);
    setValue('referenceNumber2', firstParcel.referenceNumber2);
    setValue('parcelLocation', firstParcel.parcelLocation);
    if (!firstParcel.parcelLocation.pickupAddress.country) {
      setValue('parcelLocation.pickupAddress.country', 'Australia');
    }
    setValue('receiverData', {
      ...firstParcel.receiverData,
    });
    setValue('deliveryType', firstParcel.deliveryType);
    setValue('parcels', [
      ...parcels.map((parcel: any) => ({
        weight: parseFloat(parcel.size.weightInKg as any),
        dimension_x: parseFloat(parcel.size.dimensions.x as any),
        dimension_y: parseFloat(parcel.size.dimensions.y as any),
        dimension_z: parseFloat(parcel.size.dimensions.z as any),
        requiresAttention: parcel.requiresAttention,
        containsAlcohol: parcel.containsAlcohol,
        id: parcel.id,
      })),
    ]);

    // @ts-ignore
    if (!firstParcel.parcelLocation.shippingAddress.isAmbiguous) {
      getPlaceTypeFromLatLng(firstParcel.parcelLocation.shippingAddress).then(
        setSelectedPlace
      );
    }
  }, [order, setValue, setSelectedPlace]);

  useEffect(() => {
    if (
      firstParcel &&
      firstParcel.orderId &&
      firstParcel.status === ParcelStatus.FixRequired
    ) {
      let paths = [];
      OrdersService.getOrderValidationResult(firstParcel.orderId)
        .then((response: any) => {
          const errors = response?.errors;
          paths = getObjectPaths(errors).map((error: string[]) =>
            error.join('.')
          );
          paths.forEach((path: any) => {
            const value = _.get(errors, path);
            setError(path, {
              type: 'server',
              message:
                path.includes('weight') || path.includes('dimension')
                  ? ''
                  : value,
            });
          });
        })
        .catch((e) => {
          toast.error('Could not validate fields.', {
            hideProgressBar: true,
          });
        });
    }
  }, [order]);

  useEffectAsync(async () => {
    await dispatch(fetchOrderAsync(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    if (submitted && status === 'failed' && errors) {
      setSubmitted(false);
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      });
    }
  }, [status, errors, setError, submitted]);

  useEffect(() => {
    if (status === 'succeeded') {
      toast.success('Delivery has been saved successfully!', {
        hideProgressBar: true,
      });
      dispatch(clearState());
      history.push('/dashboard');
    } else if (fetchStatus === 'fetchError') {
      toast.error(
        errorMessage === 'fetch_error'
          ? 'Unable to download package data'
          : 'An unhandled error occured.',
        { hideProgressBar: true }
      );
    } else if (status === 'failed') {
      toast.error(
        errorMessage === 'validation_error'
          ? 'Please correct your form and try again.'
          : 'An unhandled error occured.',
        { hideProgressBar: true }
      );
    }
  }, [
    status,
    fetchStatus,
    auth.currentUser?.uid,
    dispatch,
    history,
    errorMessage,
    order,
  ]);

  const validateForm = (onValid: () => void): Promise<void> => {
    return form.handleSubmit(onValid, (e) => {
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      });
    })();
  };

  const validateOptionalFields = () => {
    const formValues = form.getValues();
    const { email, phone, fullName } = formValues.receiverData;

    if (!email || !phone || !fullName) {
      setWarningDialogOpen(true);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const formValues = form.getValues();
    dispatch(
      updateOrderAsync({
        id: firstParcel!.orderId,
        externalId: firstParcel!.externalId || null,
        referenceNumber1: formValues.referenceNumber1,
        referenceNumber2: formValues.referenceNumber2,
        parcels: formValues.parcels,
        parcelLocation: {
          pickupWarehouseId: formValues.parcelLocation.pickupWarehouseId,
          pickupAddress: {
            ...formValues.parcelLocation.pickupAddress,
          },
          shippingAddress: {
            ...formValues.parcelLocation.shippingAddress,
          },
        },
        receiverData: {
          ...formValues.receiverData,
        },
        deliveryType: formValues.deliveryType,
        dataHasToBeFixed: false,
      })
    );
    setSubmitted(true);
  };

  const onSubmit = () => {
    validateForm(() => {
      if (
        selectedWarehouse?.parcelValidationSettings?.allowEmptyEmail ||
        selectedWarehouse?.parcelValidationSettings?.allowEmptyPhone ||
        selectedWarehouse?.parcelValidationSettings?.allowEmptyReceiverName
      ) {
        validateOptionalFields();
      } else {
        handleSubmit();
      }
    });
  };

  const onCancel = () => {
    dispatch(clearState());
    history.push('/dashboard');
  };

  const handleWarningDialogConfirm = () => {
    setWarningDialogOpen(false);
    handleSubmit();
  };

  return (
    <div className={classes.root}>
      <FormProvider {...form}>
        <FormSection title='Consignment details' id='editConsignmentDetails'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TitledFormTextField<CreateParcelModel>
                id={'referenceNumber1'}
                TextField={{
                  type: 'text',
                  placeholder: 'Enter reference number',
                  label: 'Reference number (1)',
                  helperText:
                    'The reference number is a unique ID linked to your shipment. It is optional to use. You can enter one or two reference numbers to help you later identify the same consignment.',
                }}
                rules={{
                  maxLength: {
                    value: 32,
                    message:
                      'Reference number must have less than 32 characters.',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TitledFormTextField<CreateParcelModel>
                id={'referenceNumber2'}
                TextField={{
                  type: 'text',
                  placeholder: 'Enter reference number',
                  label: 'Reference number (2)',
                }}
                rules={{
                  maxLength: {
                    value: 32,
                    message:
                      'Reference number must have less than 32 characters.',
                  },
                }}
              />
            </Grid>
          </Grid>
        </FormSection>
        <ReceiverDetails
          warehouseId={firstParcel?.parcelLocation?.pickupWarehouseId}
          errors={{} as any}
          selectedPlace={{ value: selectedPlace, set: setSelectedPlaceValue }}
          countryRestrictions={getCountryRestrictions(
            firstParcel?.parcelLocation?.pickupAddress
          )}
          optionalEmail={
            selectedWarehouse?.parcelValidationSettings?.allowEmptyEmail
          }
          optionalName={
            selectedWarehouse?.parcelValidationSettings?.allowEmptyReceiverName
          }
          optionalPhone={
            selectedWarehouse?.parcelValidationSettings?.allowEmptyPhone
          }
        />
        <Grid item xs={12} md={6} mb={5}>
          <Typography variant='h4' mb={0} pb='5px' pt={0}>
            Delivery Type
          </Typography>
          <Controller
            name='deliveryType'
            defaultValue={
              firstParcel?.deliveryType
                ? firstParcel.deliveryType
                : ParcelDeliveryType.Sameday
            }
            rules={{
              required: {
                value: true,
                message: 'This field is required.',
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <FormControl component='fieldset' error={fieldState.invalid}>
                    <RadioGroup
                      value={field.value}
                      onChange={field.onChange}
                      color='inherit'
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <FormControlLabel
                        value={ParcelDeliveryType.Sameday}
                        control={<Radio id={ParcelDeliveryType.Sameday} />}
                        label='Same-Day'
                      />
                      <FormControlLabel
                        value={ParcelDeliveryType.Nextday}
                        control={<Radio id={ParcelDeliveryType.Nextday} />}
                        label='Next-Day'
                      />
                    </RadioGroup>
                    {fieldState.error && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              );
            }}
          />
        </Grid>
        <PackageListEditor />
      </FormProvider>
      <Box className={classes.bottomBar}>
        <Button variant='outlined' color='inherit' onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          onClick={onSubmit}
          loading={status === 'loading'}
        >
          Save
        </LoadingButton>
      </Box>
      <WarningDialog
        title='Warning'
        message='Insufficient recipient’s address information may lead to the parcel being undeliverable and returned to the sender.'
        isVisible={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        onConfirm={() => handleWarningDialogConfirm()}
      />
    </div>
  );
}
