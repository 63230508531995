import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';

import DeliveryForm from 'src/features/delivery/components/DeliveryForm';
import { ArrowLeftIcon, ArrowRightIcon } from '../components/icons';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  createNewDeliveryAsync,
  selectNewDelivery,
} from 'src/features/delivery/newDelivery/newDeliverySlice';
import { clearState } from 'src/features/delivery/newDelivery/newDeliverySlice';
import { DeliverySummary } from './DeliverySummary';
import { PlaceType } from 'src/components/maps';
import { WarningDialog } from '../components/WarningDialog';
import { CreateParcelModel } from 'src/types/delivery/Delivery';
import { selectOrganization } from 'src/features/organization/organizationSlice';
import { selectWarehouse } from 'src/features/warehouse/warehouseSlice';
import { Warehouse } from 'src/services/api/models/Warehouse';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  bottomBar: {
    position: 'fixed',
    display: 'flex',
    left: 0,
    bottom: 0,
    background: '#ffffff',
    padding: '16px',
    boxShadow: '0px -4px 16px 0px #0000000A',
    width: '100%',
    marginLeft: '240px',
    paddingRight: '240px',
  },
}));

export default function NewDelivery() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useStateWithCallbackLazy(0);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { organization } = useAppSelector(selectOrganization);
  const { status, errorMessage, errors } = useAppSelector(selectNewDelivery);
  const { selectedWarehouseId, warehouses } = useAppSelector(selectWarehouse);
  const selectedWarehouse: Warehouse | undefined = warehouses.find(
    (w) => w.id === selectedWarehouseId
  );
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | null>(null);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const form = useForm<CreateParcelModel>({
    defaultValues: { parcels: [{}] },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const { setValue, clearErrors } = form;

  const setSelectedPlaceValue = (value: PlaceType | null) => {
    setSelectedPlace(value);
    // @ts-ignore
    setValue(`parcelLocation.shippingAddress.isAmbiguous`, false);
    clearErrors(`parcelLocation.shippingAddress`);
  };

  const toastError = useCallback(() => {
    toast.error(
      errorMessage === 'validation_error'
        ? 'Please correct your form and try again.'
        : 'An unhandled error occured.',
      { hideProgressBar: true }
    );
  }, [errorMessage]);

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      toast.success('Delivery has been created successfully!', {
        hideProgressBar: true,
      });
      dispatch(clearState());
      history.push('/dashboard');
    } else if (status === 'failed') {
      toastError();
    }
  }, [status, errorMessage]);

  const onSubmit = () => {
    validateForm(() => {
      const formValues = form.getValues();
      dispatch(
        createNewDeliveryAsync({
          ...formValues,
          receiverData: {
            ...formValues.receiverData,
          },
        })
      );
    });
  };

  const validateForm = (onValid: () => void): Promise<void> => {
    return form.handleSubmit(onValid, (e) => {
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      });
    })();
  };

  const validateOptionalFields = () => {
    const formValues = form.getValues();
    const { email, phone } = formValues.receiverData;

    if (!email || !phone) {
      setWarningDialogOpen(true);
    } else {
      goToNextStep();
    }
  };

  const goToNextStep = () => {
    setActiveStep(1, () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  };

  const handleNext = () => {
    if (activeStep !== 0) {
      return;
    }
    validateForm(() => {
      if (
        selectedWarehouse?.parcelValidationSettings?.allowEmptyEmail ||
        selectedWarehouse?.parcelValidationSettings?.allowEmptyPhone
      ) {
        validateOptionalFields();
      } else {
        goToNextStep();
      }
    });
  };

  const handleBack = (sectionKey?: string) => {
    setActiveStep(0, () => {
      if (sectionKey) {
        document?.getElementById(sectionKey)?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    });
  };

  const handleWarningDialogConfirm = () => {
    setWarningDialogOpen(false);
    goToNextStep();
  };

  return (
    <div className={classes.root}>
      <FormProvider {...form}>
        {activeStep === 0 && (
          <DeliveryForm
            errors={errors || form.formState.errors}
            selectedPlace={{ value: selectedPlace, set: setSelectedPlaceValue }}
            selectedWarehouse={selectedWarehouse}
            onSubmit={handleNext}
            optionalEmail={
              selectedWarehouse?.parcelValidationSettings?.allowEmptyEmail
            }
            optionalPhone={
              selectedWarehouse?.parcelValidationSettings?.allowEmptyPhone
            }
            optionalName={
              selectedWarehouse?.parcelValidationSettings
                ?.allowEmptyReceiverName
            }
          />
        )}
        {activeStep === 1 && (
          <DeliverySummary
            onSubmit={onSubmit}
            onEdit={handleBack}
            errors={errors || form.formState.errors}
            disableSubmit={status === 'loading'}
            salesAgreementSigned={organization?.salesAgreementSigned === true}
            selectedWarehouse={selectedWarehouse}
          />
        )}
      </FormProvider>
      <Box
        className={classes.bottomBar}
        sx={{
          justifyContent: activeStep === 0 ? 'flex-end' : 'flex-start',
        }}
      >
        {activeStep === 0 && (
          <Button variant='contained' onClick={handleNext} id='nextStep'>
            Next step <ArrowRightIcon />
          </Button>
        )}
        {activeStep > 0 && (
          <Button
            id='previousStep'
            variant='outlined'
            color='inherit'
            onClick={() => handleBack()}
          >
            <ArrowLeftIcon /> Back
          </Button>
        )}
      </Box>
      <WarningDialog
        title='Warning'
        message='Insufficient recipient’s address information may lead to the parcel being undeliverable and returned to the sender.'
        isVisible={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        onConfirm={() => handleWarningDialogConfirm()}
      />
    </div>
  );
}
