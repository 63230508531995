import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PackageEditor from './PackageEditor';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AddIcon } from './icons';
import { OrderParams, OrderParcelDetails } from 'src/services/api';

const useStyles = makeStyles((theme) => ({
  packagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3),
    gap: '16px',
  },
  packageContainer: {
    padding: theme.spacing(2),
    flexBasis: 'calc(50% - 16px)',
    border: '1px solid #DFDFDF',
    borderRadius: theme.spacing(1),
    marginTop: '24px',
  },
}));

export default function PackageListEditor() {
  const classes = useStyles();
  const { control, formState, watch } = useFormContext<OrderParams>();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'parcels', // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const [parcels, setParcels] = useState<OrderParcelDetails[]>([]);

  useEffect(() => {
    const parcels = watch().parcels;

    parcels.forEach(function (a: any) {
      ['weight', 'dimension_x', 'dimension_y', 'dimension_z'].forEach(function (
        k: any
      ) {
        a[k] = parseFloat(a[k]) || 0;
      });
    });

    setParcels(parcels);
  }, [watch, formState]);

  const canAddAnother = () => fields.length < 10;

  const addNext = () => {
    if (!canAddAnother()) return;

    append({});
  };

  const onRemove = (packageNumber: number) => {
    remove(packageNumber);
  };

  const onDuplicate = (packageNumber: number) => {
    if (!canAddAnother()) return;

    const { id, ...clone } = parcels[packageNumber];
    append(clone);
  };

  return (
    <Box>
      <Box className={classes.packagesContainer}>
        {fields.map((field, i) => (
          <Box
            className={classes.packageContainer}
            key={field.id}
            id={`parcel${i + 1}`}
          >
            <PackageEditor
              parcelNumber={i}
              onRemove={onRemove}
              onDuplicate={onDuplicate}
              canRemove={fields.length > 1}
              canDuplicate={canAddAnother()}
            />
          </Box>
        ))}
      </Box>
      <Button
        id='addParcel'
        variant='outlined'
        color='inherit'
        sx={{
          width: '270px',
          '&[disabled] > svg': {
            opacity: 0.3,
          },
        }}
        onClick={addNext}
        disabled={!canAddAnother()}
      >
        <AddIcon sx={{ fontSize: '16px' }} />
        <Box sx={{ marginLeft: '8px', fontWeight: 600, fontSize: '13px' }}>
          Add parcel
        </Box>
      </Button>
    </Box>
  );
}
