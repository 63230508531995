import {
  AccountsService,
  OrganizationsService,
  OrganizationUsersService,
  UpdateAccountParams,
  UpdateBillingInfoCommandParams,
} from 'src/services/api';
import { UpdatePrinterSettingsParams } from '../../services/api/models/UpdatePrinterSettingsParams';

export namespace SettingsService {
  export interface DeleteUserRequest {
    userId: string;
    organizationId: string;
  }

  export async function deleteUser(request: DeleteUserRequest): Promise<void> {
    return await OrganizationUsersService.deleteOrganizationUser(
      request.userId,
      request.organizationId
    );
  }

  export interface DeleteInvitationRequest {
    invitationId: string;
    organizationId: string;
  }

  export async function deleteInvitation(
    request: DeleteInvitationRequest
  ): Promise<void> {
    return await OrganizationUsersService.deleteInvitation(
      request.invitationId,
      request.organizationId
    );
  }

  export interface ResendInvitationRequest {
    invitationId: string;
    organizationId: string;
  }

  export async function resendInvitation(
    request: ResendInvitationRequest
  ): Promise<void> {
    return await OrganizationUsersService.resendInvitation(
      request.invitationId,
      request.organizationId
    );
  }

  export interface UpdateBillingInformationRequest
    extends UpdateBillingInfoCommandParams {
    organizationId: string;
  }

  export async function updateBillingInformation(
    request: UpdateBillingInformationRequest
  ): Promise<void> {
    return await OrganizationsService.updateBilling(
      request.organizationId,
      request
    );
  }

  export interface UpdateUserDetailsRequest extends UpdateAccountParams {
    userId: string;
  }

  export async function updateUserDetails(
    request: UpdateUserDetailsRequest
  ): Promise<void> {
    return await AccountsService.updateAccount(request.userId, request);
  }

  export interface UpdatePrinterSettingsRequest
    extends UpdatePrinterSettingsParams {}

  export async function updatePrinterSettings(
    request: UpdatePrinterSettingsRequest
  ): Promise<void> {
    return await AccountsService.updatePrinterSettings(request);
  }
}
