import { Box, ButtonBase, Radio, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAuth } from 'firebase/auth';
import {
  collection,
  documentId,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LoadingBar from 'src/components/LoadingBar';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  selectSettings,
  updatePrinterSettingsAsync,
} from 'src/features/settings/settingsSlice';

interface PrinterSettings {
  format: 'A4' | 'A6';
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2em',
  },
  tab: {
    width: '14em !important',
    textAlign: 'center !important',
    padding: '1em !important',
    paddingBottom: '0 !important',
    marginRight: '1em !important',
    border: '1px solid #ccc !important',
    borderRadius: '1em !important',
    cursor: 'pointer !important',
    display: 'flex !important',
    flexDirection: 'column',
    gap: '.5em',
  } as any,
  tabTitle: {
    fontSize: '16pt',
    lineHeight: '20pt',
    fontWeight: 'bold',
  },
  tabSubtitle: {},
}));

export function SettingsPrinter() {
  const classes = useStyles();
  const auth = getAuth();
  const dispatch = useAppDispatch();

  const defaultPrinterSettings = { format: 'A6' } as PrinterSettings;
  const [settings, setSettings] = useState<PrinterSettings>(
    defaultPrinterSettings
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { status } = useAppSelector(selectSettings);

  useEffect(() => {
    const q = query(
      collection(
        getFirestore(),
        `users/${auth.currentUser?.uid}/configuration`
      ),
      where(documentId(), '==', 'printer')
    );

    return onSnapshot(q, (querySnapshot) => {
      const data: PrinterSettings[] = [];
      querySnapshot.forEach((doc) => {
        data.push(doc.data() as PrinterSettings);
      });
      setSettings(data.length === 1 ? data[0] : defaultPrinterSettings);
      setIsLoading(false);
    });
  });

  const updateFormat = (format: string) => {
    if (settings.format === format) return;
    if (isDisabled) return;

    setIsDisabled(true);

    dispatch(updatePrinterSettingsAsync({ format })).then(() => {
      toast.success('Updated printer settings.', { hideProgressBar: true });
      setIsDisabled(false);
    });
  };

  if (isLoading) return <LoadingBar />;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h3'>Printing settings</Typography>
      </Box>
      Select label printing option:
      <Box className={classes.tabs}>
        <ButtonBase
          disabled={status === 'loading' || isDisabled}
          className={classes.tab}
          onClick={() => updateFormat('A4')}
        >
          <Box className={classes.tabTitle}>A4 (4 up)</Box>
          <Box className={classes.tabSubtitle}>Label size: 210 x 297 mm</Box>
          <Radio
            id='A4'
            value='A4'
            disabled={status === 'loading' || isDisabled}
            checked={settings.format === 'A4'}
          ></Radio>
        </ButtonBase>
        <ButtonBase
          disabled={status === 'loading' || isDisabled}
          className={classes.tab}
          onClick={() => updateFormat('A6')}
        >
          <Box className={classes.tabTitle}>A6</Box>
          <Box className={classes.tabSubtitle}>Label size: 105 x 148 mm</Box>
          <Radio
            id='A6'
            value='A6'
            disabled={status === 'loading' || isDisabled}
            checked={settings.format === 'A6'}
          ></Radio>
        </ButtonBase>
      </Box>
    </>
  );
}
