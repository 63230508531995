import {
  signInWithEmailAndPassword,
  getAuth,
  browserLocalPersistence,
  setPersistence,
  User,
  onAuthStateChanged,
} from 'firebase/auth';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';

import { config } from 'src/config';

export interface LoginRequestModel {
  email: string;
  password: string;
}

export interface LoginResponseModel {
  idToken: string;
  refreshToken: string;
  uid: string;
  email: string;
  isPermitted?: boolean;
}

export async function isUserPermitted(user: Partial<User>): Promise<boolean> {
  if (!user.uid) return false;

  const db = getFirestore();
  const userDocRef = doc(db, 'users', user.uid);

  try {
    const userDocSnapshot = await getDoc(userDocRef);
    return userDocSnapshot.data()?.role === 'Web';
  } catch (err) {
    console.error('Failed to get user permissions data: ', err);
    return false;
  }
}

export async function login({
  email,
  password,
}: LoginRequestModel): Promise<LoginResponseModel> {
  const auth = getAuth();
  await setPersistence(auth, browserLocalPersistence);
  const response = await signInWithEmailAndPassword(auth, email, password);
  const idToken = await response.user.getIdToken();

  const result = {
    idToken,
    refreshToken: response.user.refreshToken,
    email: response.user.email || '',
    uid: response.user.uid,
    isPermitted: await isUserPermitted(response.user),
  };

  localStorage.setItem('user', JSON.stringify(result));
  return result;
}

export async function loadSession(): Promise<LoginResponseModel> {
  const user: User = await new Promise((resolve, reject) => {
    onAuthStateChanged(getAuth(), async function (authUser) {
      if (authUser) {
        try {
          const isAuthorized = await isUserPermitted(authUser);
          if (isAuthorized) {
            localStorage.setItem('user', JSON.stringify(authUser));
            resolve(authUser);
          } else {
            await logout();
            toast.error(
                'Missing or insufficient permissions, you have been logged out.',
                { autoClose: 5000 }
            );
          }
        } catch (err) {
          console.error(err);
          await logout();
          toast.error(
              'Missing or insufficient permissions, you have been logged out.',
              { autoClose: 5000 }
          );
          reject();
        }
      } else {
        reject();
      }
    });
  });

  return {
    idToken: await user.getIdToken(true),
    refreshToken: user.refreshToken,
    email: user.email || '',
    uid: user.uid,
    isPermitted: true,
  };
}

export async function resetPassword(email: string): Promise<void> {
  await fetch(config.backendUrl + '/api/accounts/passwordReset', {
    method: 'POST',
    body: JSON.stringify({ email }),
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function logout() {
  try {
    const auth = getAuth();
    await auth.signOut();
    localStorage.removeItem('user');
  } catch (err) {
    console.error(err);
    throw new Error('User is not able to logout');
  }
}
