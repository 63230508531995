import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import {
  Controller,
  FormProvider,
  Path,
  useFormContext,
} from 'react-hook-form';

export function AcceptEulaField<TForm>(props: { fieldId: Path<TForm> }) {
  const form = useFormContext<TForm>();
  const {
    formState: { errors },
    control,
  } = form;

  return (
    <FormProvider {...form}>
      <Controller
        name={props.fieldId as any}
        control={control}
        rules={{ required: true, validate: (value) => !!value }}
        defaultValue={false as any}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              required
              error={errors.hasOwnProperty(props.fieldId)}
              component='fieldset'
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id={props.fieldId}
                    onBlur={field.onBlur}
                    checked={!!field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    inputRef={field.ref}
                    color='primary'
                  />
                }
                label={
                  <>
                    I've read and accepted{' '}
                    <a
                      href='https://www.deliverinperson.com/sender-tc/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Terms and Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href='https://www.deliverinperson.com/privacy-policy/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Privacy Policy
                    </a>
                    .
                  </>
                }
              />
              {fieldState.error && (
                <FormHelperText id='terms-helper-text'>
                  You must accept the terms and conditions.
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    </FormProvider>
  );
}
