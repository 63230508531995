import { useEffect, useState, Fragment } from 'react';

import { DeliveriesTableProps, SpacerGridCol, GridColDef } from './types';

import useStyles from './DisplayTable.styles';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';
import { ArrowDownIcon, ArrowUpIcon } from './tableIcons';
import { getValueByPath } from 'src/services/utils';

export const isItemSelected = (
  selection: string[],
  row: any,
  idColumn: string,
  groupIndex?: number
): boolean => {
  if (groupIndex) {
    return (
      selection.filter((s) => s === `${row[idColumn] as string}${groupIndex}`)
        .length > 0
    );
  }

  return selection.filter((s) => s === (row[idColumn] as string)).length > 0;
};

export const getSelectedItems = <TItem extends any, TGroupItem>(
  rows: TItem[],
  selection: string[],
  groupBy: keyof TItem,
  idColumn: string
): { item: TItem; groupIndex?: number; groupItem?: TGroupItem }[] => {
  const selectedRows = rows.filter(
    (r) =>
      isItemSelected(selection, r, idColumn) ||
      ((r[groupBy] || []) as []).filter((_, gi) =>
        isItemSelected(selection, r, idColumn, gi + 1)
      ).length > 0
  );

  return selectedRows
    .map((r) => {
      const output: {
        item: TItem;
        groupIndex?: number;
        groupItem?: TGroupItem;
      }[] = [];

      const grouped = ((r[groupBy] || []) as [])
        .map((g, gi) => ({ item: g, index: gi }))
        .filter((group) =>
          isItemSelected(selection, r, idColumn, group.index + 1)
        );

      for (let g of grouped) {
        output.push({ item: r, groupIndex: g.index, groupItem: g.item });
      }

      if (isItemSelected(selection, r, idColumn)) output.push({ item: r });

      return output;
    })
    .reduce((a, b) => [...a, ...b], []);
};

export default function DisplayTable<T>({
  rows,
  columns,
  idColumn,
  onSelectionChange,
  groupBy,
  groupByColumns,
  deselectTrigger,
  totalItems,
  currentPage,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
}: DeliveriesTableProps<T>) {
  const classes = useStyles();
  const [data, setData] = useState<T[]>();
  const [selection, setSelectionState] = useState<string[]>([]);
  const [openGroupRowId, setOpenGroupRowId] = useState<string | null>(null);

  const setSelection = (v: string[]) => {
    if (onSelectionChange) onSelectionChange(v);

    setSelectionState(v);
  };

  const allSelected = () => {
    if (!data) return false;

    return (
      selection.length ===
      data.length +
        data
          .map((r) => ((r[groupBy!] as unknown as []) || []).length)
          .reduce((a: number, b: number) => a + b, 0)
    );
  };

  const selectAll = (value: boolean) => {
    if (!data) return;

    const isIntermediate = selection.length > 0 && !allSelected();

    if (value && !isIntermediate) {
      setSelection([
        ...data.map((d) => d[idColumn] as unknown as string),
        ...data
          .map((r) =>
            ((r[groupBy!] as unknown as []) || []).map(
              (p, pi) => `${r[idColumn]}${pi + 1}`
            )
          )
          .reduce((a, b) => [...a, ...b], []),
      ]);
    } else {
      setSelection([]);
    }
  };

  useEffect(() => {
    setSelection([]);
  }, [deselectTrigger]);

  useEffect(() => {
    setData(rows || []);
  }, [rows]);

  const isGroupRow = (row: T) => {
    return (
      groupBy &&
      row[groupBy] &&
      Array.isArray(row[groupBy]) &&
      (row[groupBy] as unknown as []).length > 1
    );
  };

  const renderCheckbox = (row: any, groupIndex?: number) => {
    if (!onSelectionChange) return null;

    const isSubItem = !!groupIndex;
    const id = `${row[idColumn]}${groupIndex || ''}`;
    const groupIds = ((row[groupBy!] as unknown as []) || []).map(
      (_, pi) => `${row[idColumn]}${pi + 1}`
    );

    const isIntermediate = !isSubItem
      ? selection.filter((s) => s === id).length === 0 &&
        selection.filter((s) => groupIds.filter((pi) => pi === s).length > 0)
          .length > 0
      : false;

    const onChange = (value: boolean) => {
      if (value) {
        setSelection([
          ...selection.filter((s) => s !== id),
          id,
          ...(isSubItem
            ? []
            : groupIds.filter(
                (p) => selection.filter((s) => s === p).length === 0
              )),
          ...(isSubItem &&
          selection.filter((s) =>
            s.startsWith(row[idColumn] as unknown as string)
          ).length ===
            groupIds.length - 1
            ? [row[idColumn] as unknown as string]
            : []),
        ]);
      } else {
        setSelection(
          selection.filter(
            (s) =>
              s !== id &&
              (isSubItem || groupIds.filter((pi) => pi === s).length === 0) &&
              s !== `${isSubItem ? (row[idColumn] as unknown as string) : '_'}`
          )
        );
      }
    };

    return (
      <TableCell
        sx={{
          paddingLeft: !groupIndex ? 'inherit' : '12px !important',
          paddingRight: !groupIndex ? '12px !important' : '0 !important',
        }}
      >
        <Checkbox
          indeterminate={isIntermediate}
          checked={selection.filter((s) => s === id).length > 0}
          onChange={(e) => onChange(e.target.checked)}
          sx={{
            paddingTop: 0,
            marginTop: '-3px',
          }}
        />
      </TableCell>
    );
  };

  const renderRow = (row: T, i: number) => {
    return (
      <TableRow
        key={(row[idColumn] as unknown as string) + i}
        className={
          isItemSelected(selection, row, idColumn as unknown as string)
            ? classes.selectedRow
            : undefined
        }
      >
        {renderCheckbox(row)}
        {columns.map((c, ci) => renderCell(row, c, i, ci))}
      </TableRow>
    );
  };

  const renderCell = (
    row: T,
    c: GridColDef<T>,
    rowIndex: number,
    colIndex: number,
    groupIndex?: number
  ) => {
    const value = c.valueGetter
      ? c.valueGetter(row, groupIndex)
      : getValueByPath(row as any, c.field);
    return (
      <TableCell
        key={
          (row[idColumn] as unknown as string) +
          '_' +
          rowIndex +
          '_' +
          colIndex +
          '_' +
          (groupIndex || '') +
          'cell'
        }
        width={c.width}
      >
        {c.labelClassName ? (
          <Box component='span' className={c.labelClassName(row, groupIndex)}>
            {value}
          </Box>
        ) : (
          value
        )}
      </TableCell>
    );
  };

  const renderBody = () => {
    return data?.map((row, ri) => {
      if (isGroupRow(row)) {
        return (
          <Fragment
            key={(row[idColumn] as unknown as string) + ri + '-fragment'}
          >
            <TableRow
              key={(row[idColumn] as unknown as string) + ri}
              className={
                isItemSelected(selection, row, idColumn as unknown as string)
                  ? classes.selectedRow
                  : undefined
              }
            >
              {renderCheckbox(row)}
              {isGroupRow(row) &&
                groupByColumns!.map((c, ci) => {
                  if (c.field === SpacerGridCol.fieldName) {
                    return (
                      <TableCell
                        key={
                          (row[idColumn] as unknown as string) +
                          '_' +
                          ri +
                          '_' +
                          ci
                        }
                        colSpan={c.colspan}
                      >
                        {openGroupRowId ===
                        (row[idColumn] as unknown as string) ? (
                          <ArrowUpIcon
                            id={`table-row-${ri}-collapse`}
                            onClick={() => setOpenGroupRowId(null)}
                          />
                        ) : (
                          <ArrowDownIcon
                            id={`table-row-${ri}-expand`}
                            onClick={() =>
                              setOpenGroupRowId(
                                row[idColumn] as unknown as string
                              )
                            }
                          />
                        )}
                      </TableCell>
                    );
                  }

                  return renderCell(row, c, ri, ci);
                })}
            </TableRow>
            {openGroupRowId === (row[idColumn] as unknown as string) &&
              ((row as any)[groupBy!] as []).map((p, pi) => (
                <TableRow
                  key={(row[idColumn] as unknown as string) + ri + pi + 'tr'}
                  className={
                    isItemSelected(
                      selection,
                      row,
                      idColumn as unknown as string,
                      pi + 1
                    )
                      ? classes.selectedRow
                      : undefined
                  }
                >
                  {renderCheckbox(row, pi + 1)}
                  {columns.map((c, ci) => renderCell(row, c, ri, ci, pi + 1))}
                </TableRow>
              ))}
            {openGroupRowId === (row[idColumn] as unknown as string) && (
              <TableRow
                key={(row[idColumn] as unknown as string) + ri + 'tr-divider'}
                sx={{ height: '45px' }}
              ></TableRow>
            )}
          </Fragment>
        );
      } else {
        return renderRow(row, ri);
      }
    });
  };

  if (!data) return null;

  return (
    <>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {onSelectionChange && (
              <TableCell key='general-checkbox'>
                <Checkbox
                  checked={allSelected()}
                  indeterminate={selection.length > 0 && !allSelected()}
                  onChange={(v) => selectAll(v.target.checked)}
                />
              </TableCell>
            )}
            {columns.map((c) => (
              <TableCell key={c.field as string}>{c.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>{renderBody()}</TableBody>
      </Table>
      {handleChangePage && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <TablePagination
            count={totalItems || 0}
            page={currentPage || 0}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage || 0}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
            className={classes.tablePagination}
            color='primary'
            component='div'
          />
        </Box>
      )}
    </>
  );
}
