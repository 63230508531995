import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import {
  CircularProgress,
  Divider,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';

import LoadingButton from 'src/components/LoadingButton';

import { useEffectAsync } from 'src/components/hooks/useEffectAsync';
import {
  CalculateParcelsPriceParams,
  PriceCalculationResult,
  PricingService,
} from 'src/services/api';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ParcelDeliveryType } from 'src/types/delivery/Delivery';

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    zIndex: 5,
    minWidth: '300px',
    background: 'white',
    border: '1px solid #DFDFDF',
    borderRadius: '8px',
    boxSizing: 'border-box',
    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.12)',
    padding: '16px 24px 24px',
  },
  circularProgress: {
    display: 'block !important',
    marginTop: '2em',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  summaryTitle: {
    padding: '0 !important',
    fontSize: '20px !important',
    fontWeight: '800 !important',
  },
  submitButton: {
    boxShadow: '0px 4px 16px 0px #0000001F',
  },
  submitDescription: {
    marginTop: theme.spacing(1),
    fontSize: '11px',
    color: '#5B5B5B',
    textAlign: 'center',
  },
}));

function PriceRow(props: {
  label?: string;
  value: number;
  extraValue?: string;
  valuePrefix?: string;
  priceSx?: SxProps<any>;
  id?: string;
}) {
  return (
    <>
      <Grid
        item
        xs={8}
        sx={{
          color: props.value < 0 ? '#EC1D05' : '#000',
        }}
      >
        {props.label}
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          textAlign: 'right',
          color: props.value < 0 ? '#EC1D05' : '#000',
          fontWeight: 700,
          ...props.priceSx,
        }}
        id={props.id}
      >
        {props.valuePrefix}
        {props.value < 0 && '-'}$ {Math.abs(props.value).toFixed(2)}
        {props.extraValue && (
          <>
            <br />
            <span
              id={`${props.id}-extra`}
              style={{ fontSize: '14px', fontWeight: 400 }}
            >
              {props.extraValue}
            </span>
          </>
        )}
      </Grid>
    </>
  );
}

function GridDivider() {
  return (
    <Grid item xs={12}>
      <Divider />
    </Grid>
  );
}

export interface PaymentSummaryProps {
  onSubmit: () => void;
  disableSubmit: boolean;
  parcelsIds?: string[];
  salesAgreementSigned: boolean;
}

export default function PaymentSummary({
  disableSubmit,
  onSubmit,
  parcelsIds,
  salesAgreementSigned,
}: PaymentSummaryProps) {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [pricing, setPricing] = useState<PriceCalculationResult | null>(null);

  useEffectAsync(async () => {
    if (salesAgreementSigned) return;
    parcelsIds &&
      PricingService.getParcelsPricing({ ids: parcelsIds })
        .then((r) => {
          setPricing(r);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          toast.error('Could not fetch pricing information.', {
            hideProgressBar: true,
          });
          setLoading(false);
        });
  }, [salesAgreementSigned, parcelsIds]);

  // TODO: consider making unitPrice.SameDay and unitPrice.NextDay values not optional on backend side
  const sameDayParcelsLength =
    pricing?.itemsPrices?.filter(
      (obj) => obj.deliveryType === ParcelDeliveryType.Sameday
    ).length || 0;
  const nextDayParcelsLength =
    pricing?.itemsPrices?.filter(
      (obj) => obj.deliveryType === ParcelDeliveryType.Nextday
    ).length || 0;
  const sameDayParcelsTotal =
    sameDayParcelsLength * pricing?.unitPrice?.SameDay!.value!;
  const nextDayParcelsTotal =
    nextDayParcelsLength * pricing?.unitPrice?.NextDay!.value!;

  return (
    <Box className={classes.summaryContainer} sx={{ minWidth: '470px' }}>
      <Typography variant='h6' className={classes.summaryTitle}>
        Delivery Summary
      </Typography>
      <Grid container spacing={1} mt={1}>
        <>
          {!pricing && (
            <CircularProgress
              color='secondary'
              className={classes.circularProgress}
              size={22}
            />
          )}
          {pricing && (
            <>
              {sameDayParcelsLength > 0 && (
                <PriceRow
                  id='delivery-summary-sameday-number'
                  label={sameDayParcelsLength + ' x parcel shipping (same-day)'}
                  valuePrefix={`${sameDayParcelsLength} x `}
                  value={sameDayParcelsTotal / sameDayParcelsLength}
                />
              )}
              {nextDayParcelsLength > 0 && (
                <PriceRow
                  id='delivery-summary-nextday-number'
                  label={nextDayParcelsLength + ' x parcel shipping (next-day)'}
                  valuePrefix={`${nextDayParcelsLength} x `}
                  value={nextDayParcelsTotal / nextDayParcelsLength}
                />
              )}
              <GridDivider />
              <PriceRow
                id='delivery-summary-summary'
                label='Summary'
                value={sameDayParcelsTotal + nextDayParcelsTotal}
              />
              {pricing.totalDiscount.value > 0 && (
                <PriceRow
                  id='delivery-summary-discount'
                  label='Discount'
                  value={-pricing.totalDiscount.value}
                />
              )}
              <GridDivider />
              <PriceRow
                id='delivery-summary-total'
                label='Total price'
                value={pricing.totalPrice.value}
                extraValue={`inc. GST $ ${pricing.totalTax.value.toFixed(2)}`}
                priceSx={{
                  fontSize: '20px',
                  lineHeight: '22px',
                }}
              />
            </>
          )}
        </>
        <Grid item xs={12} mt={2}>
          <LoadingButton
            id='delivery-confirm'
            className={classes.submitButton}
            loading={disableSubmit}
            disabled={loading}
            type='submit'
            onClick={onSubmit}
            variant='contained'
            color='primary'
            fullWidth
          >
            {salesAgreementSigned ? 'Confirm & Add to bill' : 'Confirm & Pay'}
          </LoadingButton>
        </Grid>
      </Grid>
      {/* } */}
    </Box>
  );
}
