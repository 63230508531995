import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStateWithStatus } from 'src/app/hooks';
import { RootState } from 'src/app/store';
import { HttpService } from 'src/services/httpService';
import { OrganizationReadonlyService } from 'src/services/readonly/organization';
import { OrganizationModel } from 'src/services/readonly/organization.types';

export interface OrganizationState extends IStateWithStatus {
  orgId?: string;
  organization?: OrganizationModel;
}

const initialState: OrganizationState = {
  status: 'idle',
  orgId: undefined,
  organization: undefined,
};

export const fetchOrganizatonIdAsync = createAsyncThunk(
  'organization/fetchOrganizationId',
  async (model, thunkAPI) => {
    const state = (thunkAPI.getState() as RootState).settings;
    if (state.orgId) return state.orgId;

    return await OrganizationReadonlyService.getOrganizationId();
  }
);

export const fetchOrganizationInfo = createAsyncThunk(
  'organization/fetchOrganizationInfo',
  async (model, thunkAPI) => {
    return await OrganizationReadonlyService.getOrganization();
  }
);

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationInfo: (state, action: PayloadAction<any>) => {
      state.organization = action.payload;
    },
    clearState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    HttpService.registerAsyncAction(
      builder,
      fetchOrganizatonIdAsync,
      (payload, state) => {
        state.orgId = payload;
      }
    );

    HttpService.registerAsyncAction(
      builder,
      fetchOrganizationInfo,
      (payload, state) => {
        state.organization = payload;
      }
    );
  },
});

export const { setOrganizationInfo, clearState } = organizationSlice.actions;
export const selectOrganization = (state: RootState) => state.organization;
export default organizationSlice.reducer;
