import { Box, Grid, RadioGroup, Typography } from '@mui/material';
import AddressForm from 'src/components/form/AddressForm';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPhoneField } from 'src/components/form/FormPhoneField';
import FormSection from 'src/components/form/FormSection';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import { PlaceType } from 'src/components/maps';
import { CreateParcelModel } from 'src/types/delivery/Delivery';
import { useStyles } from './DeliveryForm';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Radio from '@mui/material/Radio/Radio';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import { selectEditDelivery } from 'src/features/delivery/editDelivery/editDeliverySlice';
import { useAppSelector } from 'src/app/hooks';

export function ReceiverDetails(props: {
  warehouseId?: string;
  errors: Record<string, any>;
  countryRestrictions?: ('au' | 'pl')[];
  selectedPlace: {
    value: PlaceType | null;
    set: (value: PlaceType) => void;
  };
  optionalEmail?: boolean;
  optionalPhone?: boolean;
  optionalName?: boolean;
}) {
  const { order } = useAppSelector(selectEditDelivery);
  const firstParcel = order && order[0];

  const classes = useStyles();
  const { control, watch } = useFormContext<CreateParcelModel>();
  const form = useFormContext<CreateParcelModel>();

  watch('receiverData.phone');

  const isPhoneValid = (phone: string) => {
    const allowedPrefixes = [
      '04',
      '+04',
      '4',
      '+4',
      '614',
      '+614',
      '6104',
      '+6104',
      '00614',
      '+00614',
      '006104',
      '+006104',
    ];
    const lastNineDigitsOfNumber = phone.replace(/\s/g, '').slice(-9);

    if (
      !(
        allowedPrefixes.some((prefix) => phone.startsWith(prefix)) &&
        lastNineDigitsOfNumber.startsWith('4')
      )
    ) {
      return false;
    }
    if (phone.length < 9 || phone.length > 14) {
      return false;
    }
    return true;
  };

  const displayPhoneWarning = () => {
    const phoneNumber = form.getValues('receiverData.phone');

    if (
      phoneNumber &&
      !form.formState.errors?.receiverData?.phone &&
      !isPhoneValid(phoneNumber)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getFullNameValidatiionRules = () => {
    if (props.optionalName) {
      return {};
    }
    return {
      required: 'Please provide full name.',
      minLength: {
        value: 1,
        message: 'Full name must have at least 1 character.',
      },
      maxLength: {
        value: 32,
        message: 'Full name must have less than 32 characters.',
      },
    };
  };
  return (
    <FormSection title='Delivery details' id='deliveryDetails'>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h4'>Receiver details</Typography>

          <Box className={classes.fieldlistItem}>
            <TitledFormTextField<CreateParcelModel>
              id='receiverData.businessName'
              TextField={{
                type: 'text',
                label: 'Business name',
                placeholder: 'Enter company name',
              }}
              rules={{
                maxLength: {
                  value: 32,
                  message: 'Business name must have less than 32 characters.',
                },
              }}
            />
          </Box>

          <Box className={classes.fieldlistItem}>
            <TitledFormTextField<CreateParcelModel>
              id='receiverData.fullName'
              TextField={{
                type: 'text',
                label: 'Full name',
                placeholder: 'Enter first name and last name',
              }}
              rules={getFullNameValidatiionRules()}
            />
          </Box>

          <Box className={classes.fieldlistItem}>
            <Grid container spacing={12}>
              <Grid item xs={12}>
                <FormPhoneField<CreateParcelModel>
                  id='receiverData.phone'
                  hasWarning={displayPhoneWarning()}
                  required={!props.optionalPhone}
                />
                {displayPhoneWarning() && (
                  <FormHelperText
                    id='receiverData.phone-helper-text'
                    sx={{ color: '#f5a200' }}
                  >
                    Invalid mobile number. Fix to ensure recipient receives SMS
                    delivery updates!
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.fieldlistItem}>
            <FormEmailField<CreateParcelModel>
              id='receiverData.email'
              required={!props.optionalEmail}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h4'>Address</Typography>
          <Grid container rowSpacing={2}>
            <AddressForm<CreateParcelModel>
              warehouseId={props.warehouseId}
              value={props.selectedPlace.value}
              onPlaceSelect={(item) => props.selectedPlace.set(item)}
              idPrefix='parcelLocation.shippingAddress'
              addressSearch={{
                helperText:
                  'Start with building number, street name and then select the address from the proposed',
              }}
              countryRestrictions={props.countryRestrictions}
            />
            <Grid item xs={12} md={6}>
              <Box className={classes.textFieldName}>Premise type</Box>
              <Controller
                name='parcelLocation.shippingAddress.premiseType'
                defaultValue={
                  firstParcel?.parcelLocation.shippingAddress.premiseType
                    ? firstParcel?.parcelLocation.shippingAddress.premiseType
                    : 'Commercial'
                }
                control={control as any}
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required.',
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormControl
                        component='fieldset'
                        error={fieldState.invalid}
                      >
                        <RadioGroup
                          value={field.value}
                          onChange={field.onChange}
                          color='inherit'
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                          }}
                        >
                          <FormControlLabel
                            value='Commercial'
                            control={<Radio id='commercial' />}
                            label='Commercial'
                          />
                          <FormControlLabel
                            value='Private'
                            control={<Radio id='private' />}
                            label='Residential'
                          />
                        </RadioGroup>
                        {fieldState.error && (
                          <FormHelperText>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} rowSpacing={2}>
              <TitledFormTextField<CreateParcelModel>
                id='parcelLocation.shippingAddress.hint'
                TextField={{
                  multiline: true,
                  rows: 4,
                  type: 'text',
                  label: 'Instructions for the courier',
                  placeholder: 'Comments or instructions for courier',
                }}
                rules={{
                  maxLength: {
                    value: 125,
                    message: 'Instructions must have less than 125 characters.',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormSection>
  );
}
