import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { DeliveryService } from 'src/features/delivery/deliveryAPI';
import { HttpService } from 'src/services/httpService';

type StateStatus = 'idle' | 'loading' | 'failed' | 'succeeded';

export interface NewDeliveryState {
  status: StateStatus;
  errorMessage?: string | undefined;
  errors?: any | undefined;
}

const initialState: NewDeliveryState = {
  status: 'idle',
  errorMessage: undefined,
  errors: undefined,
};

export const createNewDeliveryAsync = HttpService.wrapApiAction(
  'newDelivery/create',
  DeliveryService.createNewOrder
);

export const newDeliverySlice = createSlice({
  name: 'newDelivery',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.errors = undefined;
      state.errorMessage = undefined;
      return state;
    },
  },
  extraReducers: (builder) => {
    HttpService.registerApiAction(builder, createNewDeliveryAsync);
  },
});

export const { clearState } = newDeliverySlice.actions;
export const selectNewDelivery = (state: RootState) => state.newDelivery;
export default newDeliverySlice.reducer;
