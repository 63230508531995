import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface FormValueProps {
  label: string;
  value?: string;
  children?: JSX.Element | JSX.Element[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  header: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#6B6B6B',
    padding: `4px 0`,
  },
  value: {
    fontSize: '16px',
    lineHeight: '20px',
  },
}));

export function FormValue(props: FormValueProps) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>{props.label}</Box>
      <Box className={classes.value}>
        {props.value}
        {props.children}
      </Box>
    </Box>
  );
}
