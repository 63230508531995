import { TextField } from '@mui/material';
import { FormTextFieldProps } from './types';
import { Controller, useFormContext } from 'react-hook-form';
import { ClipboardEvent, KeyboardEvent } from 'react';

export default function FormTextField<T>(props: FormTextFieldProps<T>) {
  const { control } = useFormContext();
  const {
    TextField: textField,
    id,
    rules,
    onChange,
    onPaste,
    children,
    ...defaultProps
  } = props;

  const defaultPattern = '(.*?)';
  const pattern = new RegExp(props.pattern || defaultPattern);

  const handleOnKeyPress = (e: KeyboardEvent<any>) => {
    if (!pattern.test(e.key)) e.preventDefault();
  };

  // prevent invalid content pasting
  const handleOnPaste = (e: ClipboardEvent<any>) => {
    const paste = (e.clipboardData || (window as any).clipboardData).getData(
      'Text'
    );
    if (!pattern.test(paste)) e.preventDefault();
  };

  return (
    <Controller
      {...defaultProps}
      name={id as any}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <TextField
            id={id}
            size='small'
            fullWidth
            variant='outlined'
            error={fieldState.invalid}
            value={field.value}
            onBlur={field.onBlur}
            onChange={(change) => {
              onChange && onChange(change);
              field.onChange(change);
            }}
            onWheel={(e) => {
              (document as any).activeElement.blur();
              e.cancelable && e.preventDefault();
            }}
            {...textField}
            helperText={fieldState.error?.message || textField.helperText}
            onKeyPress={handleOnKeyPress}
            onPaste={onPaste || handleOnPaste}
          >
            {children}
          </TextField>
        );
      }}
      control={control as any}
      defaultValue={(textField?.defaultValue as any) || ''}
    />
  );
}
