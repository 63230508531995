import { Button, Grid } from '@mui/material';

import { OnboardingModel, StepDefinition } from '../types';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { RemoveIcon } from '../icons';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import { updateTeamMembersCount } from '../onboardingSlice';

export const TeamMembersStepDefinition: StepDefinition = {
  title: 'Add team members',
  subtitle:
    'You can update user permissions on the team members page after sending the email invitation.',
  skipable: true,
  submitButtonText: 'Send invitation',
};

export function TeamMembers() {
  const dispatch = useAppDispatch();
  const { control, watch, formState } = useFormContext<OnboardingModel>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'teamMembers',
  });

  useEffect(() => {
    if (formState.isValid) {
      dispatch(updateTeamMembersCount(watch().teamMembers?.length || 0));
    } else {
      dispatch(updateTeamMembersCount(0));
    }
  }, [dispatch, watch, formState]);

  const addNext = () => {
    append({
      value: '',
    });
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      {fields.map((field, i) => (
        <Grid
          item
          xs={12}
          key={field.id}
          id={`email${i + 1}`}
          sx={{ position: 'relative' }}
        >
          <FormEmailField<OnboardingModel>
            id={`teamMembers.${i}.value` as any}
            TextField={{
              variant: 'outlined',
              label: 'E-mail address',
              placeholder: 'Enter e-mail address',
            }}
            rules={{
              validate: (value: any) =>
                watch('teamMembers').filter(
                  (t) =>
                    (t.value || '').toLowerCase().trim() ===
                    (value || '').toLowerCase().trim()
                ).length > 1
                  ? 'This email address has already been added'
                  : undefined,
            }}
            required
          />
          {i > 0 && (
            <RemoveIcon
              sx={{
                position: 'absolute',
                right: '4px',
                top: '44px',
                cursor: 'pointer',
              }}
              onClick={() => onRemove(i)}
            />
          )}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant='outlined'
          color='inherit'
          onClick={addNext}
          id='team-members-new-button-add'
        >
          Add another
        </Button>
      </Grid>
    </Grid>
  );
}
