/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordParams } from '../models/ChangePasswordParams';
import type { CreateMobileAccountParams } from '../models/CreateMobileAccountParams';
import type { ResetPasswordParams } from '../models/ResetPasswordParams';
import type { UpdateAccountParams } from '../models/UpdateAccountParams';
import type { UpdatePrinterSettingsParams } from '../models/UpdatePrinterSettingsParams';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountsService {

  /**
   * Begin the password reseting procedure
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static resetPassword(
    requestBody: ResetPasswordParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/accounts/passwordReset',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Change password of authenticated user
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static changePassword(
    requestBody: ChangePasswordParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/accounts/password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `Provided incorrect old password`,
        422: `Parameters validation error`,
      },
    });
  }

  /**
   * Creates a new mobile user account
   * @param requestBody
   * @returns User Account of the mobile user
   * @throws ApiError
   */
  public static createMobileAccount(
    requestBody: CreateMobileAccountParams,
  ): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/accounts/mobile',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        422: `Parameters validation error`,
      },
    });
  }

  /**
   * Updates an account details
   * @param id ID of the account to be updated
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static updateAccount(
    id: string,
    requestBody: UpdateAccountParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/accounts/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Parameters validation error`,
      },
    });
  }

  /**
   * Updates user printer settings
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static updatePrinterSettings(
    requestBody: UpdatePrinterSettingsParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/accounts/settings/printer',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation error`,
      },
    });
  }

}