import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { AppUser } from 'src/services/appUserService';
import { fetchAppUser, fetchAppUserOrganizationRole } from './appUserAPI';

type StateStatus = 'idle' | 'loading' | 'failed' | 'succeeded';
export interface AppUserState {
  status: StateStatus;
  user?: AppUser;
  userOrganizationRole?: string;
  errorMessage?: string;
}

const initialState: AppUserState = {
  status: 'idle',
  user: undefined,
  userOrganizationRole: undefined,
  errorMessage: undefined,
};

export const fetchAppUserAsync = createAsyncThunk<AppUser | undefined>(
  'appUser/fetch',
  async (_, thunkAPI) => {
    try {
      const response = await fetchAppUser();
      if (response !== undefined) {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.error('Error', e.message);
      thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchAppUserOrganizationRoleAsync = createAsyncThunk(
  'appUser/fetchOrganizationRole',
  async (orgid: string, thunkAPI) => {
    try {
      const response = await fetchAppUserOrganizationRole(orgid);
      if (response !== undefined) {
        return response.roles;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.error('Error', e.message);
      thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const appUserSlice = createSlice({
  name: 'appUser',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.errorMessage = undefined;
      state.user = undefined;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAppUserAsync.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.user = payload;
      })
      .addCase(fetchAppUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error?.message;
      })
      .addCase(fetchAppUserOrganizationRoleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchAppUserOrganizationRoleAsync.fulfilled,
        (state, { payload }) => {
          state.status = 'succeeded';
          state.userOrganizationRole = payload;
        }
      )
      .addCase(fetchAppUserOrganizationRoleAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error?.message;
      });
  },
});

export const { clearState } = appUserSlice.actions;
export const selectAppUser = (state: RootState) => state.appUser;
export default appUserSlice.reducer;
