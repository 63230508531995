import { makeStyles, DefaultTheme } from '@mui/styles';

type Props = {
  bgColor?: string;
};

export default makeStyles<DefaultTheme, Props>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: (props: Props) => props.bgColor || '#f9f9f9',
    padding: '2rem 1rem',
    minHeight: '100vh',
  },
}));
