import { FormControl, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import LoadingButton from 'src/components/LoadingButton';
import {
  changeWarehouseSelection,
  clearState,
  fetchWarehousesAsync,
  selectWarehouse,
} from './warehouseSlice';

export function WarehouseSelector() {
  const dispatch = useAppDispatch();

  const { status, selectedWarehouseId, warehouses } =
    useAppSelector(selectWarehouse);

  useEffect(() => {
    dispatch(fetchWarehousesAsync());
  }, [dispatch]);

  //TODO: React for firebase change

  if (status === 'loading') {
    return (
      <LoadingButton
        loading={true}
        fullWidth
        sx={{ background: '#F5F4F4 !important' }}
      />
    );
  }

  return (
    <FormControl fullWidth>
      <Select
        value={selectedWarehouseId || ''}
        onChange={(e) =>
          dispatch(changeWarehouseSelection(e.target.value as string))
        }
        sx={{
          background: '#F5F4F4 !important',
          '& .MuiSelect-select': {
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
          },
        }}
        disabled={warehouses.length < 2}
      >
        {warehouses.map((w) => (
          <MenuItem key={`warehouseItem_${w.id}`} value={w.id}>
            {w.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
