import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface IStateWithStatus {
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  errorMessage?: string;
  errors?: any;
}
