import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  clearState,
  registerAsync,
  selectRegistration,
} from './registrationSlice';
import { RegisterFormModel } from './model';
import { RegisterOrganizationCommandParams } from 'src/services/api/models/RegisterOrganizationCommandParams';
import useStyles from './styles';

import BasicInformationForm from './register/BasicForm';
import BusinessInformationForm from './register/BusinessForm';
import { FormProvider, useForm } from 'react-hook-form';
import { DialogTitle } from '@mui/material';
import { ArrowLeftIcon } from '../delivery/components/icons';
import { PlaceType } from 'src/components/maps';

function getSteps(
  handleSubmit: (stepIndex: number, data: RegisterFormModel) => void,
  selectedPlace: PlaceType | null,
  setSelectedPlace: (value: PlaceType | null) => void
): JSX.Element[] {
  const steps = [
    <BasicInformationForm
      onSubmit={handleSubmit}
      selectedPlace={selectedPlace}
      setSelectedPlace={setSelectedPlace}
    />,
    <BusinessInformationForm
      onSubmit={handleSubmit}
      selectedPlace={selectedPlace}
      setSelectedPlace={setSelectedPlace}
    />,
  ];
  return steps;
}

function Register() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const { status, errorMessage, errors } = useAppSelector(selectRegistration);
  const form = useForm<RegisterFormModel>({
    defaultValues: {},
    reValidateMode: 'onSubmit',
    mode: 'all',
  });
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | null>(null);

  const { clearErrors } = form;

  const setSelectedPlaceValue = (value: PlaceType | null) => {
    setSelectedPlace(value);
    // @ts-ignore
    clearErrors('address');
  };

  const toastError = (text: string) => {
    toast.error(text, {
      hideProgressBar: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (status === 'failed') {
      if (errors?.email) {
        setCurrentStepIndex(0);
      }
      const errorString: string = Object.values(errors).toString();
      toastError(errorString || 'Error occured.');
    }
    if (status === 'succeeded') {
      toast.success('Account has been created. You can now log in.', {
        type: 'success',
        hideProgressBar: true,
        progress: undefined,
      });
      history.push('/login');
      dispatch(clearState());
    }
  }, [status, dispatch, errorMessage, history, errors]);

  const onSubmit = (stepIndex: number, data: RegisterFormModel) => {
    if (stepIndex === stepsCount - 1) {
      const registerData = {
        email: data.email,
        password: data.password,
        organizationName: data.organizationName,
        companyEmail: data.companyEmail,
        companyPhone: data.phoneNumber,
        phoneNumber: data.phoneNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        abn: data.abn.replace(/\D/g, ''),
        acceptEula: data.acceptEula,
        address: data.address,
      } as RegisterOrganizationCommandParams;

      dispatch(registerAsync(registerData));
    } else {
      setCurrentStepIndex(stepIndex + 1);
    }
  };

  const steps = getSteps(onSubmit, selectedPlace, setSelectedPlaceValue);
  const stepsCount = steps.length;

  const navigateBack = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };

  const currentStepComponent = steps[currentStepIndex];

  return (
    <Container component='main' maxWidth='xs'>
      <Paper elevation={0} square={true} className={classes.contentBox}>
        <CssBaseline />
        {currentStepIndex > 0 && status !== 'loading' && (
          <Button
            id='registerBackButton'
            variant='text'
            className={classes.backButton}
            onClick={navigateBack}
          >
            <ArrowLeftIcon />
          </Button>
        )}
        <DialogTitle className={classes.dialogTitle}>
          {currentStepIndex === 0 && 'Create business account'}
          {currentStepIndex === 1 && 'Add company information'}
        </DialogTitle>
        <FormProvider {...form}>{currentStepComponent}</FormProvider>
        <Grid className={classes.additionalTextBox}>
          <Typography variant='body2'>Already have an account?</Typography>
          <Link
            id='registerSignInLink'
            component={RouterLink}
            to='/login'
            variant='body2'
            color='secondary'
            className={classes.link}
          >
            Sign in
          </Link>
        </Grid>
      </Paper>
    </Container>
  );
}

export default Register;
