import { Tabs, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TabPanel } from 'src/components/form/TabPanel';
import DraftOrders from 'src/features/dashboard/draft-orders/DraftOrders';
import Dispatching from 'src/features/dashboard/dispatching/Dispatching';
import useStyles from './Dashboard.styles';
import { useHistory, useLocation } from 'react-router-dom';
import Filters from './filters/Filters';

export default function Dashboard() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [value, setValue] = useState(
    (location.pathname || '') === '/dashboard' ? 0 : 1
  );

  useEffect(() => {
    const value = location.pathname || '';
    switch (value) {
      case '/dashboard':
        setValue(0);
        break;

      case '/dashboard/dispatching':
        setValue(1);
        break;
    }
  }, [location, history]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 0:
        history.push('/dashboard');
        break;

      case 1:
        history.push('/dashboard/dispatching');
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Filters />
      <Tabs value={value} onChange={handleChange}>
        <Tab
          disableRipple
          label='Draft orders'
          id='draft-orders-tab'
          aria-controls='draft-orders-tab'
        />
        <Tab
          disableRipple
          label='Dispatching'
          id='dispatching-tab'
          aria-controls='dispatching-tab'
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <DraftOrders />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Dispatching />
      </TabPanel>
    </div>
  );
}
