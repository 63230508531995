import TitledFormTextField from './TitledFormTextField';
import { FieldError, Path, RegisterOptions } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';

import React from 'react';

export default function FormAbnField<T>(props: {
  id: Path<T>;
  errorData: FieldError | undefined;
  TextField?: TextFieldProps;
  rules?: Omit<
    RegisterOptions<T, Path<T>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}) {
  const { errorData } = props;
  return (
    <TitledFormTextField
      id={props.id}
      rules={{
        required: 'Please provide company ABN.',
        validate: (value: any) => {
          value = ((value as string) || '')?.replace(/\D/g, '');
          if (value.length < 11) {
            return 'ABN is too short.';
          }
          if (value.length > 11) {
            return 'ABN is too long.';
          }
        },
        ...props.rules,
      }}
      TextField={{
        helperText: errorData,
        error: !!errorData,
        type: 'text',
        label: 'Company ABN',
        placeholder: 'Enter company ABN',
        ...props.TextField,
      }}
      pattern='[0-9- ()]'
    />
  );
}
