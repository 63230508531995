import { PlaceType } from 'src/components/maps';
import { StepDefinition } from '../types';
import { WarehouseWriteForm } from 'src/features/warehouse/WarehouseWriteForm';

export const PickupPointStepDefinition: StepDefinition = {
  title: 'Define pickup point',
  subtitle: 'Set the address of your warehouse or pickup location.',
};

export interface PickupPointProps {
  selectedPlace: { value: PlaceType | null; set: (value: PlaceType) => void };
}

export function PickupPoint(props: PickupPointProps) {
  return <WarehouseWriteForm {...props} />;
}
