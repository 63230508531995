import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import {
  markReadyForCourier,
  printLabels,
  removePackage,
} from './draftOrdersAPI';

type OperationStatus = 'idle' | 'loading' | 'failed' | 'succeeded';

export interface DraftOrdersState {
  printingStatus: OperationStatus;
  dispatchingStatus: OperationStatus;
  removingStatus: OperationStatus;
  error: string | undefined;
}

const initialState: DraftOrdersState = {
  error: undefined,
  printingStatus: 'idle',
  dispatchingStatus: 'idle',
  removingStatus: 'idle',
};

export const printLabelsAsync = createAsyncThunk<void, string[]>(
  'dispatching/printLabels',
  async (params, thunkAPI) => {
    try {
      await printLabels(params);
    } catch (e: any) {
      console.error('Error', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const markReadyForCourierAsync = createAsyncThunk<void, string[]>(
  'dispatching/markReadyForCourier',
  async (params, thunkAPI) => {
    try {
      await markReadyForCourier(params);
    } catch (e: any) {
      console.error('Error', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const removePackagesAsync = createAsyncThunk<void, string[]>(
  'dispatching/removePackages',
  async (params, thunkAPI) => {
    try {
      for (let id of params) {
        await removePackage(id);
      }
    } catch (e: any) {
      console.error('Error', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const dispatchingSlice = createSlice({
  name: 'dispatching',
  initialState,
  reducers: {
    clearState: (state) => {
      state.printingStatus = 'idle';
      state.dispatchingStatus = 'idle';
      state.removingStatus = 'idle';
      state.error = undefined;
      return state;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(printLabelsAsync.pending, (state) => {
        state.printingStatus = 'loading';
        state.error = undefined;
      })
      .addCase(printLabelsAsync.fulfilled, (state) => {
        state.printingStatus = 'succeeded';
      })
      .addCase(printLabelsAsync.rejected, (state, action) => {
        state.printingStatus = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(markReadyForCourierAsync.pending, (state) => {
        state.dispatchingStatus = 'loading';
        state.error = undefined;
      })
      .addCase(markReadyForCourierAsync.fulfilled, (state) => {
        state.dispatchingStatus = 'succeeded';
      })
      .addCase(markReadyForCourierAsync.rejected, (state, action) => {
        state.dispatchingStatus = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(removePackagesAsync.pending, (state) => {
        state.removingStatus = 'loading';
        state.error = undefined;
      })
      .addCase(removePackagesAsync.fulfilled, (state) => {
        state.removingStatus = 'succeeded';
      })
      .addCase(removePackagesAsync.rejected, (state, action) => {
        state.removingStatus = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearState } = dispatchingSlice.actions;
export const selectDispatching = (state: RootState): DraftOrdersState =>
  state.draftOrders as any;
export default dispatchingSlice.reducer;
