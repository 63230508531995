import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  table: {
    borderRadius: 0,
    '& td': {
      wordBreak: 'break-word',
      fontSize: '13px',
      [theme.breakpoints.down('xl')]: {
        fontSize: '12px',
      },
    },
  },
  tableHead: {
    '&, & th': {
      fontSize: '11px',
      fontWeight: 700,
      color: '#6b6b6b',
      background: '#FCFCFC',
      textTransform: 'uppercase',
    },
    '& th': {
      padding: '0 8px 0 0',
      lineHeight: '32px',
      whiteSpace: 'nowrap',
    },
    '& tr:first-child': {
      marginLeft: '8px',
    },
  },
  tableBody: {
    fontSize: '13px',
    lineHeight: '14px',
    color: '#000000',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
    },
    '& tr td': {
      padding: '10px 8px 10px 0',
      verticalAlign: 'top',
    },
  },
  selectedRow: {
    background: '#F5F4F4',
  },
  tablePagination: {
    '&.MuiTableCell-root': {
      border: 0,
    },
    '& .MuiToolbar-root': {
      padding: '10px 0 5px',
      border: 0,
    },
  },
}));
