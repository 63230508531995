import amexCardLogo from 'src/assets/cards/amex.svg';
import cbCardLogo from 'src/assets/cards/cb.svg';
import dinersCardLogo from 'src/assets/cards/diners.svg';
import discoverCardLogo from 'src/assets/cards/discover.svg';
import jcbCardLogo from 'src/assets/cards/jcb.svg';
import mastercardCardLogo from 'src/assets/cards/mastercard.svg';
import unionpayCardLogo from 'src/assets/cards/unionpay.svg';
import visaCardLogo from 'src/assets/cards/visa.svg';
import unknownCardLogo from 'src/assets/cards/unknown.svg';

export const cardLogos = [
  {
    brand: 'amex',
    image: amexCardLogo,
  },
  {
    brand: 'cb',
    image: cbCardLogo,
  },
  {
    brand: 'diners',
    image: dinersCardLogo,
  },
  {
    brand: 'discover',
    image: discoverCardLogo,
  },
  {
    brand: 'jcb',
    image: jcbCardLogo,
  },
  {
    brand: 'mastercard',
    image: mastercardCardLogo,
  },
  {
    brand: 'unionpay',
    image: unionpayCardLogo,
  },
  {
    brand: 'visa',
    image: visaCardLogo,
  },
  {
    brand: 'unknown',
    image: unknownCardLogo,
  },
];
