import {
  AppUser,
  getCurrentAppUser,
  getCurrentAppUserOrganizationRole,
} from 'src/services/appUserService';

export async function fetchAppUser(): Promise<AppUser | undefined> {
  const value = await getCurrentAppUser();
  return value || undefined;
}

export async function fetchAppUserOrganizationRole(
  orgid: string
): Promise<any | undefined> {
  const value = await getCurrentAppUserOrganizationRole(orgid);
  return value || undefined;
}
