import { getAuth } from 'firebase/auth';
import { config } from 'src/config';
import { getCollection } from 'src/services/databaseService';
import { CreateParcelModel, Parcel } from 'src/types/delivery/Delivery';
import { download } from 'src/utilities/utilities';
import { HttpService } from 'src/services/httpService';
import {
  CreateOrderCommandParams,
  CreateOrderResponse,
  OrdersService,
  UpdateOrderCommandParams,
  CancelablePromise,
} from 'src/services/api';
import { WebParcelProjection } from 'src/services/api/models/WebParcelProjection';

export async function fetchRecentDeliveries(): Promise<Parcel[]> {
  const value = await getCollection<Parcel>({
    path: `projections/sender/parcels`,
    uidField: 'senderId',
  });
  return value;
}

export const getParcelLabel = async (
  parcelId: string,
  referenceNumber?: string
) => {
  const token = await getAuth()?.currentUser?.getIdToken();
  const res = await fetch(config.backendUrl + '/getParcelLabel', {
    method: 'POST',
    body: JSON.stringify({ parcelId }),
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  const blob = await res.blob();
  download(blob, `DIP_${referenceNumber ?? parcelId}_label.pdf`);
};

export namespace DeliveryService {
  export interface CreateDeliveryRequest {
    delivery: CreateParcelModel;
  }

  export interface CreateDeliveryResponse
    extends HttpService.HttpsResponse<{ id: string; parcelsIds: string[] }> {}

  export async function fetchSelectedOrder(
    orderId: string
  ): CancelablePromise<Array<WebParcelProjection>> {
    return OrdersService.getOrder(orderId);
  }

  export async function createNewOrder(
    request: CreateOrderCommandParams
  ): Promise<CreateOrderResponse> {
    const requestData: CreateOrderCommandParams = {
      ...request,
      deliveryType: request.deliveryType,
      parcelLocation: {
        pickupWarehouseId: request.parcelLocation.pickupWarehouseId,
        pickupAddress: {
          ...request.parcelLocation.pickupAddress,
        },
        shippingAddress: {
          ...request.parcelLocation.shippingAddress,
        },
      },
      ///NOTE: We are converting numeric value just in case they might be strings from user input form
      parcels: request.parcels.map((p) => ({
        weight: parseFloat(p.weight as any as string),
        dimension_x: parseFloat(p.dimension_x as any as string),
        dimension_y: parseFloat(p.dimension_y as any as string),
        dimension_z: parseFloat(p.dimension_z as any as string),
        requiresAttention: p.requiresAttention,
        containsAlcohol: p.containsAlcohol,
      })),
    };

    return await OrdersService.create(requestData);
  }

  export interface updateOrderRequest extends UpdateOrderCommandParams {
    id: string;
  }

  export async function updateOrder(
    request: updateOrderRequest
  ): Promise<void> {
    return OrdersService.update(request.id, request);
  }
}
