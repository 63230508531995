import { FirebaseOptions } from 'firebase/app';
import * as firebase from 'firebase/app';

import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { config } from 'src/config';

export const firebaseConfig: FirebaseOptions = config.firebaseConfig;

export default function initializeApp() {
  firebase.initializeApp(firebaseConfig);

  // if (window.location.hostname === "localhost") {
  // const db = getDatabase();
  // const functions = getFunctions();
  // connectDatabaseEmulator(db, "localhost", 9000);
  // connectFunctionsEmulator(functions, "localhost", 5001);
  // }

  setPersistence(getAuth(), browserLocalPersistence).catch((error) => {
    console.error(error.message);
  });
}
