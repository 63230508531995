import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface WarningDialogProps {
  title?: string;
  message: string;
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const WarningDialog = (props: WarningDialogProps) => {
  const { title, message, isVisible, onClose, onConfirm } = props;

  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: '30px 20px',
          minWidth: '400px',
          maxWidth: '470px',
        },
      }}
    >
      {title && (
        <DialogTitle
          sx={{ m: 0, mb: 2, p: 0, textAlign: 'left' }}
          id='warning-dialog-title'
        >
          {title}
        </DialogTitle>
      )}
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{ position: 'absolute', right: 5, top: 5 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ mx: 0 }} id='warning-dialog-message'>
        {message}
      </DialogContent>
      <DialogActions sx={{ m: 0 }}>
        <Button
          id='warning-dialog-cancel'
          variant='outlined'
          color='inherit'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          id='warning-dialog-confirm'
          variant='contained'
          onClick={onConfirm}
        >
          Ignore
        </Button>
      </DialogActions>
    </Dialog>
  );
};
