import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import DisplayTable, { GridColDef } from 'src/components/table';
import { AddIcon } from 'src/features/delivery/components/icons';
import { MuiLink } from 'src/components/MuiLink';
import {
  deleteInvitationAsync,
  deleteUserAsync,
  resendInvitationAsync,
  selectSettings,
  sendInvitationsAsync,
} from '../settingsSlice';
import { OnboardingModel } from 'src/features/onboarding/types';
import { TeamMembers } from 'src/features/onboarding/steps/TeamMembers';
import LoadingButton from 'src/components/LoadingButton';
import { toast } from 'react-toastify';
import LoadingBar from 'src/components/LoadingBar';
import { GetTeamMembersQueryResult } from 'src/services/api';
import { selectOrganization } from 'src/features/organization/organizationSlice';
import { fetchOrganizationUsersAsync } from 'src/features/settings/settingsSlice';

export function SettingsTeamMembers() {
  const { orgId } = useAppSelector(selectOrganization);
  const { users, status, errors } = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const [operation, setOperation] = useState<'Add' | 'Delete'>('Add');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchOrganizationUsersAsync(orgId));
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    if (status === 'succeeded' && dialogOpen && submitted) {
      setSubmitted(false);
      setDialogOpen(false);
    }
  }, [status, dialogOpen, submitted]);

  const toastError = useCallback(() => {
    if (errors) {
      const errorMessage = Object.entries(errors)
        .map(([_, v]) => v)
        .join('\n');
      toast.error(
        errors
          ? errorMessage || 'Please correct your form and try again.'
          : 'An unhandled error occured.',
        { hideProgressBar: true }
      );
    }
  }, [errors]);

  useEffect(() => {
    if (!submitted) return;

    if (status === 'succeeded') {
      switch (operation) {
        case 'Add':
          toast.success('Invitation has been sent.', { hideProgressBar: true });
          break;
        case 'Delete':
          toast.success('User has been removed.', { hideProgressBar: true });
          break;
      }
    } else if (status === 'failed') {
      toastError();
    }
  }, [status, errors, toastError, operation, submitted]);

  const form = useForm<OnboardingModel>({
    defaultValues: { teamMembers: [{ value: '' }] },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const validateForm = (onValid: () => void): Promise<void> => {
    return form.handleSubmit(onValid, () =>
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      })
    )();
  };

  const onAdd = () => {
    form.reset();
    setOperation('Add');
    setDialogOpen(true);
    setSubmitted(false);
  };

  const onDialogSubmit = () => {
    switch (operation) {
      case 'Add':
        validateForm(() => {
          dispatch(
            sendInvitationsAsync({
              emails: form.getValues().teamMembers.map((v) => v.value),
              organizationId: orgId!,
            })
          );
          setSubmitted(true);
        });
        break;

      case 'Delete':
        const user = users.find((u) => u.id === selectedUserId);
        if (!user) {
          onCancel();
          return;
        }

        if (user.isActive) {
          dispatch(
            deleteUserAsync({ userId: selectedUserId!, organizationId: orgId! })
          );
        } else {
          dispatch(
            deleteInvitationAsync({
              invitationId: selectedUserId!,
              organizationId: orgId!,
            })
          );
        }

        setSubmitted(true);
        break;
    }
  };

  const onCancel = () => {
    form.reset();
    setDialogOpen(false);
    setSubmitted(false);
  };

  const onRemove = (userId: string) => {
    setSelectedUserId(userId);
    setOperation('Delete');
    setDialogOpen(true);
    setSubmitted(false);
  };

  const columns: GridColDef<GetTeamMembersQueryResult['teamMembers'][0]>[] = [
    {
      field: 'user',
      headerName: 'User',
      valueGetter: (row) => (
        <>
          <Typography variant='h5' sx={{ padding: 0 }}>
            {row.firstName} {row.lastName}
          </Typography>
          <Typography variant='h6'>{row.email}</Typography>
          {row.roles
            .filter((r) => !!r)
            .map((r) => {
              return (
                <Box
                  key={r}
                  component='span'
                  sx={{
                    textTransform: 'uppercase !important',
                    color: '#fff',
                    fontSize: '10px',
                    lineHeight: '12px',
                    fontWeight: 800,
                    padding: '2px 4px',
                    borderRadius: '4px',
                  }}
                  className='dip_label-awaiting_rematch'
                >
                  {r.toUpperCase()}
                </Box>
              );
            })}
        </>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: (row) =>
        row.isActive ? (
          'Active'
        ) : (
          <>
            <Box
              component='span'
              sx={{
                textTransform: 'uppercase !important',
                color: '#fff',
                fontSize: '10px',
                lineHeight: '12px',
                fontWeight: 800,
                padding: '2px 4px',
                borderRadius: '4px',
              }}
              className='dip_label-rejected'
            >
              Invited
            </Box>
            <br />
            <MuiLink
              onClick={() => {
                dispatch(
                  resendInvitationAsync({
                    invitationId: row.id,
                    organizationId: row.orgId,
                  })
                );
                setSubmitted(true);
              }}
            >
              Resend invitation
            </MuiLink>
          </>
        ),
      labelClassName: (row) => (row.isActive ? 'dip_label-asap' : ''),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      valueGetter: (row) => (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <MuiLink
            disabled={users.length < 2 || row?.roles?.includes('owner')}
            onClick={() => onRemove(row.id)}
            id='team-members-row-button-remove'
          >
            Remove
          </MuiLink>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h3'>Team members</Typography>
        <Box>
          <Button
            id='team-members-button-add'
            variant='contained'
            sx={{ minWidth: '140px' }}
            onClick={onAdd}
          >
            <AddIcon sx={{ fontSize: '18px' }} /> Add new
          </Button>
        </Box>
      </Box>

      {status === 'loading' ? (
        <LoadingBar />
      ) : (
        <DisplayTable columns={columns} rows={users} idColumn='id' />
      )}

      <Dialog
        open={dialogOpen}
        PaperProps={{
          sx: {
            width: '470px',
          },
        }}
      >
        <DialogTitle sx={{ marginTop: '24px' }}>
          {operation === 'Add' && 'Add team members'}
          {operation === 'Delete' && 'Remove team members'}
        </DialogTitle>
        <DialogContent sx={{ overflow: 'visible' }}>
          {operation === 'Add' && (
            <>
              <DialogContentText sx={{ marginBottom: 2 }}>
                You can update user permissions on the team members page after
                sending the email invitation.
              </DialogContentText>
              <FormProvider {...form}>
                <TeamMembers />
              </FormProvider>
            </>
          )}

          {operation === 'Delete' && 'Are you sure you want to delete user?'}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={onCancel}
            id='team-members-new-button-cancel'
          >
            Cancel
          </Button>
          <LoadingButton
            id='team-members-new-button-send'
            loading={status === 'loading'}
            variant='contained'
            onClick={onDialogSubmit}
          >
            {operation === 'Add' && 'Send invitation'}
            {operation === 'Delete' && 'Delete'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
