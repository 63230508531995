/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DispatchParcelsParams } from '../models/DispatchParcelsParams';
import type { GetDispatchingLabelsParams } from '../models/GetDispatchingLabelsParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DispatchingService {

  /**
   * Returns PDF labels for given parcels
   * @param requestBody
   * @returns any PDF labels document
   * @throws ApiError
   */
  public static getLabel(
    requestBody: GetDispatchingLabelsParams,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/dispatching/print-labels',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Changes status of given parcels to shipped
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static ship(
    requestBody: DispatchParcelsParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/dispatching/ship',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

}