import React, { useCallback, useMemo, useRef } from 'react';
import { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

import {
  GetParcelModel,
  Package,
  Parcel,
  ParcelStatus,
  getStatusClassName,
  getStatusName,
  groupGetParcelModel,
} from 'src/types/delivery/Delivery';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';

import {
  selectDispatching,
  printLabelsAsync,
  clearState,
  markReadyForCourierAsync,
  removePackagesAsync,
} from './draftOrdersSlice';
import useStyles from '../Dashboard.styles';
import DisplayTable, {
  formatAddress,
  formatDateString,
  formatReferenceNumbers,
  getSelectedItems,
  GridColDef,
  SpacerGridCol,
} from 'src/components/table';
import { AddIcon, UploadIcon } from 'src/features/delivery/components/icons';
import startDeliveryImg from 'src/assets/start-delivery.png';
import { MuiLink, MuiNavigationLink } from 'src/components/MuiLink';
import { MarkPackageReadyDialog } from 'src/features/delivery/components/MarkPackageReadyDialog';
import { PaymentDialog } from 'src/features/delivery/payment/PaymentDialog';
import LoadingButton from 'src/components/LoadingButton';
import { Upload } from 'src/features/dashboard/upload-csv/Upload';
import { selectPayment } from 'src/features/delivery/payment/paymentSlice';
import { ParcelDeliveryType } from 'src/types/delivery/Delivery';
import { useParcelSubscriptionForOrg } from 'src/components/hooks/useSubscription';
import { ParcelsService } from 'src/services/api';
import { getLocalTimeFromTimestamp } from 'src/components/table/valueFormatters';
import moment from 'moment';

export default function DraftOrders() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const cursors = useRef<Map<number, string>>(new Map());
  type SelectedItem = {
    item: Parcel;
    groupIndex?: number;
    groupItem?: Package;
  };

  const { error, printingStatus, dispatchingStatus, removingStatus } =
    useAppSelector(selectDispatching);
  const { paymentStatus } = useAppSelector(selectPayment);
  const [draftOrders, setDraftOrders] = useState<Parcel[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [markPackageReadyIds, setMarkPackageReadyIds] = useState<string[]>([]);
  const [removePackagedIds, setRemovePackagedIds] = useState<string[]>([]);
  const [parcelsMarkedForPayment, setParcelsMarkedForPayment] = useState<
    string[] | null
  >(null);
  const [displayUploadModal, setDisplayUploadModal] = useState<boolean>(false);
  const [deselectTrigger, setDeselectTrigger] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const {
    value: deliveries,
    count,
    isLoading,
    lastUpdateTimestamp,
  } = useParcelSubscriptionForOrg<GetParcelModel>(
    `projections/web/webParcelProjections`,
    itemsPerPage,
    cursors.current.get(page),
    [
      {
        field: 'status',
        operator: 'in',
        value: [ParcelStatus.NeedsLabel, ParcelStatus.FixRequired],
      },
    ]
  );

  const parcels = useMemo(() => {
    return deliveries;
  }, [deliveries]);

  const onRowsPerPageChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage((page) => {
      cursors.current.set(
        (page = page + 1),
        deliveries[deliveries.length - 1].creationDate
      );
      return 0;
    });
  };

  const onPageChanged = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage((page) => {
      cursors.current.set(
        (page = page + 1),
        deliveries[deliveries.length - 1].creationDate
      );
      return newPage;
    });
  };

  const recalculateParcelsDeliveryTime = async () => {
    try {
      const response = await ParcelsService.recalculateDeliveryTime();
    } catch (e: any) {
      console.error('Error', e.message);
      toast.error(`Could not recalculate parcels estimated delivery time`, {
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (!parcels) return;

    setDraftOrders(
      groupGetParcelModel<Parcel>(
        Object.values<GetParcelModel>(deliveries),
        'orderId',
        'id',
        'parcels',
        (order: GetParcelModel) => ({
          weight: order.size?.weightInKg,
          dimension_x: order.size?.dimensions?.x,
          dimension_y: order.size?.dimensions?.y,
          dimension_z: order.size?.dimensions?.z,
          requiresAttention: order.requiresAttention,
          paymentMethod: order.paymentMethod,
          paymentStatus: order.paymentStatus,
        })
      )
    );
  }, [lastUpdateTimestamp]);

  useEffect(() => {
    recalculateParcelsDeliveryTime();
  }, []);

  useEffect(() => {
    if (draftOrders)
      setSelectedItems(
        getSelectedItems(
          Object.values<Parcel>(draftOrders),
          selection,
          'parcels',
          'orderId'
        )
      );
  }, [selection, draftOrders]);

  const canPrintLabel = () => {
    return (
      selectedItems.length > 0 &&
      selectedItems.every((s) => s.item.status === ParcelStatus.NeedsLabel)
    );
  };

  const canMarkAsReady = () => {
    return (
      selectedItems.length > 0 &&
      selectedItems.every((s) => s.item.status !== ParcelStatus.FixRequired)
    );
  };

  const markParcelsForPayment = (parcelIds: string[]) => {
    setParcelsMarkedForPayment(parcelIds);
  };

  const canRemove = () => {
    return (
      selectedItems.length > 0 &&
      selectedItems.every(
        (s) =>
          s.item.status === ParcelStatus.NeedsLabel ||
          s.item.status === ParcelStatus.FixRequired
      )
    );
  };

  const deselect = () => {
    setDeselectTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    if (error) {
      toast.error(error, {
        hideProgressBar: true,
        progress: undefined,
        position: 'bottom-right',
      });
    }
  }, [error]);

  useEffect(() => {
    if (paymentStatus === 'succeeded') {
      deselect();
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (printingStatus === 'succeeded') {
      toast.success('Successfully printed labels for selected parcels.', {
        hideProgressBar: true,
        progress: undefined,
      });
      deselect();
    }
  }, [printingStatus]);

  useEffect(() => {
    if (dispatchingStatus === 'succeeded') {
      toast.success('Successfully dispatched selected parcels.', {
        hideProgressBar: true,
        progress: undefined,
      });
      deselect();
    }
  }, [dispatchingStatus]);

  useEffect(() => {
    if (removingStatus === 'succeeded') {
      toast.success('Successfully removed selected parcels.', {
        hideProgressBar: true,
        progress: undefined,
      });
      deselect();
    }
  }, [removingStatus]);

  useEffect(
    () => () => {
      dispatch(clearState());
    },
    [dispatch]
  );

  const onPrintLabelsClick = () => {
    dispatch(
      printLabelsAsync(
        selectedItems
          .map((s) => s.groupItem?.id || s.item.id)
          .filter((value, index, self) => self.indexOf(value) === index)
          .filter((v) => !!v)
      )
    );
  };

  const onMarkPackageReadyConfirmed = () => {
    dispatch(markReadyForCourierAsync(markPackageReadyIds));
    setMarkPackageReadyIds([]);
  };

  const onMarkAsReadySelected = () => {
    let payByCardParcels: SelectedItem[] = [];
    let payByContractParcels: SelectedItem[] = [];

    const uniqueParcels = Array.from(
      new Map(
        selectedItems.map((v) => [v.groupItem?.id || v.item.id, v])
      ).values()
    );

    uniqueParcels.forEach((parcel) => {
      if (parcel.item.paymentMethod === 'Card') {
        payByCardParcels = [...payByCardParcels, parcel];
      } else if (parcel.item.paymentMethod === 'Contract') {
        payByContractParcels = [...payByContractParcels, parcel];
      }
    });

    payByCardParcels.length &&
      markParcelsForPayment(
        payByCardParcels
          .map((s) => s.groupItem?.id || s.item.id)
          .filter((value, index, self) => self.indexOf(value) === index)
          .filter((v) => !!v)
      );

    payByContractParcels.length &&
      setMarkPackageReadyIds(
        payByContractParcels
          .map((s) => s.groupItem?.id || s.item.id)
          .filter((value, index, self) => self.indexOf(value) === index)
          .filter((v) => !!v)
      );
  };

  const onRemoveConfirmed = () => {
    dispatch(removePackagesAsync(removePackagedIds));
    setRemovePackagedIds([]);
  };

  const onRemoveSelected = () => {
    setRemovePackagedIds(
      selectedItems
        .map((s) => s.groupItem?.id || s.item.id)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((v) => !!v)
    );
  };

  const getRowActions = (row: Parcel, groupIndex?: number) => {
    const packageIds = groupIndex
      ? [row.parcels[groupIndex - 1].id!]
      : row.parcels.map((p) => p.id!);
    const isDispatchingDisabled =
      row.status === ParcelStatus.FixRequired ||
      dispatchingStatus === 'loading';

    if (row.paymentMethod === 'Contract' || row.paymentStatus === 'succeeded') {
      return (
        <>
          {
            <>
              <MuiNavigationLink
                navigationTarget={`/deliveries/edit/${row.orderId}`}
                id={`draft-orders-row-edit`}
              >
                Edit
              </MuiNavigationLink>
              <br />
            </>
          }
          <MuiLink
            onClick={() => dispatch(printLabelsAsync(packageIds))}
            disabled={
              row.status === ParcelStatus.FixRequired ||
              printingStatus === 'loading'
            }
            id={`draft-orders-row-print`}
          >
            Print label
          </MuiLink>
          <br />
          <MuiLink
            disabled={isDispatchingDisabled}
            onClick={() => setMarkPackageReadyIds(packageIds)}
            id={`draft-orders-row-mark-ready`}
          >
            Mark as ready for courier
          </MuiLink>
        </>
      );
    }

    return (
      <>
        {
          <>
            <MuiNavigationLink
              navigationTarget={`/deliveries/edit/${row.orderId}`}
              id={`draft-orders-row-edit`}
            >
              Edit
            </MuiNavigationLink>
            <br />
          </>
        }
        <MuiLink
          onClick={() => dispatch(printLabelsAsync(packageIds))}
          disabled={
            row.status === ParcelStatus.FixRequired ||
            printingStatus === 'loading'
          }
          id={`draft-orders-row-print`}
        >
          Print label
        </MuiLink>
        <br />
        <MuiLink
          disabled={isDispatchingDisabled}
          onClick={() => markParcelsForPayment(packageIds)}
          id={`draft-orders-row-pay`}
        >
          Mark as ready for courier
        </MuiLink>
      </>
    );
  };

  const columns: GridColDef<Parcel>[] = [
    { field: 'orderId', headerName: 'Order ID', width: '10%' },
    {
      field: 'id',
      headerName: 'Package ID',
      width: '9%',
      valueGetter: (row: Parcel, groupIndex?: number) => {
        const id = groupIndex ? row.parcels[groupIndex - 1].id! : row.id;
        return (
          <MuiNavigationLink navigationTarget={`/deliveries/${id}`}>
            {id}
          </MuiNavigationLink>
        );
      },
    },
    {
      field: 'trackingId',
      headerName: 'Tracking ID',
      width: '6%',
      valueGetter: (row: Parcel) => {
        return (
          <a target='_blank' href={`/tracking/${row.trackingId}`}>
            {row.trackingId}
          </a>
        );
      },
    },
    {
      field: 'referenceNumber',
      headerName: 'Reference No.',
      width: '8%',
      valueGetter: (row: Parcel) => formatReferenceNumbers(row),
    },
    {
      field: 'pickupAddress',
      headerName: 'Pickup address',
      width: '13%',
      valueGetter: (row: Parcel) =>
        formatAddress(row.parcelLocation?.pickupAddress),
    },
    {
      field: 'dropoffAddress',
      headerName: 'Dropoff address',
      width: '13%',
      valueGetter: (row: Parcel) =>
        formatAddress(row.parcelLocation?.shippingAddress),
    },
    { field: 'receiverData.fullName', headerName: 'Receiver', width: '9%' },
    {
      field: 'creationDate',
      headerName: 'Creation time',
      width: '8%',
      valueGetter: (row: Parcel) => formatDateString(row.creationDate),
    },
    {
      field: 'deliveryType',
      headerName: 'Delivery Type',
      width: '7%',
      valueGetter: (row: any) => {
        const deliveryType =
          row.deliveryType === ParcelDeliveryType.Sameday
            ? 'Same-Day'
            : 'Next-Day';
        const cutoffTime = getLocalTimeFromTimestamp(
          row?.deadlineTime?.cutoff?.seconds
        );
        const dueTime = getLocalTimeFromTimestamp(
          row?.deadlineTime?.deadline?.seconds
        );
        const cutoffTimeFormatted = moment(cutoffTime).format('h:mm A');
        const dueTimeFormatted =
          moment(dueTime).format('DD/MM/YYYY h:mm A') || 'No ETA';

        const tooltipContent = (
          <span>
            Cutoff: {cutoffTimeFormatted} <br /> Delivery before:{' '}
            {dueTimeFormatted}
          </span>
        );
        const tooltip = (
          <Tooltip title={tooltipContent}>
            <span>
              {deliveryType}
              <InfoIcon className={classes.tooltipIcon} />
            </span>
          </Tooltip>
        );
        return tooltip;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: '8%',
      labelClassName: (row) =>
        getStatusClassName(row.status, row.paymentStatus, row.paymentMethod),
      valueGetter: (row: Parcel) =>
        getStatusName(row.status, row.paymentStatus, row.paymentMethod),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: '10%',
      valueGetter: getRowActions,
    },
  ];

  const groupByColumns: GridColDef<Parcel>[] = [
    {
      field: 'id',
      headerName: 'Order ID',
      valueGetter: (row: Parcel) => (
        <>
          {row.orderId}
          <br />({row.parcels.length} Parcels)
        </>
      ),
    },
    new SpacerGridCol(8),
    {
      field: 'status',
      headerName: 'Status',
      labelClassName: (row) =>
        getStatusClassName(row.status, row.paymentStatus, row.paymentMethod),
      valueGetter: (row: Parcel) =>
        getStatusName(row.status, row.paymentStatus, row.paymentMethod),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: '10%',
      valueGetter: getRowActions,
    },
  ];

  return (
    <>
      {/* <Box mt={2}>
        <TextField placeholder="Search order" disabled />
      </Box> */}
      {draftOrders.length > 0 && (
        <Grid container>
          <Grid item sm={10}>
            <Box className={classes.buttonGroup}>
              <LoadingButton
                loading={printingStatus === 'loading'}
                variant='contained'
                onClick={() => canPrintLabel() && onPrintLabelsClick()}
                disabled={!canPrintLabel() || printingStatus === 'loading'}
                id='draft-orders-button-print'
              >
                Print label
              </LoadingButton>
              <LoadingButton
                loading={dispatchingStatus === 'loading'}
                variant='contained'
                onClick={() => canMarkAsReady() && onMarkAsReadySelected()}
                disabled={!canMarkAsReady() || dispatchingStatus === 'loading'}
                id='draft-orders-button-mark-ready'
              >
                Mark as ready
              </LoadingButton>
              <LoadingButton
                loading={removingStatus === 'loading'}
                variant='contained'
                onClick={() => canRemove() && onRemoveSelected()}
                disabled={!canRemove() || removingStatus === 'loading'}
                id='draft-orders-button-remove'
              >
                Remove
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item sm={2}>
            <Box sx={{ textAlign: 'right' }}>
              <Button
                id='draft-order-button-upload-csv'
                variant='outlined'
                onClick={() => setDisplayUploadModal(true)}
                sx={{
                  marginTop: '16px',
                  color: '#000',
                  borderColor: '#000',
                  borderRadius: 8,
                }}
              >
                Upload a CSV
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      {isLoading ? (
        <>
          <Typography
            variant='body1'
            className={classes.circularProgressBox}
            component={'div'}
          >
            <CircularProgress />
          </Typography>
        </>
      ) : (
        <>
          {draftOrders.length > 0 ? (
            <Box>
              <DisplayTable
                idColumn={'orderId'}
                rows={draftOrders}
                columns={columns}
                groupBy={'parcels'}
                groupByColumns={groupByColumns}
                onSelectionChange={setSelection}
                deselectTrigger={deselectTrigger}
                itemsPerPage={itemsPerPage}
                totalItems={count}
                currentPage={page}
                handleChangePage={onPageChanged}
                rowsPerPage={itemsPerPage}
                handleChangeRowsPerPage={onRowsPerPageChanged}
              />
            </Box>
          ) : (
            <Box
              m={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img src={startDeliveryImg} alt='Start delivery' />
              <Typography variant='h2'>Start delivering today</Typography>
              <Typography
                variant='subtitle2'
                color='#808080'
                sx={{ marginBottom: 3 }}
              >
                Create your first order and it will be displayed here.
              </Typography>
              <Button
                component={RouterLink}
                to='/deliveries/new'
                sx={{
                  padding: '12px 64px',
                  display: 'flex',
                  justifyContent: 'center',
                  minWidth: '238px',
                }}
                variant='contained'
                color='primary'
              >
                <AddIcon height='18' width='18' /> New order
              </Button>
              <Button
                onClick={() => setDisplayUploadModal(true)}
                sx={{
                  marginTop: '12px',
                  padding: '12px 64px',
                  display: 'flex',
                  justifyContent: 'center',
                  minWidth: '238px',
                }}
                variant='contained'
                color='primary'
              >
                <UploadIcon
                  height='18'
                  width='18'
                  id='draft-order-empty-button-upload-csv'
                />
                Upload a CSV
              </Button>
            </Box>
          )}
        </>
      )}
      <MarkPackageReadyDialog
        isOpen={markPackageReadyIds.length > 0}
        onClose={() => setMarkPackageReadyIds([])}
        onMarkPackageReadyConfirmed={onMarkPackageReadyConfirmed}
      />
      <Dialog
        open={removePackagedIds.length > 0}
        onClose={() => setRemovePackagedIds([])}
        PaperProps={{
          sx: {
            maxWidth: '470px',
          },
        }}
      >
        <DialogTitle>
          Remove packages
          <IconButton
            aria-label='close'
            onClick={() => setRemovePackagedIds([])}
            className={classes.dialogExitIcon}
            sx={{ position: 'absolute' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete packages?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant='outlined'
            color='inherit'
            onClick={() => setRemovePackagedIds([])}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onRemoveConfirmed}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <PaymentDialog
        parcelsIds={parcelsMarkedForPayment}
        onClose={() => setParcelsMarkedForPayment(null)}
      />
      <Upload
        isOpen={displayUploadModal}
        onClose={() => setDisplayUploadModal(false)}
      />
    </>
  );
}
