import React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Typography,
  Drawer,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import FormSection from 'src/components/form/FormSection';
import { FormValue } from 'src/components/form/FormValue';
import { useEffectAsync } from 'src/components/hooks/useEffectAsync';
import LoadingButton from 'src/components/LoadingButton';
import LoadingScreen from 'src/components/LoadingScreen';
import {
  formatAddress,
  formatDateString,
  formatReferenceNumbers,
} from 'src/components/table';
import { getValue } from 'src/services/databaseService';
import {
  GetParcelModel,
  getStatusClassName,
  getStatusName,
  ParcelStatus,
} from 'src/types/delivery/Delivery';
import {
  markReadyForCourierAsync,
  printLabelsAsync,
  selectDispatching,
  clearState,
} from 'src/features/dashboard/draft-orders/draftOrdersSlice';
import { MarkPackageReadyDialog } from 'src/features/delivery/components/MarkPackageReadyDialog';
import { GetVolume } from 'src/features/delivery/components/packageUtils';
import { ArrowLeftIcon } from 'src/features/delivery/components/icons';
import { ParcelInformation } from 'src/features/delivery/components/ParcelInformation';
import { PodContent } from 'src/features/delivery/components/PodContent';
import CloseIcon from '@mui/icons-material/Close';
import {
  GetPodByTrackingIdQueryResult,
  TrackingService,
} from 'src/services/api';
import { PaymentDialog } from 'src/features/delivery/payment/PaymentDialog';
import { ParcelDeliveryType } from 'src/types/delivery/Delivery';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PodFile } from './components/PodFile';
import dipTheme from '../../theme/dip.theme';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 15,
  },
  header: {
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(3),
    width: '100%',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #E5E5E5',
    '& h3': {
      padding: 0,
    },
  },
  inlineInformation: {
    display: 'flex',
    alignItems: 'center',
  },
  mainSection: {
    paddingBottom: theme.spacing(4),
  },
  bottomBar: {
    position: 'fixed',
    display: 'flex',
    left: 0,
    bottom: 0,
    background: '#ffffff',
    padding: '16px',
    boxShadow: '0px -4px 16px 0px #0000000A',
    width: '100%',
    marginLeft: '240px',
    paddingRight: '270px',
    justifyContent: 'flex-start',
    gap: '8px',
    '& > button': {
      minWidth: '120px',
    },
  },
  drawer: {
    '& .MuiPaper-root': {
      padding: '2rem 3rem',
      width: '425px',
      textAlign: 'center',
    },
  },
  drawerClose: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      right: '0.5rem',
      top: '0.75rem',
    },
    '& svg': {
      fill: 'black',
    },
  },
}));

export interface DeliveryDetailsProps {}

export const DeliveryDetails = (props: DeliveryDetailsProps) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { id } = useParams() as any;
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState<GetParcelModel>({} as GetParcelModel);
  const { error, printingStatus, dispatchingStatus } =
    useAppSelector(selectDispatching);
  const [markPackageReadyIds, setMarkPackageReadyIds] = useState<string[]>([]);
  const [dispatched, setDispatched] = useState(false);
  const [podData, setPodData] = useState<GetPodByTrackingIdQueryResult>();
  const [isPodDrawerOpened, setPodDrawerOpened] = useState(false);
  const [parcelsMarkedForPayment, setParcelsMarkedForPayment] = useState<
    string[] | null
  >(null);

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const onMarkPackageReadyConfirmed = () => {
    dispatch(markReadyForCourierAsync(markPackageReadyIds));
    setMarkPackageReadyIds([]);
    setDispatched(true);
  };

  const markParcelsForPayment = (parcelIds: string[]) => {
    setParcelsMarkedForPayment(parcelIds);
  };

  useEffectAsync(async () => {
    const value = await getValue<GetParcelModel>({
      path: `projections/web/webParcelProjections/${id}`,
    });
    setValue(value);
    setIsLoading(false);

    if (value?.status === ParcelStatus.Delivered && value.trackingId) {
      try {
        const podObject = await TrackingService.getProofOfDeliveryByTrackingId(
          value.trackingId
        );
        podObject && setPodData(podObject);
      } catch (e: any) {
        console.error('Error', e.message);
        toast.error(
          `Could not get proof of delivery: ${e.message.toLowerCase()}`,
          { hideProgressBar: true }
        );
      }
    }
  }, [setIsLoading, setValue]);

  useEffect(() => {
    if (printingStatus === 'succeeded') {
      toast.success('Successfully printed label for package.', {
        hideProgressBar: true,
        progress: undefined,
        position: 'bottom-right',
      });
    }
  }, [printingStatus]);

  useEffect(() => {
    if (dispatchingStatus === 'succeeded' && dispatched) {
      toast.success('Successfully dispatched package.', {
        hideProgressBar: true,
        progress: undefined,
        position: 'bottom-right',
      });
      history.push('/deliveries');
    }
  }, [dispatchingStatus, history, dispatched]);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        hideProgressBar: true,
        progress: undefined,
        position: 'bottom-right',
      });
    }
  }, [error]);

  if (isLoading || (dispatched && dispatchingStatus === 'loading'))
    return <LoadingScreen {...props} />;

  const isTrackingLinkVisible = () =>
    [ParcelStatus.DriverAssigned, ParcelStatus.OutForDelivery].includes(
      value.status
    );

  const isEditDisabled = () => {
    return (
      value.status !== ParcelStatus.NeedsLabel &&
      value.status !== ParcelStatus.FixRequired
    );
  };

  const isDispatchDisabled =
    value.status !== ParcelStatus.NeedsLabel &&
    value.status !== ParcelStatus.ReadyToSubmit;

  const getTrackingLinkElement = () => {
    if (isTrackingLinkVisible())
      return (
        <RouterLink to={`/tracking/${value?.trackingId}`} target='_blank'>
          Click to track this parcel here
        </RouterLink>
      );

    if (value?.status === ParcelStatus.Delivered && podData) {
      return (
        <>
          Parcel has been delivered.
          <br />
          <Link
            onClick={() => setPodDrawerOpened(true)}
            sx={{
              margin: '0.5rem 0',
              display: 'inline-block',
              cursor: 'pointer',
              textDecoration: 'none',
            }}
          >
            Click to display Proof of Delivery
          </Link>
          <br />
          <PDFDownloadLink
            document={<PodFile parcel={value} pod={podData} />}
            fileName='Proof of Delivery.pdf'
            style={{ textDecoration: 'none' }}
          >
            {({ error }) => (
              <span
                style={{ color: error ? 'red' : dipTheme.palette.primary.main }}
              >
                {error
                  ? 'There was en error in loading POD report'
                  : 'Click to download POD report'}
              </span>
            )}
          </PDFDownloadLink>
        </>
      );
    }

    return <>Link will appear here after courier confirms.</>;
  };
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.mainSection}>
          <Box className={classes.header}>
            <IconButton
              id='parcel-details-button-back'
              onClick={() => history.goBack()}
              sx={{ position: 'absolute', marginTop: -3, marginLeft: -1 }}
            >
              <ArrowLeftIcon />
            </IconButton>
            <Typography variant='h1'>Package {id}</Typography>
            <strong>
              <Box component='span' sx={{ paddingRight: 1 }}>
                Parcel status:
              </Box>
              <Box
                component='span'
                className={getStatusClassName(
                  value.status,
                  value.paymentStatus,
                  value.paymentMethod
                )}
              >
                {getStatusName(
                  value.status,
                  value.paymentStatus,
                  value.paymentMethod
                )}
              </Box>
            </strong>
          </Box>

          <Grid
            container
            spacing={1}
            sx={{ marginLeft: '-22px', width: 'calc(100% + 22px)' }}
          >
            <Grid item xs={3}>
              <FormValue label='Tracking link'>
                {getTrackingLinkElement()}
              </FormValue>
              <FormValue
                label='Courier'
                value={value.courierData?.fullName || '-'}
              />
            </Grid>
            <Grid item xs={3}>
              <FormValue label='Order ID' value={value.orderId} />
              <FormValue label='Reference No.'>
                {formatReferenceNumbers(value)}
              </FormValue>
            </Grid>
            <Grid item xs={3}>
              <FormValue
                label='Priority'
                value={
                  value.pickupType === 'Asap'
                    ? 'As soon as possible'
                    : value.pickupType
                }
              />
              <FormValue label='Creation time'>
                {formatDateString(value.creationDate)}
              </FormValue>
            </Grid>
            <Grid item xs={3}>
              {value.deliveryEnd && (
                <FormValue label='Delivery time'>
                  {formatDateString(value.deliveryEnd!)}
                </FormValue>
              )}
            </Grid>
          </Grid>

          <Grid container sx={{ marginBottom: 4, marginTop: 4 }}>
            <Grid item sm={6} xs={12}>
              <FormSection
                title='Pick up details'
                fullSize
                sx={{ paddingRight: 2 }}
              >
                <FormValue label='Pick up from'>
                  <>{formatAddress(value.parcelLocation.pickupAddress)}</>
                </FormValue>

                <FormValue
                  label='Comments'
                  value={
                    value.parcelLocation.pickupAddress?.hint || 'No comment'
                  }
                />
              </FormSection>
            </Grid>

            <Grid item sm={6} xs={12}>
              <FormSection
                title='Delivery details'
                fullSize
                sx={{ paddingRight: 2, marginLeft: 0, marginBottom: 0 }}
              >
                <FormValue label='Delivery to'>
                  <>
                    {value.receiverData?.fullName}
                    <br />
                    {formatAddress(value.parcelLocation?.shippingAddress)}
                  </>
                </FormValue>
                <FormValue
                  label='Comments'
                  value={
                    value.parcelLocation.shippingAddress?.hint || 'No comment'
                  }
                />
                <FormValue
                  label='Premise type'
                  value={
                    value.parcelLocation.shippingAddress?.premiseType ===
                    'Private'
                      ? 'Residential'
                      : 'Commercial' || 'No data'
                  }
                />
                <FormValue
                  label='Receiver phone number'
                  value={value.receiverData?.phone}
                />
                <FormValue
                  label='Receiver email'
                  value={value.receiverData?.email}
                />
                <FormValue
                  label='Delivery type'
                  value={
                    value.deliveryType === ParcelDeliveryType.Sameday
                      ? 'Same-Day'
                      : 'Next-Day'
                  }
                />
              </FormSection>
            </Grid>
          </Grid>

          <FormSection
            title='Parcel details'
            fullSize
            sx={{ borderBottom: '1px solid #E5E5E5' }}
          >
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormValue
                  label='Weight'
                  value={`${value.size?.weightInKg} kg`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormValue
                  label='Dimensions'
                  value={`${value.size?.dimensions?.x} x ${value.size?.dimensions?.y} x ${value.size?.dimensions?.z} cm`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormValue
                  label='Volume'
                  value={`${GetVolume(
                    value.size?.dimensions?.x,
                    value.size?.dimensions?.y,
                    value.size?.dimensions?.z
                  )} m3`}
                />
              </Grid>
              <Grid item xs={3}>
                <FormValue label='Information'>
                  <ParcelInformation value={value} />
                </FormValue>
              </Grid>
            </Grid>
          </FormSection>
        </Box>
        <Box className={classes.bottomBar}>
          <Button
            id='parcel-details-button-edit'
            variant='outlined'
            color='inherit'
            onClick={() => history.push(`/deliveries/edit/${value.orderId}`)}
            disabled={isEditDisabled()}
          >
            Edit
          </Button>
          <LoadingButton
            id='parcel-details-button-print'
            variant='outlined'
            color='inherit'
            loading={printingStatus === 'loading'}
            onClick={() => dispatch(printLabelsAsync([value.id]))}
            disabled={
              value.status === ParcelStatus.Delivered ||
              value.status === ParcelStatus.ReturnedToSender ||
              value.status === ParcelStatus.Cancelled ||
              value.status === ParcelStatus.FixRequired ||
              value.status === ParcelStatus.Lost
            }
          >
            Print label
          </LoadingButton>
          <Button
            id='parcel-details-button-mark-ready'
            variant='outlined'
            color='inherit'
            onClick={() =>
              value.paymentMethod === 'Card'
                ? markParcelsForPayment([value.id])
                : setMarkPackageReadyIds([value.id])
            }
            disabled={isDispatchDisabled}
          >
            Mark as ready
          </Button>

          <Button
            variant='outlined'
            color='error'
            disabled
            id='parcel-details-button-report-problem'
          >
            Report a problem
          </Button>
        </Box>
        <MarkPackageReadyDialog
          isOpen={markPackageReadyIds.length > 0}
          onClose={() => setMarkPackageReadyIds([])}
          onMarkPackageReadyConfirmed={onMarkPackageReadyConfirmed}
        />
      </Box>
      {podData && (
        <Drawer
          anchor='right'
          open={isPodDrawerOpened}
          onClose={() => setPodDrawerOpened(false)}
          className={classes.drawer}
        >
          <IconButton
            aria-label='close'
            onClick={() => setPodDrawerOpened(false)}
            className={classes.drawerClose}
          >
            <CloseIcon />
          </IconButton>
          <PodContent parcel={value} pod={podData} />
        </Drawer>
      )}
      <PaymentDialog
        parcelsIds={parcelsMarkedForPayment}
        onClose={() => setParcelsMarkedForPayment(null)}
      />
    </>
  );
};
