import React, { useState } from 'react';
import {
  Grid,
  Modal,
  Tooltip,
  Box,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles, {
  dropzoneStyles,
} from 'src/features/dashboard/upload-csv/Upload.styles';
import Dropzone, { IDropzoneProps } from 'react-dropzone-uploader';
import fileIcon from 'src/assets/icons/file.svg';
import doneIcon from 'src/assets/icons/status-done.svg';
import errorIcon from 'src/assets/icons/status-error.svg';
import loadingIcon from 'src/assets/icons/status-loading.svg';
import tooltipIcon from 'src/assets/icons/tooltip.svg';
import csvTemplateFile from 'src/assets/documents/Deliver In Person - CSV Template.csv';
import { PlaceType } from '../../../components/maps';
import { config } from 'src/config';
import { getAuth } from 'firebase/auth';
import { useAppSelector } from 'src/app/hooks';
import { selectWarehouse } from 'src/features//warehouse/warehouseSlice';

export const Upload = (props: { isOpen: boolean; onClose: () => void }) => {
  const classes = useStyles();
  const { isOpen, onClose } = props;
  const { selectedWarehouseId } = useAppSelector(selectWarehouse);
  const [loadingStatus, setLoadingStatus] = useState<string | null>(null);

  const getUploadParams: IDropzoneProps['getUploadParams'] = async ({
    file,
  }) => {
    const token = await getAuth()?.currentUser?.getIdToken();
    return {
      url: config.backendUrl + `/api/orders/import/${selectedWarehouseId}`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  };

  const handleClose = () => {
    setLoadingStatus(null);
    props.onClose();
  };

  const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (
    { meta },
    status
  ) => {
    setLoadingStatus(status);
  };

  const Preview = ({ meta }: any) => {
    const { name, percent } = meta;
    const previewIcon =
      loadingStatus === 'done'
        ? doneIcon
        : loadingStatus === 'error_upload'
          ? errorIcon
          : loadingIcon;
    return (
      <>
        <div className={classes.dropzonePreview}>
          <img
            className={classes.dropzonePreviewIcon}
            src={previewIcon}
            alt='file'
          />
          <p className={classes.dropzoneFileName}>{name}</p>
        </div>
        <div className={classes.dropzoneProgress}>
          <div
            className={classes.dropzoneProgressInner}
            style={{
              maxWidth: `${Math.round(percent)}%`,
              backgroundColor:
                loadingStatus === 'done'
                  ? '#C1EAD1'
                  : loadingStatus === 'error_upload'
                    ? '#EA9B92'
                    : '#FFDFAF',
            }}
          >
            {loadingStatus === 'done' ? (
              'File was uploaded successfuly'
            ) : loadingStatus === 'error_upload' ? (
              <div>
                Upload failed. File is incorrectly formatted. Please use
                provided{' '}
                <a href={csvTemplateFile} target='_blank' rel='noreferrer'>
                  template file
                </a>
              </div>
            ) : (
              'Upload in progress...'
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className={classes.modalBox}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          className={classes.modalCloseIcon}
          sx={{ position: 'absolute' }}
        >
          <CloseIcon />
        </IconButton>
        <Typography align='center' variant='h2' component='p' p={0}>
          Upload a CSV file
        </Typography>

        <Dropzone
          accept='.csv'
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          PreviewComponent={Preview}
          maxFiles={1}
          multiple={false}
          canCancel={true}
          disabled={(files) =>
            files.some((f) =>
              ['preparing', 'getting_upload_params', 'uploading'].includes(
                f.meta.status
              )
            )
          }
          inputContent={(files, extra) => {
            return (
              <>
                <img
                  className={classes.dropzoneIcon}
                  src={fileIcon}
                  alt='file'
                />
                {extra.reject ? (
                  <p>
                    Only <b>.csv</b> files are allowed.
                  </p>
                ) : (
                  <p>
                    Drag and drop .csv file here or
                    <br />
                    <u>
                      <b>Browse file</b>
                    </u>{' '}
                    from your computer
                  </p>
                )}
              </>
            );
          }}
          styles={dropzoneStyles}
        ></Dropzone>

        {loadingStatus === 'rejected_file_type' && (
          <p className={classes.rejected}>
            File has been rejected. Only <b>.csv</b> files are allowed.
          </p>
        )}

        <Grid container mt={4} sx={{ alignItems: 'center' }}>
          <Grid item xs={6}>
            {(!loadingStatus ||
              loadingStatus === 'rejected_file_type' ||
              loadingStatus === 'error_upload') && (
              <Box className={classes.dropzoneTip}>
                <a href={csvTemplateFile} target='_blank' rel='noreferrer'>
                  Download CSV template
                </a>
                {/*<Tooltip*/}
                {/*arrow*/}
                {/*placement="top"*/}
                {/*title={*/}
                {/*<>*/}
                {/*<Typography color="#fff" fontSize={12}>*/}
                {/*Don’t know how to prepare the file for uploading?*/}
                {/*<br/>*/}
                {/*Check out our tips in <a href="#" style={{color: "#fff", textDecoration: "underline"}}>Help*/}
                {/*section</a>*/}
                {/*</Typography>*/}
                {/*</>*/}
                {/*}*/}
                {/*>*/}
                {/*<img src={tooltipIcon} alt="help"/>*/}
                {/*</Tooltip>*/}
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ textAlign: 'right' }}>
              {loadingStatus === 'done' ? (
                <Button
                  variant='contained'
                  onClick={handleClose}
                  id='upload-csv-button-done'
                >
                  Done
                </Button>
              ) : (
                <Button
                  variant='outlined'
                  color='error'
                  onClick={handleClose}
                  id='upload-csv-button-cancel'
                >
                  Cancel
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
