import GoogleMap, { MapOptions } from 'google-map-react';
import { Avatar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeliveryTrackingData, LatLong, CourierPosition } from './types';
import pinBlueImg from 'src/assets/location-pin-blue.svg';
import pinGreenImg from 'src/assets/location-pin-green.svg';
import dropoffIcon from 'src/assets/dropoff.svg';
import pickupIcon from 'src/assets/pickup.svg';
import React from 'react';
import { useSubscription } from 'src/components/hooks/useSubscription';
import { useParams } from 'react-router';
import { TrackerRouteParams } from './types';
import letterLogotype from 'src/assets/logotypes/dip-monogram-white.png';

const useStyles = makeStyles((theme) => ({
  pin: {
    fontSize: '12px',
    cursor: 'default',
    color: theme.palette.primary.main,
  },
  courierPin: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '3px',
    backgroundColor: '#fff',
    position: 'relative',
    transform: 'translate3d(-50%, -100%, 0)',
    '&.empty': {
      backgroundColor: '#000',
      '& img': {
        width: '60%',
        height: '60%',
      },
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '9px',
      bottom: '-9px',
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderTop: `8px solid ${theme.palette.primary.main}`,
    },
    '& .MuiAvatar-root': {
      width: '100%',
      height: '100%',
    },
    '& .MuiAvatar-img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
  pinContainer: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
    textAlign: 'center',
    paddingTop: '5px',
    transform: 'translate3d(-50%, -100%, 0)',
    width: '2.5rem',
    height: '3.25rem',
    '& img': {
      transform: 'translateY(1px)',
      width: '0.95rem',
      height: '0.95rem',
      objectFit: 'contain',
      objectPosition: 'center',
    },
  },
}));

const Pin = ({
  label,
  icon,
  component: Component,
  style,
  id,
  ...props
}: any) => {
  const classes = useStyles();

  if (Component) {
    return <Component className={classes.pin} style={style} id={id} />;
  }

  return (
    <Tooltip {...props} title={label} aria-label={label} id={`${id}-tooltip`}>
      <div className={classes.pinContainer} id={`${id}-pin`} style={style}>
        <img src={icon} alt={label} id={`${id}-icon`} />
      </div>
    </Tooltip>
  );
};

const CourierPin = ({ label, style, courierPhoto, ...props }: any) => {
  const classes = useStyles();
  let { id } = useParams<TrackerRouteParams>();
  const { value: trackingDatas, isLoading: trackingLoadingData } =
    useSubscription<DeliveryTrackingData>(
      `projections/tracking/trackingParcelProjections`,
      [{ field: 'id', operator: '==', value: id }]
    );
  const trackingData =
    (trackingDatas && trackingDatas.length > 0 && trackingDatas[0]) || null;

  return (
    <Tooltip
      {...props}
      title={label}
      aria-label={label}
      id={`tracker-map-courier-${id}-tooltip`}
    >
      <div
        className={`${classes.courierPin} ${courierPhoto ? '' : 'empty'}`}
        id={`tracker-map-courier-${id}-pin`}
      >
        <Avatar
          alt={trackingData?.courierName.toUpperCase()}
          src={
            courierPhoto
              ? `data:image/jpeg;base64,${courierPhoto}`
              : letterLogotype
          }
          id={`tracker-map-courier-${id}-avatar`}
        />
      </div>
    </Tooltip>
  );
};

export interface TrackerMapProps {
  pickupAddress: LatLong;
  shippingAddress: LatLong;
  courierLocation: CourierPosition;
  courierPhoto?: string;
  onLoad?: (map: any, maps: any) => void;
}

const TrackerMap = ({
  pickupAddress,
  courierLocation,
  courierPhoto,
  shippingAddress,
  onLoad,
}: TrackerMapProps) => {
  return (
    <GoogleMap
      bootstrapURLKeys={{
        key: 'AIzaSyBa8nM57eY8w5mWNK1uSvOpYbyKcKtKbuc',
        id: '30f0f4fb69bb9ad5',
      }}
      options={
        {
          maxZoom: 20,
          zoomControl: true,
          fullscreenControl: false,
          id: '30f0f4fb69bb9ad5',
        } as MapOptions
      }
      center={{
        lat: pickupAddress.latitude,
        lng: pickupAddress.longitude,
      }}
      zoom={14}
      draggable={true}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ map, maps }) => onLoad && onLoad(map, maps)}
    >
      {courierLocation && (
        <CourierPin
          lat={courierLocation.latitude}
          lng={courierLocation.longitude}
          label='Courier'
          id='tracker-map-courier'
          courierPhoto={courierPhoto}
        />
      )}

      {!courierLocation && (
        <Pin
          style={{ backgroundImage: `url(${pinBlueImg})` }}
          icon={pickupIcon}
          lat={pickupAddress.latitude}
          lng={pickupAddress.longitude}
          label='Pickup'
          id='tracker-map-pickup'
        />
      )}

      <Pin
        style={{ backgroundImage: `url(${pinGreenImg})` }}
        icon={dropoffIcon}
        lat={shippingAddress.latitude}
        lng={shippingAddress.longitude}
        label='Dropoff'
        id='tracker-map-dropoff'
      />
    </GoogleMap>
  );
};

export default TrackerMap;
