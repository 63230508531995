import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import DisplayTable, { formatAddress, GridColDef } from 'src/components/table';
import { Warehouse } from 'src/services/api/models/Warehouse';
import { AddIcon } from 'src/features/delivery/components/icons';
import {
  fetchWarehousesAsync,
  selectWarehouse,
} from 'src/features/warehouse/warehouseSlice';
import useStyles from 'src/features/dashboard/Dashboard.styles';
import { WarehouseWriteForm } from 'src/features/warehouse/WarehouseWriteForm';
import { getPlaceTypeFromLatLng, PlaceType } from 'src/components/maps';
import { toast } from 'react-toastify';
import {
  clearState,
  createWarehouseAsync,
  deleteWarehouseAsync,
  selectSettings,
  updateWarehouseAsync,
} from '../settingsSlice';
import { MuiLink } from 'src/components/MuiLink';
import LoadingButton from 'src/components/LoadingButton';
import { CreateWarehouseCommandParams } from 'src/services/api';
import { selectOrganization } from 'src/features/organization/organizationSlice';
import { getLocalTimeFromTimeObject } from 'src/components/table/valueFormatters';

export function SettingsWarehouses() {
  const { orgId } = useAppSelector(selectOrganization);
  const { warehouses } = useAppSelector(selectWarehouse);
  const { status, errorMessage } = useAppSelector(selectSettings);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [operation, setOperation] = useState<'Add' | 'Update' | 'Delete'>(
    'Add'
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | null>(null);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | null>(
    null
  );
  const [submitted, setSubmitted] = useState(false);

  const form = useForm<CreateWarehouseCommandParams>({
    defaultValues: {},
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const { clearErrors } = form;

  const setSelectedPlaceValue = (value: PlaceType | null) => {
    setSelectedPlace(value);
    // @ts-ignore
    clearErrors('address');
  };

  const onAdd = () => {
    form.reset();
    setSelectedWarehouseId(null);
    setSelectedPlace(null);
    setOperation('Add');
    setDialogOpen(true);
  };

  const onEdit = (warehouse: Warehouse) => {
    form.reset();
    setSelectedWarehouseId(warehouse.id);
    setOperation('Update');

    form.setValue('name', warehouse.name);
    form.setValue('organizationId', warehouse.organizationId);
    form.setValue('address', warehouse.address);

    getPlaceTypeFromLatLng(warehouse.address).then(setSelectedPlaceValue);

    setDialogOpen(true);
  };

  const onRemove = (warehouseId: string) => {
    setSelectedWarehouseId(warehouseId);
    setOperation('Delete');
    setDialogOpen(true);
  };

  const resetAndCloseDialog = useCallback(() => {
    setDialogOpen(false);
    form.reset();
    setSelectedWarehouseId(null);
  }, [setDialogOpen, form, setSelectedWarehouseId]);

  const toastError = useCallback(() => {
    toast.error(
      errorMessage === 'validation_error'
        ? 'Please correct your form and try again.'
        : 'An unhandled error occured.',
      { hideProgressBar: true }
    );
  }, [errorMessage]);

  useEffect(() => {
    dispatch(fetchWarehousesAsync());
  }, [submitted]);

  useEffect(() => {
    if (!submitted) return;

    if (status === 'succeeded') {
      toast.success('Operation succeeded', { hideProgressBar: true });
      dispatch(clearState());
      resetAndCloseDialog();
      setSubmitted(false);
    } else if (status === 'failed') {
      toastError();
      dispatch(clearState());
      setSubmitted(false);
    }
  }, [
    status,
    dispatch,
    errorMessage,
    toastError,
    resetAndCloseDialog,
    submitted,
  ]);

  const onSubmit = () => {
    validateForm(() => {
      const formValue = form.getValues();

      if (operation === 'Add') {
        dispatch(
          createWarehouseAsync({ ...formValue, organizationId: orgId! })
        );
      }

      if (operation === 'Update') {
        dispatch(
          updateWarehouseAsync({
            ...formValue,
            id: selectedWarehouseId!,
            organizationId: orgId!,
          })
        );
      }

      if (operation === 'Delete') {
        dispatch(
          deleteWarehouseAsync({
            id: selectedWarehouseId!,
            organizationId: orgId!,
          })
        );
      }

      setSubmitted(true);
    });
  };

  const validateForm = (onValid: () => void): Promise<void> => {
    return form.handleSubmit(onValid, () =>
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      })
    )();
  };

  const columns: GridColDef<any>[] = [
    { field: 'name', headerName: 'Warehouse name' },
    {
      field: 'address',
      headerName: 'Address',
      valueGetter: (row) => formatAddress(row.address),
    },
    {
      field: 'cutOffTime',
      headerName: 'Cut Off Time',
      valueGetter: (row) =>
        getLocalTimeFromTimeObject(row.deliveryDueTime.cutOffTime).format(
          'h:mm A'
        ),
    },
    { field: 'address.hint', headerName: 'Instructions for courier' },
    {
      field: 'actions',
      headerName: 'Actions',
      valueGetter: (row) => (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <MuiLink id='warehouses-row-button-edit' onClick={() => onEdit(row)}>
            Edit
          </MuiLink>
          <MuiLink
            id='warehouses-row-button-remove'
            disabled={warehouses.length < 2}
            onClick={() => onRemove(row.id)}
          >
            Remove
          </MuiLink>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h3'>List of warehouses</Typography>
        <Box>
          <Button
            id='warehouses-button-add'
            variant='contained'
            sx={{ minWidth: '140px' }}
            onClick={onAdd}
          >
            <AddIcon sx={{ fontSize: '18px' }} /> Add new
          </Button>
        </Box>
      </Box>
      <DisplayTable columns={columns} rows={warehouses} idColumn='id' />
      <Dialog
        open={dialogOpen}
        PaperProps={{
          sx: {
            width: '470px',
          },
        }}
      >
        <DialogTitle sx={{ marginTop: '24px' }}>
          {operation} warehouse
        </DialogTitle>
        <DialogContent sx={{ overflow: 'visible' }}>
          {operation === 'Delete' &&
            'Are you sure you want to delete warehouse?'}
          {operation !== 'Delete' && (
            <FormProvider {...form}>
              <WarehouseWriteForm
                selectedPlace={{
                  value: selectedPlace,
                  set: setSelectedPlaceValue,
                }}
              />
            </FormProvider>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            id='warehouses-add-new-button-cancel'
            variant='outlined'
            color='inherit'
            onClick={resetAndCloseDialog}
          >
            Cancel
          </Button>
          <LoadingButton
            id='warehouses-add-new-button-save'
            loading={status === 'loading'}
            variant='contained'
            disabled={form.formState.isSubmitting}
            onClick={onSubmit}
          >
            {operation === 'Delete' ? 'Delete' : 'Save'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
