/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateWarehouseCommandParams } from '../models/CreateWarehouseCommandParams';
import type { DeleteWarehouseCommandParams } from '../models/DeleteWarehouseCommandParams';
import type { IResponse } from '../models/IResponse';
import type { UpdateWarehouseCommandParams } from '../models/UpdateWarehouseCommandParams';
import type { Warehouse } from '../models/Warehouse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WarehousesService {

  /**
   * Creates a new warehouse for a given organization.
   * @param requestBody
   * @returns Warehouse Ok
   * @throws ApiError
   */
  public static createWarehouse(
    requestBody: CreateWarehouseCommandParams,
  ): CancelablePromise<Warehouse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/warehouses',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Parameters validation error`,
      },
    });
  }

  /**
   * @returns IResponse Ok
   * @throws ApiError
   */
  public static getWarehouses(): CancelablePromise<IResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/warehouses',
    });
  }

  /**
   * Creates a new warehouse for a given organization.
   * @param id ID of the warehouse to be deleted
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static deleteWarehouse(
    id: string,
    requestBody: DeleteWarehouseCommandParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/warehouses/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Creates a new warehouse for a given organization.
   * @param id ID of the warehouse to be deleted
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static updateWarehouse(
    id: string,
    requestBody: UpdateWarehouseCommandParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/warehouses/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Parameters validation error`,
      },
    });
  }

}