/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';

import LoadingButton from 'src/components/LoadingButton';
import { useAppSelector, useAppDispatch } from 'src/app/hooks';
import { loginAsync, selectUser } from './userSlice';
import { LoginRequestModel } from './userAPI';

import useStyles from './styles';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import { DialogTitle } from '@mui/material';
import { FormEmailField } from 'src/components/form/FormEmailField';

function getErrorMessage(code: string): string | undefined {
  if (code === 'auth/invalid-login-credentials') {
    return 'Invalid email address or password';
  } else if (code === 'permission-denied' || code === 'auth/user-disabled') {
    return 'Missing or insufficient permissions';
  }
}

function Login() {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const history = useHistory();
  const form = useForm<any>({
    defaultValues: {},
    reValidateMode: 'onSubmit',
    mode: 'all',
  });
  const { handleSubmit } = form;
  const { status, errorMessage, authData } = useAppSelector(selectUser);

  const onSubmit = (data: LoginRequestModel) => {
    dispatch(loginAsync(data))
      .then(() => {
        history.push('/onboarding');
      })
      .catch((err) => {
        console.error(err);
        history.push('/');
      });
  };

  useEffect(() => {
    if (
      status === 'idle' &&
      authData?.idToken !== undefined &&
      authData.isPermitted
    ) {
      history.push('/onboarding');
    }
  }, [authData]);

  return (
    <div>
      <CssBaseline />
      <Container component='main' maxWidth='xs'>
        <Paper elevation={0} square={true} className={classes.contentBox}>
          <DialogTitle className={classes.dialogTitle}>Sign in</DialogTitle>
          <FormProvider {...form}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormEmailField<any>
                    id='email'
                    TextField={{
                      label: 'E-mail Address',
                      placeholder: 'Enter e-mail address',
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TitledFormTextField<any>
                    id='password'
                    TextField={{
                      type: 'password',
                      label: 'Password',
                      placeholder: 'Enter password',
                      autoComplete: 'password',
                    }}
                    rules={{
                      required: 'Please provide a password.',
                    }}
                  />
                </Grid>
              </Grid>
              {errorMessage !== undefined && (
                <FormHelperText error id='password-invalid-helper-text'>
                  {getErrorMessage(errorMessage)}
                </FormHelperText>
              )}
              <Link
                id='signInForgotPassword'
                component={RouterLink}
                to='/resetPassword'
                variant='body2'
                color='secondary'
                className={classes.link}
                style={{ textAlign: 'left', marginTop: '8px' }}
              >
                Forgot password?
              </Link>
              <Grid
                container
                justifyContent='center'
                className={classes.submitBox}
              >
                <LoadingButton
                  id='signInSubmit'
                  fullWidth
                  loading={status === 'loading'}
                  disabled={status === 'loading'}
                  type='submit'
                  color='primary'
                  variant='contained'
                >
                  Sign in
                </LoadingButton>
              </Grid>
              {/*<Grid className={classes.additionalTextBox}>*/}
              {/*Don't have an account yet?*/}
              {/*<Link*/}
              {/*component={RouterLink}*/}
              {/*to="/register"*/}
              {/*variant="body2"*/}
              {/*color="secondary"*/}
              {/*className={classes.link}*/}
              {/*>*/}
              {/*Create business account*/}
              {/*</Link>*/}
              {/*</Grid>*/}
            </Box>
          </FormProvider>
        </Paper>
      </Container>
    </div>
  );
}

export default Login;
