import { getAuth } from 'firebase/auth';
import { getValue } from 'src/services/databaseService';
import { PremiseType } from 'src/services/api/models/PremiseType';
import { Address } from 'src/services/api';

export interface AppUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isEulaConfirmed: boolean;
  isPhoneConfirmed: boolean;
  phone: string;
  business?: {
    name: string;
    abn: string;
    address: Address;
  };
  signUpDate: string;
}

export interface AppUserReadOnlyData {
  lastName: string;
  firstName: string;
  abn: string;
}

export interface GetDatabaseObjectProps {
  uid: string;
}

export async function getAppUser({
  uid,
}: GetDatabaseObjectProps): Promise<AppUser | null> {
  const value = await getValue<AppUser>({ path: 'users/' + uid });
  return value;
}

export async function getCurrentAppUser(): Promise<AppUser | null> {
  const auth = getAuth();
  if (!auth.currentUser) {
    return null;
  }

  const value = await getValue<AppUser>({
    path: 'users/' + auth.currentUser.uid,
  });
  return value;
}

export async function getCurrentAppUserOrganizationRole(
  orgid: string
): Promise<string | null> {
  const auth = getAuth();
  if (!auth.currentUser) {
    return null;
  }
  const value = await getValue<string>({
    path: 'organizations/' + orgid + '/orgUsers/' + auth.currentUser.uid,
  });
  return value;
}
