import { makeStyles } from '@mui/styles';
import bg from 'src/assets/tiffany-tracker-bg.jpg';

export default makeStyles((theme) => ({
  mapContainter: {
    marginTop: '35px',
    height: '460px',
    position: 'relative',
  },
  statusWrapper: {
    height: '100%',
    padding: '1rem',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  contentContainer: {
    height: '100%',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    padding: '1rem 0',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  contentWrapper: {
    height: '100%',
    maxWidth: '500px',
    padding: '0 1rem 0.25rem',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  paper: {
    position: 'relative',
    zIndex: 3,
    backgroundColor: 'white',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '2rem',
    minHeight: '300px',
    marginTop: '28vh',
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(5),
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '22vh',
      paddingTop: theme.spacing(0),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
  },
  bg: {
    position: 'absolute',
    width: '100%',
    height: '50vh',
    left: 0,
    top: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundImage: `url(${bg})`,
    zIndex: 2,
  },
  detailsContainer: {},
  detailsContentWrapper: {
    borderTopWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    padding: '0 1rem 0.25rem',
    [theme.breakpoints.down('md')]: {
      borderColor: 'transparent',
      marginTop: '1rem !important',
    },
  },
  detailsContentWrapperBorder: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
      borderLeftWidth: '2px',
      borderColor: '#000',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      borderTopWidth: '1px',
    },
  },
  detailsContentTextSecondary: {
    color: '#000',
    fontSize: '13px',
    lineHeight: '13px',
    fontWeight: '800 !important',
    letterSpacing: '1px',
    marginBottom: '0.5rem !important',
  },
  detailsContentTextPrimary: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: 'bold',
    fontSize: '12px',
  },
  detailsContentTextDefault: {
    color: theme.palette.secondary.main,
    fontSize: '16px !important',
    lineHeight: '1.35 !important',
  },
  deliveryTrackingHeader: {
    fontSize: '24px !important',
    fontWeight: 'bold !important',
  },
  mapResetBoundsButtonWrapper: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.2),
    '&>button': {
      boxShadow: 'none',
      borderRadius: '0',
      color: theme.palette.secondary.main,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#CCCCCC',
      backgroundColor: '#fff',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#F4C98C',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '13spx',
        padding: '5px 10px',
        minWidth: 'unset',
      },
    },
  },
  mapCourierInfoAvatar: {
    marginRight: theme.spacing(1),
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  mapCourierInfoTextSecondary: {
    lineHeight: '12px !important',
    color: '#999999',
    fontSize: '12px !important',
  },
  mapCourierInfoTextDefault: {
    lineHeight: '11px !important',
    fontSize: '11px !important',
  },
  mapCourierInfoTextPrimary: {
    color: theme.palette.secondary.main,
    fontFamily: theme.typography.h6.fontFamily,
    fontWeight: 'bold !important',
    fontSize: '18px !important',
  },
  mobileAppInfoImg: {
    maxWidth: '200px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    paddingLeft: '3px',
    paddingTop: '5px',
    '& img': {
      width: '20px',
      height: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  statusText: {
    fontSize: '18px !important',
    fontWeight: 'bold !important',
  },
  trackingIdForm: {
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  trackingIdFormInput: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '360px',
    },
    '& .Mui-focused fieldset': {
      borderColor: '#70D8CC !important',
    },
    '& input': {
      color: '#000',
      backgroundColor: '#F5F4F4',
      '&::placeholder': {
        opacity: '0.75 !important',
        color: '#000 !important',
      },
    },
    '&>div': {
      borderRadius: '0 !important',
    },
  },
  trackingIdFormBtn: {
    boxShadow: 'none !important',
    borderRadius: '0 !important',
    backgroundColor: '#70D8CC !important',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  bottomImage: {
    margin: '0 auto 2rem',
    height: '58',
    width: 'auto',
    maxHeight: 58,
    display: 'block',
    [theme.breakpoints.down('md')]: {
      height: '38',
      maxHeight: 38,
    },
  },
}));
