/* eslint-disable react-hooks/exhaustive-deps */
import { FormProvider, useForm } from 'react-hook-form';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingButton from 'src/components/LoadingButton';
import { useAppSelector, useAppDispatch } from 'src/app/hooks';
import { clearState, resetPasswordAsync, selectUser } from './userSlice';

import useStyles from './styles';
import { DialogTitle } from '@mui/material';
import { useEffect } from 'react';
import { FormEmailField } from 'src/components/form/FormEmailField';

function ResetPassword() {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const form = useForm<any>({
    reValidateMode: 'onSubmit',
    mode: 'all',
  });
  const { handleSubmit } = form;
  const { passwordResetStatus, errorMessage } = useAppSelector(selectUser);

  const onSubmit = ({ email }: { email: string }) => {
    dispatch(resetPasswordAsync({ email }));
  };

  const history = useHistory();

  const toastError = (text: string) => {
    toast.error(text, {
      hideProgressBar: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (passwordResetStatus === 'failed') {
      toastError(errorMessage || 'Error occured.');
    }
    if (passwordResetStatus === 'succeeded') {
      toast.success(
        `An email with reset link has been sent to ${form.getValues().email}`,
        {
          type: 'success',
          hideProgressBar: true,
          progress: undefined,
        }
      );
      history.push('/login');
      dispatch(clearState());
    }
  }, [passwordResetStatus, dispatch, errorMessage, history]);

  return (
    <div>
      <CssBaseline />
      <Container component='main' maxWidth='xs'>
        <Paper elevation={0} square={true} className={classes.contentBox}>
          <DialogTitle className={classes.dialogTitle}>
            Reset password
          </DialogTitle>
          <FormProvider {...form}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmit, () =>
                toastError('Please correct your form and try again.')
              )}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormEmailField<any>
                    id='email'
                    TextField={{
                      label: 'E-mail Address',
                      placeholder: 'Enter e-mail address',
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    id='resetPasswordSubmit'
                    fullWidth
                    loading={passwordResetStatus === 'loading'}
                    disabled={passwordResetStatus === 'loading'}
                    type='submit'
                    color='primary'
                    variant='contained'
                  >
                    Send reset link
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid className={classes.additionalTextBox}>
                <Link
                  id='resetPasswordBack'
                  component={RouterLink}
                  to='/login'
                  variant='body2'
                  color='secondary'
                  className={classes.link}
                >
                  Back to login
                </Link>
              </Grid>
            </Box>
          </FormProvider>
        </Paper>
      </Container>
    </div>
  );
}

export default ResetPassword;
