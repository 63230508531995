import { useEffect } from 'react';
import { Controller, UseFormSetValue, useFormContext } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';

import {
  CreateParcelModel,
  ParcelDeliveryType,
} from 'src/types/delivery/Delivery';
import {
  getCountryRestrictions,
  setAddressData,
} from 'src/components/form/AddressForm';
import { PlaceType } from 'src/components/maps';
import PackageListEditor from 'src/features/delivery/components/PackageListEditor';
import FormSection from 'src/components/form/FormSection';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import { RadioGroup } from '@mui/material';
import { Warehouse } from 'src/services/api/models/Warehouse';
import { formatAddress } from 'src/components/table';
import { ReceiverDetails } from './ReceiverDetails';
import { getLocalTimeFromTimeObject } from 'src/components/table/valueFormatters';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    alignItems: 'flex-start',
    minHeight: '90vh',
    paddingLeft: '12px',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(8),
    marginLeft: 'inherit !important',
    marginRight: 'inherit !important',
  },
  option: {
    cursor: 'default',
  },
  fieldlistItem: {
    marginBottom: theme.spacing(2),
  },
  textFieldName: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14.4px',
    marginBottom: '6px',
    color: '#979797',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  addressTitle: {
    fontSize: '12px !important',
  },
  addressText: {
    paddingBottom: theme.spacing(2),
    fontSize: '16px !important',
  },
}));

export interface DeliveryFormProps {
  errors: Record<keyof CreateParcelModel, any>;
  onSubmit: () => void;
  selectedPlace: { value: PlaceType | null; set: (value: PlaceType) => void };
  selectedWarehouse: Warehouse | undefined;
  optionalEmail?: boolean;
  optionalPhone?: boolean;
  optionalName?: boolean;
}

export default function DeliveryForm(props: DeliveryFormProps) {
  const classes = useStyles();
  const { control, setValue } = useFormContext<CreateParcelModel>();
  const { selectedWarehouse } = props;

  useEffect(() => {
    setInitPickupData(setValue, selectedWarehouse);
  }, [setValue, selectedWarehouse]);

  return (
    <Container className={classes.root}>
      <Box component='form' onSubmit={props.onSubmit}>
        <FormSection title='Consignment details' id='consignmentDetails'>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TitledFormTextField<CreateParcelModel>
                id={'referenceNumber1'}
                TextField={{
                  type: 'text',
                  placeholder: 'Enter reference number',
                  label: 'Reference number (1)',
                  helperText:
                    'The reference number is a unique ID linked to your shipment. It is optional to use. You can enter one or two reference numbers to help you later identify the same consignment.',
                }}
                rules={{
                  maxLength: {
                    value: 32,
                    message:
                      'Reference number must have less than 32 characters.',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TitledFormTextField<CreateParcelModel>
                id={'referenceNumber2'}
                TextField={{
                  type: 'text',
                  placeholder: 'Enter reference number',
                  label: 'Reference number (2)',
                }}
                rules={{
                  maxLength: {
                    value: 32,
                    message:
                      'Reference number must have less than 32 characters.',
                  },
                }}
              />
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title='Pick up details' id='pickupDetails'>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant='h4'>Pick up from</Typography>
              <Typography className={classes.addressTitle}>
                To send a package from a different warehouse location, please
                choose the desired warehouse from the top left drop-down list
              </Typography>

              <Typography variant='h6'></Typography>
              <Grid item xs={12}>
                <Typography
                  color='textSecondary'
                  className={classes.addressTitle}
                >
                  Warehouse/pick up point name
                </Typography>
                <Typography color='textPrimary' className={classes.addressText}>
                  {selectedWarehouse?.name}
                </Typography>

                <Typography
                  color='textSecondary'
                  className={classes.addressTitle}
                >
                  Address
                </Typography>
                <Typography color='textPrimary' className={classes.addressText}>
                  {formatAddress(selectedWarehouse?.address)}
                </Typography>

                <Typography
                  color='textSecondary'
                  className={classes.addressTitle}
                >
                  Comments
                </Typography>
                <Typography color='textPrimary' className={classes.addressText}>
                  {selectedWarehouse?.address?.hint || 'No comment'}
                </Typography>
                {selectedWarehouse?.deliveryDueTime.cutOffTime && (
                  <>
                    <Typography
                      color='textSecondary'
                      className={classes.addressTitle}
                    >
                      Cut off time
                    </Typography>
                    <Typography
                      color='textPrimary'
                      className={classes.addressText}
                    >
                      {getLocalTimeFromTimeObject(
                        selectedWarehouse?.deliveryDueTime.cutOffTime
                      ).format('h:mm A')}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h4'>Pick up time</Typography>
              <Controller
                name='pickupType'
                rules={{
                  required: {
                    value: true,
                    message: 'This field is required.',
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <>
                      <FormControl
                        component='fieldset'
                        error={fieldState.invalid}
                      >
                        <RadioGroup
                          value={field.value}
                          onChange={field.onChange}
                          color='inherit'
                          sx={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <FormControlLabel
                            value='Asap'
                            control={<Radio id='asap' />}
                            label='ASAP'
                          />
                          <FormControlLabel
                            disabled
                            value='Scheduled'
                            control={<Radio id='scheduled' />}
                            label='Scheduled (coming soon)'
                          />
                        </RadioGroup>
                        {fieldState.error && (
                          <FormHelperText>
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </>
                  );
                }}
                defaultValue={'Asap'}
                control={control as any}
              />
            </Grid>
          </Grid>
        </FormSection>

        <ReceiverDetails
          warehouseId={selectedWarehouse?.id}
          errors={props.errors}
          selectedPlace={props.selectedPlace}
          countryRestrictions={getCountryRestrictions(
            selectedWarehouse?.address
          )}
          optionalEmail={props.optionalEmail}
          optionalPhone={props.optionalPhone}
          optionalName={props.optionalName}
        />

        <Grid item xs={12} md={6} mb={5}>
          <Typography variant='h4' mb={0} pb='5px' pt={0}>
            Delivery Type
          </Typography>
          <Controller
            name='deliveryType'
            defaultValue={ParcelDeliveryType.Sameday}
            control={control as any}
            rules={{
              required: {
                value: true,
                message: 'This field is required.',
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <FormControl component='fieldset' error={fieldState.invalid}>
                    <RadioGroup
                      value={field.value}
                      onChange={field.onChange}
                      color='inherit'
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                      }}
                    >
                      <FormControlLabel
                        value={ParcelDeliveryType.Sameday}
                        control={<Radio id={ParcelDeliveryType.Sameday} />}
                        label='Same-Day'
                      />
                      <FormControlLabel
                        value={ParcelDeliveryType.Nextday}
                        control={<Radio id={ParcelDeliveryType.Nextday} />}
                        label='Next-Day'
                      />
                    </RadioGroup>
                    {fieldState.error && (
                      <FormHelperText>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              );
            }}
          />
        </Grid>

        <FormSection title='List of parcels' fullSize>
          <PackageListEditor />
        </FormSection>
      </Box>
    </Container>
  );
}

const setInitPickupData = (
  setValue: UseFormSetValue<CreateParcelModel>,
  selectedWarehouse: Warehouse | undefined
) => {
  if (!selectedWarehouse) {
    return;
  }

  const address = selectedWarehouse?.address;

  setAddressData(setValue, 'parcelLocation.pickupAddress', address);
  setValue('parcelLocation.pickupWarehouseId', selectedWarehouse?.id);
};
