import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import useStyles from './DiorDeliveryTracker.styles';
import {
  DeliveryTrackingData,
  TrackerRouteParams,
  CourierPosition,
} from './types';
import { ParcelDeliveryType, ParcelStatus } from 'src/types/delivery/Delivery';
import LayoutHeader from './DiorLayoutHeader';
import StatusTimeline from './DiorStatusTimeline';
import TrackerMap from './TrackerMap';
import { useSubscription } from 'src/components/hooks/useSubscription';
import FormTextField from 'src/components/form/FormTextField';
import trackerImage from 'src/assets/tracker-img.png';

import { useForm, FormProvider } from 'react-hook-form';
import { DeliveryType, Parcel, TrackingService } from 'src/services/api';
import { toast } from 'react-toastify';
import { formatAddress } from '../../components/table';
import diorLogo from 'src/assets/logotypes/dior-logo.png';
import { getIdentifierFieldNameByParamValue } from './helpers';

export default function DiorDeliveryTracker() {
  const classes = useStyles();
  const history = useHistory();
  const formMethods = useForm();
  const [mapHandler, setMapHandler] = useState<any>({});
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<string>();
  const [courierPosition, setCourierPosition] = useState<CourierPosition>();
  const [courierPositionLoading, setCourierPositionLoading] =
    useState<boolean>(false);
  const [isCourierPositionInitialized, setIsCourierPositionInitialized] =
    useState<boolean>(false);
  const [courierPhoto, setCourierPhoto] = useState<string>();

  let { id } = useParams<TrackerRouteParams>() || '';
  const COURIER_DATA_FETCH_INTERVAL = 10000;

  const [idInputValue, setIdInputValue] = useState<string>(id);

  const { value: trackingDatas, isLoading: trackingLoadingData } =
    useSubscription<DeliveryTrackingData>(
      `projections/tracking/trackingParcelProjections`,
      [
        {
          field: getIdentifierFieldNameByParamValue(id, true),
          operator: '==',
          value: id,
        },
      ]
    );
  const trackingData =
    (id && trackingDatas && trackingDatas.length > 0 && trackingDatas[0]) ||
    null;

  useEffect(() => {
    if (id) {
      try {
        const ref = new URLSearchParams(window.location.search).get('ref');
        if (ref) {
          TrackingService.logTrackingDataRequest(id, ref);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [id]);

  const updateMapBounds = () => {
    const { map, maps } = mapHandler;

    if (map && maps && trackingData && !courierPositionLoading) {
      const points = [
        new maps.LatLng(
          trackingData.shippingAddress.latitude,
          trackingData.shippingAddress.longitude
        ),
      ];

      if (!courierPosition && trackingData.status !== ParcelStatus.Delivered) {
        points.push(
          new maps.LatLng(
            trackingData.pickupAddress.latitude,
            trackingData.pickupAddress.longitude
          )
        );
      }

      if (courierPosition && trackingData.status !== ParcelStatus.Delivered) {
        points.push(
          new maps.LatLng(courierPosition.latitude, courierPosition.longitude)
        );
      }

      let bounds = new maps.LatLngBounds();
      for (let i = 0; i < points.length; i++) {
        bounds.extend(points[i]);
      }
      map.fitBounds(bounds, { top: 55, bottom: 55, right: 130, left: 130 });
      setLoading(false);
    }
  };

  const fetchCourierPosition = async () => {
    if (trackingData?.id && trackingData?.status !== ParcelStatus.Delivered) {
      setCourierPositionLoading(true);
      try {
        const courierData = await TrackingService.getCourierPosition(
          trackingData?.id
        );
        courierData?.allowTracking && setCourierPosition(courierData.position);
      } catch (e: any) {
        console.error('Error', e.message);
        toast.error(
          `Could not get courier position: ${e.message.toLowerCase()}`,
          { hideProgressBar: true }
        );
      } finally {
        setCourierPositionLoading(false);
        setIsCourierPositionInitialized(true);
      }
    } else if (trackingData?.status === ParcelStatus.Delivered) {
      setIsCourierPositionInitialized(true);
    }
  };

  const fetchCourierPhoto = async () => {
    if (trackingData?.id && trackingData?.status !== ParcelStatus.Delivered) {
      try {
        const courierData = await TrackingService.getCourierPhoto(
          trackingData?.id
        );
        courierData && setCourierPhoto(courierData?.courierPhotoBase64);
      } catch (e: any) {
        console.error('Error', e.message);
        toast.error(`Could not get courier photo: ${e.message.toLowerCase()}`, {
          hideProgressBar: true,
        });
      }
    }
  };

  useEffect(() => {
    if (trackingLoadingData === false && trackingData === null && id) {
      setError('Invalid tracking ID');
      setLoading(false);
    }
  }, [trackingLoadingData]);

  useEffect(() => {
    if (id) setLoading(true);
  }, []);

  useEffect(() => {
    updateMapBounds();
  }, [mapHandler, trackingLoadingData, courierPositionLoading]);

  useEffect(() => {
    fetchCourierPhoto();
  }, [trackingDatas]);

  useEffect(() => {
    fetchCourierPosition();
  }, [trackingDatas]);

  useEffect(() => {
    const myInterval = setInterval(
      fetchCourierPosition,
      COURIER_DATA_FETCH_INTERVAL
    );
    return () => {
      clearInterval(myInterval);
    };
  }, []);

  console.log(trackingData)
  return (
    <>
      <Box component='div' className={classes.bg} />
      <Paper className={classes.paper} elevation={2} square>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='row'
          gap={10}
          maxWidth='900px'
          margin='0 auto'
        >
          <Grid container>
            <Grid
              xs={12}
              md={4}
              item
              sx={(theme) => ({
                [theme.breakpoints.between('xs', 'lg')]: {
                  textAlign: 'center',
                },
              })}
            >
              <Box
                component='img'
                sx={{
                  height: '60px',
                  width: 'auto',
                  marginTop: '50px',
                }}
                alt='Dior'
                src={diorLogo}
              />
            </Grid>
            <Grid xs={12} md={8} item>
              <Box mt={3}>
                <Typography fontSize='1rem' paddingBottom={3}>
                  Track your order in real time via your Tracking ID, sent to
                  you by SMS.
                </Typography>
                <Box paddingBottom={3}>
                  <FormProvider {...formMethods}>
                    <form
                      className={classes.trackingIdForm}
                      onSubmit={formMethods.handleSubmit((data) => {
                        const trackingId = Object.values(data)[0];
                        if (trackingId) {
                          history.push(`/tracking/dior/${trackingId}`);
                          history.go(0);
                        }
                      })}
                    >
                      <FormTextField<TrackerRouteParams>
                        pattern='[a-zA-Z0-9-]'
                        id='tracker-input-field'
                        rules={{
                          required: true,
                        }}
                        onChange={(event) =>
                          setIdInputValue(event.target.value)
                        }
                        TextField={{
                          className: classes.trackingIdFormInput,
                          type: 'text',
                          placeholder: 'Enter Tracking ID',
                          value: idInputValue,
                        }}
                      ></FormTextField>
                      <Button
                        id='tracker-submit-button'
                        className={classes.trackingIdFormBtn}
                        variant='contained'
                        type='submit'
                        disabled={loading}
                      >
                        Track my parcel
                      </Button>
                    </form>
                  </FormProvider>
                </Box>
                {error && (
                  <Box mt={3}>
                    <Alert severity='error' id='tracker-error-message'>
                      {error}
                    </Alert>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {(loading ||
          (courierPositionLoading && !isCourierPositionInitialized)) && (
          <Box display='flex' alignItems='center' flexDirection='column' m={10}>
            <CircularProgress size={64} />
          </Box>
        )}
        {trackingData && isCourierPositionInitialized && (
          <>
            <Box className={classes.mapContainter}>
              <TrackerMap
                pickupAddress={trackingData.pickupAddress}
                courierLocation={courierPosition}
                courierPhoto={courierPhoto}
                shippingAddress={trackingData.shippingAddress}
                onLoad={(map, maps) => setMapHandler({ map, maps })}
              />
              <div className={classes.mapResetBoundsButtonWrapper}>
                <Button
                  type='submit'
                  disabled={loading}
                  onClick={updateMapBounds}
                >
                  Reset view
                </Button>
              </div>
            </Box>

            <Grid
              container
              marginTop={2}
              className={classes.detailsContainer}
              spacing={1.5}
            >
              <Grid item xs={12} md={4}>
                <div className={classes.statusWrapper}>
                  <Box style={{ display: 'inline-block' }}>
                    <Typography
                      className={classes.detailsContentTextSecondary}
                      id='tracker-status-header'
                    >
                      DELIVERY STATUS
                    </Typography>
                    <StatusTimeline
                      statusHistory={trackingData.statusHistory}
                      deadline={trackingData.deadline.seconds}
                      color='#000000'
                      phoneNumber='0488 826 664'
                      isReturn={trackingData.externalId?.toUpperCase().startsWith("DPR")}
                      attempts={trackingData.deliveryAttempts}
                    />
                  </Box>
                </div>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container className={classes.contentContainer}>
                  <Grid item className={classes.contentWrapper} xs={12} sm={6}>
                    <Box style={{ display: 'inline-block' }} width={'100%'}>
                      <Typography
                        className={classes.detailsContentTextSecondary}
                        id='tracker-sender-header'
                      >
                        SEND FROM
                      </Typography>
                      <Typography
                        className={classes.detailsContentTextDefault}
                        id='tracker-sender-name'
                      >
                        {trackingData.senderName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    className={classes.detailsContentWrapper}
                    xs={12}
                    sm={6}
                  >
                    <Typography
                      className={classes.detailsContentTextSecondary}
                      id='tracker-receiver-header'
                    >
                      DELIVER TO
                    </Typography>
                    <Typography
                      className={classes.detailsContentTextDefault}
                      id='tracker-receiver-name'
                    >
                      {trackingData.receiverName}
                    </Typography>
                    <Typography
                      className={classes.detailsContentTextDefault}
                      id='tracker-receiver-address-line1'
                    >
                      {formatAddress(trackingData.shippingAddress)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
      <LayoutHeader />
    </>
  );
}
