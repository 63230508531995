import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Copyright from 'src/components/Copyright';
import logo from 'src/assets/logotypes/dip-primary-white.png';

import useStyles from './Blank.styles';

export default function Blank(props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='absolute'
        color={'transparent'}
        className={classes.appBar}
      >
        <Toolbar>
          <img src={logo} className={classes.companyLogo} alt='DIP Business' />
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {props.children}
        <Copyright />
      </main>
    </div>
  );
}
