import { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import useStyles from './Settings.styles';
import { SettingsAccount } from './pages/SettingsAccount';
import { SettingsCompanyInfo } from './pages/SettingsCompanyInfo';
import { SettingsTeamMembers } from './pages/SettingsTeamMembers';
import { SettingsWarehouses } from './pages/SettingsWarehouses';
import {
  AccountIcon,
  CompanyInformationIcon,
  PrinterSettingsIcon,
  TeamMembersIcon,
  WarehouseIcon,
} from './icons';
import { useHistory, useLocation } from 'react-router-dom';
import { SettingsPrinter } from 'src/features/settings/pages/SettingsPrinter';
import { RoleRestricted } from 'src/utilities/RoleRestricted';

function TabPanel({
  value,
  id,
  children,
}: {
  value: string;
  id: string;
  children: React.ReactChild | React.ReactFragment;
}) {
  return <>{value === id && children}</>;
}

const allowedPages = [
  'account',
  'company-info',
  'warehouses',
  'team-members',
  'printing',
];

export function Settings() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [value, setValue] = useState((location.hash || '').replace('#', ''));

  useEffect(() => {
    const value = (location.hash || '').replace('#', '');
    setValue(value);

    if (!allowedPages.includes(value)) {
      history.push({ hash: 'company-info' });
    }
  }, [location, history]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    history.push({ hash: newValue });
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} className={classes.tabs}>
        <Tab
          label={
            <Box className={classes.tabLabel}>
              <AccountIcon viewBox='0 0 20 20' /> My account
            </Box>
          }
          value={'account'}
        />
        <Tab
          label={
            <Box className={classes.tabLabel}>
              <CompanyInformationIcon viewBox='0 0 20 20' /> Company information
            </Box>
          }
          value={'company-info'}
        />
        <Tab
          label={
            <Box className={classes.tabLabel}>
              <WarehouseIcon viewBox='0 0 20 20' /> Warehouses
            </Box>
          }
          value={'warehouses'}
        />
        {RoleRestricted(
          <Tab
            label={
              <Box className={classes.tabLabel}>
                <TeamMembersIcon viewBox='0 0 20 20' /> Team members
              </Box>
            }
            value={'team-members'}
          />,
          'owner'
        )}
        <Tab
          label={
            <Box className={classes.tabLabel}>
              <PrinterSettingsIcon viewBox='0 0 20 20' /> Printing settings
            </Box>
          }
          value={'printing'}
        />
      </Tabs>
      <Box className={classes.tabContentContainer}>
        <TabPanel value={value} id='account'>
          <SettingsAccount />
        </TabPanel>
        <TabPanel value={value} id='company-info'>
          <SettingsCompanyInfo />
        </TabPanel>
        <TabPanel value={value} id='warehouses'>
          <SettingsWarehouses />
        </TabPanel>
        {RoleRestricted(
          <TabPanel value={value} id='team-members'>
            <SettingsTeamMembers />
          </TabPanel>,
          'owner'
        )}
        <TabPanel value={value} id='printing'>
          <SettingsPrinter />
        </TabPanel>
      </Box>
    </>
  );
}
