import { useEffect } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import { clearState, logoutAsync } from './userSlice';

function Logout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logoutAsync());
    dispatch(clearState());
  }, []);

  return null;
}

export default Logout;
