/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePaymentIntentCommandParams } from '../models/CreatePaymentIntentCommandParams';
import type { CreatePaymentIntentResponse } from '../models/CreatePaymentIntentResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentsService {

  /**
   * Creates a Stripe Payment Intent and payment in Deliver In Person.
   * @param requestBody
   * @returns CreatePaymentIntentResponse Payment intent created successfully.
   * @throws ApiError
   */
  public static getPaymentIntent(
    requestBody: CreatePaymentIntentCommandParams,
  ): CancelablePromise<CreatePaymentIntentResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/payments',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        403: `No permission to parcels`,
        404: `Parcels not found`,
      },
    });
  }

}