import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createAsyncThunk,
  Draft,
  GetThunkAPI,
} from '@reduxjs/toolkit';
import { IStateWithStatus } from 'src/app/hooks';
import { AppDispatch, RootState } from 'src/app/store';

declare module '@reduxjs/toolkit' {
  type GetThunkAPI = {
    dispatch: AppDispatch;
    getState: () => RootState;
  };
}

export namespace HttpService {
  export interface HttpsResponse<T> {
    status: 'validation_error' | 'success' | 'unhandler_exception';
    errorMessage?: string | null;
    errors?: any | null;
    response?: T;
  }

  export function wrapApiAction<TResponse, TRequest>(
    actionName: string,
    action: (model: TRequest) => Promise<TResponse | undefined>,
    successCallback?: (
      thunkAPI: GetThunkAPI,
      response: TResponse | undefined
    ) => void
  ): AsyncThunk<TResponse | undefined, TRequest, any> {
    return createAsyncThunk<TResponse | undefined, TRequest, any>(
      actionName,
      async (model, thunkAPI) => {
        try {
          const response = await action(model);
          if (successCallback) {
            successCallback(
              {
                dispatch: thunkAPI.dispatch as any,
                getState: thunkAPI.getState as () => RootState,
              },
              response
            );
          }

          return response;
        } catch (e: any) {
          console.error('Error', e);
          return thunkAPI.rejectWithValue(JSON.stringify(e));
        }
      }
    );
  }

  export function registerApiAction<
    TResponse,
    TRequest,
    TState extends IStateWithStatus,
  >(
    builder: ActionReducerMapBuilder<TState>,
    asyncAction: AsyncThunk<TResponse, TRequest, any>,
    successCallback?: (payload: TResponse, state: Draft<TState>) => void,
    failCallback?: (payload: any, state: Draft<TState>) => void
  ) {
    registerAsyncAction(
      builder,
      asyncAction,
      successCallback,
      (payload, state) => {
        payload = JSON.parse(payload);
        state.errorMessage = payload?.errorMessage || payload?.status;
        state.errors = payload?.body?.validationErrors;
        failCallback && failCallback(payload, state);
      }
    );
  }

  export function registerAsyncAction<
    TResponse,
    TRequest,
    TState extends IStateWithStatus,
  >(
    builder: ActionReducerMapBuilder<TState>,
    asyncAction: AsyncThunk<TResponse, TRequest, any>,
    successCallback?: (payload: TResponse, state: Draft<TState>) => void,
    errorProvider?: (Payload: any, state: Draft<TState>, error: any) => void
  ) {
    builder
      .addCase(asyncAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(asyncAction.fulfilled, (state, { payload, meta }) => {
        state.status = 'succeeded';

        if (successCallback) successCallback(payload, state);
      })
      .addCase(asyncAction.rejected, (state, action) => {
        state.status = 'failed';

        try {
          const payload = action.payload as any;
          state.errorMessage = payload?.statusText;
          state.errors = payload?.body?.errors;
        } catch {
          //Ignore
        }

        if (errorProvider) {
          const payload = action.payload as TResponse;
          errorProvider(payload, state, action.error as any);
        }
      });
  }
}
