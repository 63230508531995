import { Path, useFormContext, UseFormSetValue } from 'react-hook-form';
import Grid from '@mui/material/Grid';

import { PlaceAutocompleteField, PlaceType } from 'src/components/maps';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import FormTextField from 'src/components/form/FormTextField';
import { Address } from "src/services/api";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LookupService } from 'src/services/api';

export function getIdWithPrefix(field: string, idPrefix: string) {
  return `${idPrefix}.${field}`
}

export function setAddressData<TModel>(setValue: UseFormSetValue<TModel>, idPrefix: Path<TModel>, address: Address | undefined) {
  // @ts-ignore
  setValue(getIdWithPrefix(`latitude`, idPrefix) as Path<TModel>, address?.latitude || 0);
  // @ts-ignore
  setValue(getIdWithPrefix(`longitude`, idPrefix) as Path<TModel>, address?.longitude || 0);
  // @ts-ignore
  setValue(getIdWithPrefix(`street`, idPrefix) as Path<TModel>, address?.street || '');
  // @ts-ignore
  setValue(getIdWithPrefix(`state`, idPrefix) as Path<TModel>, address?.state || '');
  // @ts-ignore
  setValue(getIdWithPrefix(`city`, idPrefix) as Path<TModel>, address?.city || '');
  // @ts-ignore
  setValue(getIdWithPrefix(`postcode`, idPrefix) as Path<TModel>, address?.postcode || '');
  // @ts-ignore
  setValue(getIdWithPrefix(`line1`, idPrefix) as Path<TModel>, address.line1 || '');
  // @ts-ignore
  setValue(getIdWithPrefix(`line2`, idPrefix) as Path<TModel>, address?.line2 || '');
  // @ts-ignore
  setValue(getIdWithPrefix(`country`, idPrefix) as Path<TModel>, address?.country || null);
  // @ts-ignore
  setValue(getIdWithPrefix(`hint`, idPrefix) as Path<TModel>, address?.hint || '');
  // @ts-ignore
  setValue(getIdWithPrefix(`premiseType`, idPrefix) as Path<TModel>, address?.premiseType || '');
  // @ts-ignore
  setValue(getIdWithPrefix(`isAmbiguous`, idPrefix) as Path<TModel>, address?.isAmbiguous || false);
}

export function setPlaceData<TModel>(setValue: UseFormSetValue<TModel>, idPrefix: Path<TModel>, place: PlaceType | undefined) {
  const formatLine1Value = () =>{
    if(place?.location?.route) {
      if(place?.location?.subpremise && place?.location?.street_number) {
        return  `${place?.location?.subpremise}/${place?.location?.street_number} ${place?.location?.route}`
      } else if(place?.location?.street_number) {
        return `${place?.location?.street_number} ${place?.location?.route}`
      } else {
        return place?.location?.route
      }
    } else {
      return ""
    }
  };
  // @ts-ignore
  setValue(getIdWithPrefix(`state`, idPrefix), place?.location?.administrative_area_level_1 || null);
  // @ts-ignore
  setValue(getIdWithPrefix(`latitude`, idPrefix), place?.location?.lat || null);
  // @ts-ignore
  setValue(getIdWithPrefix(`longitude`, idPrefix), place?.location?.lng || null);
  // @ts-ignore
  setValue(getIdWithPrefix(`postcode`, idPrefix), place?.location?.postal_code || null);
  // @ts-ignore
  setValue(getIdWithPrefix(`city`, idPrefix), place?.location?.locality || null);
  // @ts-ignore
  setValue(getIdWithPrefix(`line1`, idPrefix), formatLine1Value());
  // @ts-ignore
  setValue(getIdWithPrefix(`country`, idPrefix), place?.location?.country || null);
  // @ts-ignore
  setValue(getIdWithPrefix(`isPartial`, idPrefix), !place?.location?.route || !place?.location?.street_number);
}

export function getCountryRestrictions(address: Address | undefined): ("au" | "pl")[] | undefined {
  if (address?.country === "Australia")
    return ["au"];

  if (address?.country === "Poland")
    return ["pl"];

  return undefined;
}

export interface AddressFormProps {
  warehouseId?: string;
  onPlaceSelect: (item: PlaceType) => void;
  value: PlaceType | null;
  idPrefix: string;
  addressSearch?: {
    title?: string;
    placeholder?: string;
    helperText?: string;
  },
  countryRestrictions?: ("au" | "pl")[];
  disableAddressVerify?: boolean;
}

export default function AddressForm<TModel>({ warehouseId, onPlaceSelect, value, idPrefix, addressSearch, countryRestrictions, disableAddressVerify }: AddressFormProps) {
  const getId = (field: string) => getIdWithPrefix(field, idPrefix);

  const { formState, setValue, getValues } = useFormContext();

  const [postalCodeSupported, setPostalCodeSupported] = useState(true);
  const [addressInSameZone, setAddressInSameZone] = useState(true);

  const onPlaceSelectInternal = (place: PlaceType | undefined) => {
    setPlaceData(setValue, idPrefix, place);
    if (place) {
      !disableAddressVerify && checkPostalCode(place?.location?.postal_code);
      !disableAddressVerify && checkTheSameZone(place?.location?.postal_code);
      onPlaceSelect(place);
    }
  };

  const isAmbiguous = getValues(getId("isAmbiguous"));

  const checkPostalCode = async (postcode: any) => {
    try {
      const suburbId = getId("city");
      const suburbValue = getValues(suburbId);
      const value = await LookupService.checkPostalCode(postcode, suburbValue);
      setPostalCodeSupported(value.postalCodeInOperation);

      return value.postalCodeInOperation || "We do not operate in this area.";
    } catch {
      toast.error(
        "Could not verify if we can provide a service in selected address.",
        {hideProgressBar: true}
      );
    }
  }

  const checkTheSameZone = async (postcode: any) => {
    if(warehouseId) {
      try {
        const suburbId = getId("city");
        const suburbValue = getValues(suburbId);
        const value = await LookupService.checkIfAddressesAreInTheSameZone(postcode, suburbValue, warehouseId);
        setAddressInSameZone(value.inTheSameArea);
        return value.inTheSameArea || "We do not send parcels between different area of operation zones.";
      } catch {
        toast.error(
          "Could not verify if we can provide a service in selected address.",
          { hideProgressBar: true }
        );
      }
    }
  };

  const isEmpty = formState.errors?.address?.postcode?.type === 'required' || formState.errors?.parcelLocation?.shippingAddress?.postcode?.type === 'required' || formState.errors?.parcelLocation?.shippingAddress?.postcode?.message === 'Value cannot be empty';

  const otherLocationError = (() => {
    return formState.errors?.parcelLocation?.message || formState.errors?.parcelLocation?.shippingAddress?.message
  })();

  const removeLeadingComma = (str:string) => {
    if (str.startsWith(',')) {
      str = str.slice(1);
    }
    return str;
  };

  useEffect(() => {
    if (value) {
      setPlaceData(setValue, idPrefix, value);
    }
  }, [setValue, idPrefix, value]);

  return (
    <>
      <Grid item xs={12}>
        <PlaceAutocompleteField
          label={addressSearch?.title || "*Address"}
          placeholder={addressSearch?.placeholder || "Enter receiver's address"}
          helperText={
            !postalCodeSupported
              ? "We do not operate in this area."
              : !addressInSameZone
                ? "We do not send parcels between different area of operation zones."
                : isAmbiguous
                  ? "Geocoding this address returned ambiguous results. Original input: " + removeLeadingComma(getValues(getId("originalAddress")))
                  : isEmpty ? "Please provide an address."
                  : otherLocationError || addressSearch?.helperText
          }
          countryRestrictions={countryRestrictions}
          onSelect={(i) => {
            onPlaceSelectInternal(i ?? undefined);
          }}
          initialValue={value}
          hasError={isAmbiguous || otherLocationError || !postalCodeSupported || !addressInSameZone || isEmpty}
        />
        {/** NOTE: this field is only to provide validation rules */}
        <FormTextField<TModel>
          id={getId("postcode") as any}
          rules={{
            required: true,
            validate: disableAddressVerify ? undefined : warehouseId ? checkTheSameZone : checkPostalCode
          }}
          TextField={{
            sx: {
              display: "none",
            }
          }}
        />
        {/** NOTE: this field is only to provide validation rules */}
        <FormTextField<TModel>
          id={getId("country") as any}
          TextField={{
            sx: {
              display: "none",
            }
          }}
        />
        {/** NOTE: this field is only to provide validation rules */ }
        <FormTextField<TModel>
          id={getId("latitude") as any}
          TextField={{
            sx: {
              display: "none",
            }
          }}
        />
        {/** NOTE: this field is only to provide validation rules */ }
        <FormTextField<TModel>
          id={getId("longitude") as any}
          TextField={{
            sx: {
              display: "none",
            }
          }}
        />
        {/** NOTE: this field is only to provide validation rules */ }
        <FormTextField<TModel>
          id={getId("state") as any}
          TextField={{
            sx: {
              display: "none",
            }
          }}
        />
        {/** NOTE: this field is only to provide validation rules */ }
        <FormTextField<TModel>
          id={ getId("isAmbiguous") as any }
          rules={ {
            validate: (value) => !value
          } }
          TextField={{
            sx: {
              display: "none",
            }
          }}
        />
        {/** NOTE: this field is only to provide validation rules */}
        <FormTextField<TModel>
          id={getId("city") as any}
          rules={{
            required: true,
            validate: disableAddressVerify ? undefined : async (city: any) => {
              try {
                const postcodeId = getId("postcode");
                const postcode = getValues(postcodeId);
                const value = await LookupService.checkPostalCode(postcode, city);
                setPostalCodeSupported(value.postalCodeInOperation);

                return value.postalCodeInOperation || "We do not operate in this area.";
              } catch {
                toast.error(
                  "Could not verify if we can provide a service in selected address.",
                  { hideProgressBar: true }
                );
              }
            },
          }}
          TextField={{
            sx: {
              display: "none",
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TitledFormTextField<TModel>
          id={getId("line2") as any}
          TextField={{
            disabled: false,
            type: "text",
            label: "Address line 2",
            autoComplete: "off"
          }}
          rules={{
            maxLength: {
              value: 32,
              message: "Address line 2 must have less than 32 characters.",
            }
          }}
        />
      </Grid>
    </>
  );
}
