import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink as RouterLink } from 'react-router-dom';
import useStyles from './Dashboard.styles';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddIcon from '@mui/icons-material/Add';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

export function MainListItems() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.menuListItemsContainer}>
        <ListItemButton
          component={RouterLink}
          to='/dashboard'
          activeClassName={classes.activeRoute}
        >
          <ListItemIcon className={classes.menuListItemIcon}>
            <ShoppingBasketIcon color='inherit' />
          </ListItemIcon>
          <ListItemText primary='Current deliveries' />
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          to='/deliveries/new'
          activeClassName={classes.activeRoute}
        >
          <ListItemIcon className={classes.menuListItemIcon}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary='New order' />
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          exact
          to='/deliveries'
          activeClassName={classes.activeRoute}
        >
          <ListItemIcon className={classes.menuListItemIcon}>
            <FormatListBulletedIcon />
          </ListItemIcon>
          <ListItemText primary='All deliveries' />
        </ListItemButton>
      </div>
    </div>
  );
}
