import { TextFieldProps } from '@mui/material';

import { Path } from 'react-hook-form';
import TitledFormTextField from './TitledFormTextField';
import React from 'react';

export function FormPhoneField<T>(props: {
  id: Path<T>;
  TextField?: TextFieldProps;
  hasWarning?: boolean;
  required?: boolean;
}) {
  return (
    <TitledFormTextField
      id={props.id}
      className={props.hasWarning ? 'has-warning' : ''}
      rules={{
        required: props.required ? 'This field is required.' : false,
        validate: (value: any) => {
          value = ((value as string) || '')?.replace(/\D/g, '');
          if (value && value.length < 8) {
            return 'Phone number is too short.';
          }
          if (value && value.length > 20) {
            return 'Value must be no more than 20 characters long.';
          }
        },
      }}
      TextField={{
        type: 'text',
        label: 'Phone number',
        placeholder: '+61 000 000 000',
        inputProps: {
          maxLength: 20,
        },
        ...props.TextField,
      }}
      inputGroupSx={{ alignItems: 'flex-start' }}
    ></TitledFormTextField>
  );
}
