import React from 'react';
import { StaticContext } from 'react-router';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

import { useAppSelector } from '../app/hooks';
import { selectUser } from '../features/user/userSlice';
import LoadingScreen from './LoadingScreen';

function PrivateRoute({
  component: Component,
  children,
  render,
  ...rest
}: RouteProps) {
  const user = useAppSelector(selectUser);
  const isAuthenticated =
    user?.authData?.idToken !== undefined && user?.authData?.isPermitted;
  const isLoading = user.status === 'loading';

  if (
    Component === undefined &&
    children === undefined &&
    render === undefined
  ) {
    throw Error('Missing component for PrivateRoute');
  }

  const renderer = (
    props: RouteComponentProps<
      {
        [x: string]: string | undefined;
      },
      StaticContext,
      unknown
    >
  ) => {
    if (render) return render(props);
    if (Component) return <Component {...props} />;
    return children;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <LoadingScreen {...props} />
        ) : isAuthenticated ? (
          renderer(props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
