import { TitledFormTextFieldProps } from './types';
import FormTextField from './FormTextField';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

export const useStyles = makeStyles((theme) => ({
  textField: {
    '& input': {
      padding: theme.spacing(1),
    },
  },
  textFieldName: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14.4px',
    marginBottom: '6px',
    color: '#979797',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
  },
  inputGroup: {
    display: 'flex',
    gap: '.5em',
    alignItems: 'baseline',
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      marginRight: 0,
    },
    '&.has-warning fieldset': {
      borderColor: '#f5a200 !important',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '.5em',
  },
  inputCol: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function TitledFormTextField<T>(
  props: TitledFormTextFieldProps<T>
) {
  const classes = useStyles();
  return (
    <Box className={classes.inputCol}>
      <Box className={classes.textFieldName}>
        {props.rules?.required ? '*' : ' '}
        {props.TextField.label}
      </Box>
      <Box
        className={`${classes.inputGroup} ${props.className}`}
        sx={{ ...props.inputGroupSx }}
      >
        {props.children}
        <FormTextField
          {...props}
          TextField={{
            ...props.TextField,
            label: null,
            className: classes.textField,
          }}
        />
      </Box>
    </Box>
  );
}
