/* eslint-disable import/first */

import initializeApp from './app/firebaseConfig';
initializeApp();

import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';

import AppRoutes from './app/routes';
import theme from './theme/dip.theme';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
