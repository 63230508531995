import React, { useEffect } from 'react';
import clsx from 'clsx';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  fetchAppUserAsync,
  fetchAppUserOrganizationRoleAsync,
  selectAppUser,
} from 'src/features/user/appUserSlice';
import { logoutAsync } from 'src/features/user/userSlice';
import Copyright from 'src/components/Copyright';
import { MainListItems } from './listItems';
import useStyles from './Dashboard.styles';
import logo from 'src/assets/logotypes/dip-primary-black.png';
import { WarehouseSelector } from 'src/features/warehouse/WarehouseSelector';
import {
  fetchUserInfo,
  selectSettings,
} from 'src/features/settings/settingsSlice';
import { Organization } from 'src/features/organization/Organization';
import { RoleRestricted } from 'src/utilities/RoleRestricted';
import { selectOrganization } from 'src/features/organization/organizationSlice';

export function loadScript(
  src: string,
  position: HTMLElement | null,
  id: string
) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export default function Dashboard(props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {
  const classes = useStyles();
  const history = useHistory();
  const [open] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useAppDispatch();
  const organisation = useAppSelector(selectOrganization);

  const { currentUser } = useAppSelector(selectSettings);

  const loaded = React.useRef(false);
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  //NOTE: Temporarilly loading google maps globally
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBa8nM57eY8w5mWNK1uSvOpYbyKcKtKbuc&libraries=places',
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    dispatch(fetchAppUserAsync());
  }, [dispatch]);

  useEffect(() => {
    organisation &&
      organisation.orgId &&
      dispatch(fetchAppUserOrganizationRoleAsync(organisation.orgId));
  }, [organisation]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Organization />
      <AppBar
        position='absolute'
        color={'primary'}
        className={clsx(classes.appBar)}
        sx={{ position: 'fixed' }}
      >
        <Toolbar className={classes.toolbar}>
          <Box sx={{ marginTop: '10px' }}>
            <img
              src={logo}
              className={classes.companyLogo}
              alt='DIP Business'
            />
          </Box>
          <div>
            <Button
              aria-controls='your-account-menu'
              color='inherit'
              aria-haspopup='true'
              onClick={handleClick}
            >
              <AccountCircleIcon style={{ marginRight: '0.75rem' }} />
              Your account
            </Button>
            <Menu
              id='your-account-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  minWidth: '208px !important',
                },
              }}
              MenuListProps={{
                sx: {
                  padding: 0,
                },
              }}
            >
              <MenuItem
                disabled
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  opacity: '1 !important',
                }}
              >
                <Box sx={{ color: '#000000', fontSize: '14px' }}>
                  {currentUser?.firstName} {currentUser?.lastName}
                </Box>
                <Box sx={{ color: '#808080', fontSize: '12px' }}>
                  {currentUser?.email}
                </Box>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push('/settings/#account');
                }}
              >
                My account
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push('/settings/#company-info');
                }}
              >
                Company info
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push('/settings/#warehouses');
                }}
              >
                Warehouses
              </MenuItem>
              {RoleRestricted(
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push('/settings/#team-members');
                  }}
                >
                  Team members
                </MenuItem>,
                'owner'
              )}
              <MenuItem
                onClick={() => {
                  handleClose();
                  history.push('/settings/#printing');
                }}
              >
                Printing settings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  dispatch(logoutAsync());
                }}
              >
                Log out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !open && classes.drawerPaperClose,
            classes.sidebar
          ),
        }}
        open={open}
      >
        <Box sx={{ margin: '24px 12px' }}>
          <WarehouseSelector />
        </Box>
        <List>
          <MainListItems />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Box
          component='div'
          style={{ backgroundColor: 'transparent' }}
          className={classes.container}
        >
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                {organisation?.orgId && props.children}
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Copyright />
      </main>
    </div>
  );
}
