import {
  getFirestore,
  getDocs,
  query,
  where,
  collection,
  collectionGroup,
} from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import { Warehouse } from 'src/services/api/models/Warehouse';
import {
  OrganizationModel,
  OrganizationWarehouseModel,
} from './organization.types';
import {
  OrganizationInvitationModel,
  OrganizationUserModel,
  UserModel,
  UserWithRoles,
} from 'src/features/settings/types';
import { getValue } from '../databaseService';
import { logout } from 'src/features/user/userAPI';

export namespace OrganizationReadonlyService {
  export async function getOrganizationId() {
    const auth = getAuth();

    const roles = await getDocs(
      query(
        collectionGroup(getFirestore(), 'orgUsers'),
        where('userId', '==', auth.currentUser?.uid)
      )
    );

    if (roles.size !== 1)
      throw Error('User has ownership in more than one organization');

    return roles.docs.map((d) => d.get('orgId'))[0] as string;
  }

  export async function getOrganization() {
    const orgId = await getOrganizationId();

    const organizations = await getDocs(
      query(
        collection(getFirestore(), `/organizations`),
        where('id', '==', orgId)
      )
    );

    if (organizations.size !== 1) {
      try {
        logout();
      } catch {
        //Ignore
      }
      throw Error('Cannot find organization.');
    }

    return organizations.docs[0].data() as OrganizationModel;
  }

  export const getWarehousesQuery = (orgId: string) =>
    `/organizations/${orgId}/warehouses`;

  export async function getWarehouses() {
    //TODO: Get from organization state
    const orgId = await getOrganizationId();

    const snapshot = await getDocs(
      query(
        collection(getFirestore(), getWarehousesQuery(orgId)),
        where('organizationId', '==', orgId)
      )
    );

    return snapshot.docs.map((d) => d.data() as Warehouse);
  }

  export async function getCurrentUserInfo(): Promise<UserModel> {
    const auth = getAuth();

    return (await getValue({
      path: `/users/${auth.currentUser?.uid}`,
    })) as UserModel;
  }

  export async function getOrganizationUsers(
    orgId: string
  ): Promise<UserWithRoles[]> {
    const orgUsers = (
      await getDocs(
        query(
          collection(getFirestore(), `/organizations/${orgId}/orgUsers`),
          where('orgId', '==', orgId)
        )
      )
    ).docs.map((d) => d.data() as OrganizationUserModel);

    const invitations = (
      await getDocs(
        query(
          collection(getFirestore(), `/organizations/${orgId}/invitations`),
          where('organizationId', '==', orgId)
        )
      )
    ).docs.map((d) => d.data() as OrganizationInvitationModel);

    const users = (
      await getDocs(
        query(
          collection(getFirestore(), `/users`),
          where(
            'id',
            'in',
            orgUsers.map((o) => o.userId)
          )
        )
      )
    ).docs.map((d) => d.data() as UserModel);

    return [
      ...orgUsers.map((i) => {
        const user = users.find((u) => u.id === i.userId);

        return {
          id: i.userId,
          email: user?.email || '',
          roles: i.roles,
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
          orgId: orgId,
          isActive: true,
        };
      }),
      ...invitations.map((i) => ({
        id: i.id,
        email: i.email,
        roles: [],
        firstName: 'Invited',
        lastName: 'user',
        orgId: orgId,
        isActive: false,
      })),
    ];
  }
}
