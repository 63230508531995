/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculateNewOrderPriceParams } from '../models/CalculateNewOrderPriceParams';
import type { CalculateParcelsPriceParams } from '../models/CalculateParcelsPriceParams';
import type { PriceCalculationResult } from '../models/PriceCalculationResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PricingService {

  /**
   * Calculates pricing for a new order
   * @param requestBody
   * @returns PriceCalculationResult Ok
   * @throws ApiError
   */
  public static getPricingForOrder(
    requestBody: CalculateNewOrderPriceParams,
  ): CancelablePromise<PriceCalculationResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/pricing/newOrder',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Parameters validation error`,
      },
    });
  }

  /**
   * Calculates pricing for a new order
   * @param requestBody
   * @returns PriceCalculationResult Ok
   * @throws ApiError
   */
  public static getParcelsPricing(
    requestBody: CalculateParcelsPriceParams,
  ): CancelablePromise<PriceCalculationResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/pricing/parcels',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Parameters validation error`,
      },
    });
  }

}