import { Grid } from '@mui/material';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPhoneField } from 'src/components/form/FormPhoneField';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import { OnboardingModel, StepDefinition } from '../types';

export const BrandingStepDefinition: StepDefinition = {
  title: 'Check Brand information',
  subtitle:
    'Brand information is your public contact information that will appear on your parcel label. It is to ensure your customers can contact you using the email and phone number as provided below.',
};

export function BrandingInformation() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitledFormTextField<OnboardingModel>
          id={'displayName'}
          TextField={{
            variant: 'outlined',
            type: 'text',
            label: 'Display name',
          }}
          rules={{
            required: 'Please provide display name.',
            minLength: {
              value: 2,
              message: 'Display name must have at least 2 characters.',
            },
            maxLength: {
              value: 32,
              message: 'Display name must have less than 32 characters.',
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <FormEmailField<OnboardingModel>
          id={'brandEmail'}
          TextField={{
            variant: 'outlined',
            label: 'Brand e-mail',
            placeholder: 'Enter brand e-mail address',
          }}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <FormPhoneField<OnboardingModel>
          id='brandPhone'
          TextField={{
            variant: 'outlined',
            label: 'Brand phone',
            placeholder: '+61 000 000 000',
          }}
          required
        />
      </Grid>
    </Grid>
  );
}
