import { makeStyles } from '@mui/styles';

export default makeStyles((theme: any) => ({
  contentBox: {
    padding: '0 32px 32px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '8px',
  },
  submitBox: {
    marginTop: '2rem',
  },
  additionalTextBox: {
    marginTop: '1.5rem',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    fontFamily: 'Lato, sans-serif',
  },
  link: {
    display: 'block',
    fontWeight: 'bold',
    textDecoration: 'underline',
    textAlign: 'center',
  },
  backButton: {
    alignSelf: 'flex-start',
    minWidth: 'auto !important',
    padding: '20px 0 0 !important',
    marginBottom: '-18px !important',
  } as any,
  dialogTitle: {
    margin: '0 !important',
    padding: '24px 0 0 !important',
    textAlign: 'left !important',
    width: '100%',
  } as any,
}));
