import React from 'react';
import useStyles from './CenteredBlock.styles';

export default function CenteredBlock(props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  bgColor?: string;
}) {
  const classes = useStyles(props);
  return <div className={classes.root}>{props.children}</div>;
}
