import { makeStyles } from '@mui/styles';

const drawerWidth = 240;

export default makeStyles((theme) => ({
  root: {},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#FFF',
    height: '2rem',
    boxShadow: '0 0 10px #666',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    backgroundColor: '#000',
  },
  appBar: {
    backgroundColor: '#000',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  searchBox: {
    flexGrow: 1,
    textAlign: 'center',
  },
  companyLogo: {
    height: '34px',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    boxShadow: '0px 0px 6px #c9c9c9',
    border: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    minHeight: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginLeft: '240px',
  },
  activeRoute: {
    '& .MuiTypography-root': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
    background: '#000000 !important',
    borderRight: `4px solid ${theme.palette.primary.main} !important`,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  menuListItemIcon: {
    paddingLeft: '0.5rem',
  },
  menuListItemsContainer: {},
  avatarContainer: {
    display: 'flex',
    padding: '0.75rem',
    width: '100%',
    paddingLeft: '1rem',
  },
  avatar: {
    width: '2.5rem',
    height: '2.5rem',
    marginRight: '1rem',
  },
  avatarLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  sidebar: {
    width: '240px',
    marginTop: '64px',
    color: '#DFDFDF !important',
    background: '#373737 !important',
    fontSize: '14px !important',
    lineHeight: '18px !important',
    fontWeight: '700 !important',
    height: 'calc(100vh - 64px)',
    position: 'fixed !important',
  } as any,
}));
