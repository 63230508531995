import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import logo from 'src/assets/logotypes/dip-secondary-black.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  },
  companyLogo: {
    height: '35px',
    position: 'relative',
    zIndex: 4,
  },
  logoStack: {
    flexDirection: 'row',
    marginTop: theme.spacing(3),
  },
  logoText: {
    display: 'inline-block',
    bottom: '0.65rem',
    position: 'relative',
    fontSize: '1rem',
    right: '1rem'
  },
  companyTitle: {
    position: 'relative',
    zIndex: 4,
    margin: '3.5rem 0 2.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: '3.5rem 0 1.75rem',
    },
  },
  companyTitleSpan: {
    display: 'inline-block',
    marginBottom: '0.85rem',
    fontSize: '1.5rem',
    position: 'relative',
    padding: '1rem 2rem',
    lineHeight: 1,
    color: '#fff',
    backgroundColor: '#000',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      height: 0,
      width: '1rem',
      top: 0,
    },
    '&::before': {
      left: '-1rem',
      borderTop: '3.5rem solid transparent',
      borderRight: '1rem solid #000',
    },
    '&::after': {
      right: '-1rem',
      borderBottom: '3.5rem solid transparent',
      borderLeft: '1rem solid #000',
    },
  },
  container: {
    zIndex: 2,
  },
  flex: {
    display: 'flex',
  },
}));

const DiorLayoutHeader = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} style={{textAlign: 'center', marginBottom: '4rem'}}>
        <span className={classes.logoText}>powered by</span>
        <a href='https://deliverinperson.com'>
          <img src={logo} className={classes.companyLogo} alt='DIP Business' />
        </a>
      </Grid>
    </Grid>
  );
};

export default DiorLayoutHeader;
