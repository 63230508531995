/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VerifyInvitationCommandParams } from '../models/VerifyInvitationCommandParams';
import type { VerifyInvitationResponse } from '../models/VerifyInvitationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvitationsService {

  /**
   * Validates the invitation code and returns organization data.
   * @param requestBody
   * @returns VerifyInvitationResponse Ok
   * @throws ApiError
   */
  public static verifyInvitation(
    requestBody: VerifyInvitationCommandParams,
  ): CancelablePromise<VerifyInvitationResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/invitations/verify',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Parameters validation error`,
      },
    });
  }

}