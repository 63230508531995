import { makeStyles, DefaultTheme } from '@mui/styles';
import { StripeCardElementOptions } from '@stripe/stripe-js';

export default makeStyles<DefaultTheme>((theme) => ({
  circularProgress: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  dialogContent: {
    minWidth: '400px',
    minHeight: '100px',
    margin: '0 !important',
    padding: '10px 20px !important',
  },
  cardControlLabel: {
    width: '100%',
    marginBottom: '10px',
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  },
  cardTitle: {
    marginBottom: '2px !important',
  },
  cardLogo: {
    '& img': {
      height: '25px',
      width: '50px',
      objectFit: 'contain',
      objectPosition: 'left',
      transform: 'translateY(1px)',
    },
  },
  cardDataDetails: {
    flex: 1,
    '& span': {
      textTransform: 'uppercase',
      fontSize: '10px',
    },
  },
  cardRemove: {
    transform: 'translateX(7px)',
  },
  dialogExitIcon: {
    right: '8px !important',
    top: '8px !important',
  },
  dialogActions: {
    marginTop: '25px',
    '& button': {
      width: '50%',
    },
  },
}));

export const styleOptions: StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontWeight: '500',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      ':-webkit-autofill': {
        color: '#000',
      },
      '::placeholder': {
        color: '#000',
      },
    },
    invalid: {
      iconColor: '#ff0000',
      color: '#ff0000',
      '::placeholder': {
        color: '#ff0000',
      },
    },
  },
};
