import { makeStyles, DefaultTheme } from '@mui/styles';

type Props = {
  bgColor?: string;
};

export default makeStyles<DefaultTheme, Props>((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: (props: Props) => props.bgColor || '#FDF4E8',
    minHeight: '100vh',
  },
  companyLogo: {
    height: '34px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  },
  footer: {
    backgroundColor: '#000',
    padding: `${theme.spacing(5)} 0`,
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
      transition: theme.transitions.create('color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
      }),
    },
  },
  footerSocials: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& a': {
      display: 'block',
      margin: `0 ${theme.spacing(1)}`,
    },
    '& svg': {
      height: 'auto',
      width: 30,
      fill: '#F4C98C',
      transition: theme.transitions.create('fill', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
      }),
      '&:hover': {
        fill: '#FDB13F',
      },
    },
  },
  footerText: {
    paddingBottom: theme.spacing(2),
    color: '#fff',
    '& span': {
      color: '#E4836E',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    '& a': {
      color: '#fff',
      '&:hover': {
        color: '#FDB13F',
      },
    },
  },
  footerCopyrights: {
    color: '#939393',
    '& a': {
      color: '#939393',
      '&:hover': {
        color: '#FDB13F',
      },
    },
  },
}));
