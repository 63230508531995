import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  section: {
    marginLeft: '0 !important',
    marginBottom: theme.spacing(6),
    scrollMargin: '70px !important',
  },
  sectionHeader: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #E5E5E5',
    '& h3': {
      padding: 0,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export interface FormSectionProps {
  id?: string;
  title: string;
  headerChildren?: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  fullSize?: boolean;
  sx?: SxProps<any>;
}

export default function FormSection(props: FormSectionProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      className={classes.section}
      id={props.id}
      sx={props.sx}
    >
      <Box className={classes.sectionHeader}>
        <Typography variant='h3'>{props.title}</Typography>
        {props.headerChildren}
      </Box>
      <Grid container>
        <Grid item xs={props.fullSize ? 12 : 6}>
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
}
