import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  loadSession,
  login,
  resetPassword,
  LoginRequestModel,
  LoginResponseModel,
  logout,
} from './userAPI';

export interface UserState {
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  passwordResetStatus: 'idle' | 'loading' | 'failed' | 'succeeded';
  authData?: LoginResponseModel;
  errorMessage?: string;
}

const initialState: UserState = {
  authData: undefined,
  status: 'idle',
  passwordResetStatus: 'idle',
};

export const loginAsync = createAsyncThunk<
  LoginResponseModel | undefined,
  LoginRequestModel
>('user/login', async ({ email, password }, thunkAPI) => {
  try {
    const response = await login({ email, password });
    if (response !== undefined) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.error('Error', e.code, e.message);
    return thunkAPI.rejectWithValue(e.code);
  }
});

export const resetPasswordAsync = createAsyncThunk<
  undefined,
  { email: string }
>('user/resetPassword', async ({ email }, thunkAPI) => {
  try {
    await resetPassword(email);
  } catch (e: any) {
    console.error('Error', e.code, e.message);
    return thunkAPI.rejectWithValue(e.code);
  }
});

export const loadSessionAsync = createAsyncThunk<
  LoginResponseModel | undefined,
  undefined
>('user/loadSession', async (_, thunkAPI) => {
  try {
    const user = await loadSession();
    if (user !== undefined) {
      return user;
    } else {
      return thunkAPI.rejectWithValue(user);
    }
  } catch (e: any) {
    console.error('Error', e.code, e.message);
    return thunkAPI.rejectWithValue(e.code);
  }
});

export const logoutAsync = createAsyncThunk(
  'user/logout',
  async (_, thunkAPI) => {
    try {
      await logout();
      await thunkAPI.dispatch({ type: 'logout' });
    } catch (e: any) {
      console.error('Error', e.code, e.message);
      return thunkAPI.rejectWithValue(e.code);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.passwordResetStatus = 'idle';
      state.errorMessage = undefined;
      state.authData = undefined;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.authData = payload;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload as string;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.passwordResetStatus = 'loading';
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.passwordResetStatus = 'succeeded';
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.passwordResetStatus = 'failed';
        state.errorMessage = action.payload as string;
      })
      .addCase(loadSessionAsync.pending, (state, { payload }) => {
        state.status = 'loading';
        state.authData = payload;
      })
      .addCase(loadSessionAsync.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.authData = payload;
      })
      .addCase(loadSessionAsync.rejected, (state) => {
        state.status = 'failed';
        state.authData = undefined;
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.status = 'idle';
        state.authData = undefined;
      });
  },
});

export const { clearState } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;
