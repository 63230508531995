import { createSvgIcon } from '@mui/material';

export const RemoveIcon = createSvgIcon(
  <g>
    <ellipse cx='10' cy='10' rx='10' ry='10' fill='#5B5B5B' />
    <path
      d='M10 9.19171L13.0245 6.1672C13.2474 5.94427 13.6094 5.94427 13.8328 6.1672C14.0557 6.39063 14.0557 6.75254 13.8328 6.97549L10.8083 10L13.8328 13.0245C14.0557 13.2474 14.0557 13.6094 13.8328 13.8328C13.6094 14.0557 13.2475 14.0557 13.0245 13.8328L10 10.8083L6.97549 13.8328C6.75256 14.0557 6.39064 14.0557 6.1672 13.8328C5.94427 13.6094 5.94427 13.2475 6.1672 13.0245L9.19171 10L6.1672 6.97549C5.94427 6.75256 5.94427 6.39064 6.1672 6.1672C6.39063 5.94427 6.75254 5.94427 6.97549 6.1672L10 9.19171Z'
      fill='#FCFCFC'
    />
  </g>,
  'Remove'
);
