import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from '../../dashboard/Dashboard.styles';

export const MarkPackageReadyDialog = (props: {
  isOpen: boolean;
  onClose: () => void;
  onMarkPackageReadyConfirmed: () => void;
}) => {
  const classes = useStyles();

  const { isOpen, onClose, onMarkPackageReadyConfirmed } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '470px',
        },
      }}
    >
      <DialogTitle>
        {'Is the package ready for the pick-up?'}
        <IconButton
          aria-label='close'
          onClick={onClose}
          className={classes.dialogExitIcon}
          sx={{ position: 'absolute' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm that the parcel has been labelled as required before
          you "Mark as ready". Ensure that the label is firmly stuck on, and
          other necessary instructions are provided.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={onMarkPackageReadyConfirmed}
          autoFocus
        >
          Mark as ready
        </Button>
      </DialogActions>
    </Dialog>
  );
};
