import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  filterLoading: {
    marginTop: '0.5rem',
    minWidth: '13rem',
  },
  root: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '13rem',
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: '#00000010',
    },
    '& .MuiSelect-select': {
      padding: '8px 0 8px 35px',
    },
    '& .MuiSelect-icon': {
      position: 'absolute',
      left: '10px',
      top: '10px',
      width: '1rem',
      height: 'auto',
      marginRight: '0.5rem',
      transform: 'none',
    },
    '& .MuiFormLabel-root': {
      transform: 'translate(14px, -9px) scale(0.75)',
      maxWidth: 'calc(133% - 24px)',
      backgroundColor: '#fff',
      padding: '0 7px',
      left: '-5px',
      '&.MuiFormLabel-filled, &.Mui-focused': {
        padding: '0',
        left: '0',
      },
    },
  },
}));
