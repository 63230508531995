// @ts-nocheck
export function getObjectPaths(object) {
  return object && typeof object === 'object'
    ? Object.entries(object).reduce((r, [k, o]) => {
        let temp = getObjectPaths(o).reduce((r, a, i) => {
          if (!i || r[0].length < a.length) return [a];
          if (r[0].length === a.length) r.push(a);
          return r;
        }, []);

        return temp.length
          ? [...r, ...temp.map((t) => [k].concat(t))]
          : [...r, [k]];
      }, [])
    : [];
}
