export const groupBy = <T> (list: T[], keyGetter: (param: T) => any): {[key: string]: T[]} => {
  const map: {[key: string]: T[]} = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map[key];
    if (!collection) {
      map[key] = [item];
    } else {
      collection.push(item);
    }
  });
  return map;
}

type Join<K, P> = K extends string | number ?
  P extends string | number ?
  `${K}${"" extends P ? "" : "."}${P}`
  : never : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

export type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
  { [K in keyof T]-?: K extends string | number ?
    `${K}` | Join<K, Paths<T[K], Prev[D]>>
    : never
  }[keyof T] : ""

/* @ts-ignore */
export const nameof = <T> (name: Paths<T>): string => name.split('.').pop().toString();

/* @ts-ignore */
export const pathof = <T> (name: Paths<T>): string => name.toString().replace(/\./g, '/');

/**
 * formatNumber
 * @param {number} value
 * @return {number} formatted value
 */
export function formatNumber (value: number | undefined, decimalPlaces: number = 2): number {
  if (!value) return 0;
  const pow = Math.pow(10, decimalPlaces ?? 2);
  return Math.round((value + Number.EPSILON) * pow) / pow;
}

export const download = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};