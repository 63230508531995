/* eslint-disable react-hooks/exhaustive-deps */

// import Box from '@mui/material/Box';
// import Link from '@mui/material/Link';
// import Typography from '@mui/material/Typography';

function Copyright(props: any) {
  return null;

  // return (
  //   <Box sx={ { mt: 4, mb: 1 } }>
  //     <Typography variant="body2" align="center" color="primary" { ...props }>
  //       { 'Copyright © ' }
  //       <Link color="inherit" href="https://dip.com/">
  //         DeliveryInPerson
  //       </Link>{ ' ' }
  //       { new Date().getFullYear() }
  //       { '.' }
  //     </Typography>
  //   </Box>
  // );
}

export default Copyright;
