/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IResponse } from '../models/IResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentMethodService {

  /**
   * @returns IResponse Ok
   * @throws ApiError
   */
  public static getPaymentMethods(): CancelablePromise<IResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/paymentMethods',
    });
  }

  /**
   * @param paymentMethodId
   * @returns IResponse Ok
   * @throws ApiError
   */
  public static getPaymentMethod(
    paymentMethodId: string,
  ): CancelablePromise<IResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/payment/paymentMethods/{paymentMethodId}',
      path: {
        'paymentMethodId': paymentMethodId,
      },
    });
  }

  /**
   * @param paymentMethodId
   * @returns IResponse Ok
   * @throws ApiError
   */
  public static detachPaymentMethod(
    paymentMethodId: string,
  ): CancelablePromise<IResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/payment/paymentMethods/{paymentMethodId}',
      path: {
        'paymentMethodId': paymentMethodId,
      },
    });
  }

}