export const getValueByPath = (
  row: any,
  path: string | number | symbol
): any => {
  if (
    typeof path === 'number' ||
    typeof path === 'symbol' ||
    path.indexOf('.') < 0
  )
    return !!row ? row[path] : null;

  const dotIndex = path.indexOf('.');
  return getValueByPath(
    row[path.substring(0, dotIndex)],
    path.substring(dotIndex + 1)
  );
};
