import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
  RouteProps,
} from 'react-router-dom';

import PrivateRoute from '../components/PrivateRoute';
import DashboardLayout from '../layouts/dashboard/Dashboard';
import BlankLayout from '../layouts/blank/Blank';
import AllDeliveries from '../features/delivery/AllDeliveries';
import Dashboard from '../features/dashboard/Dashboard';
import NewDelivery from '../features/delivery/newDelivery/NewDelivery';
import EditDelivery from '../features/delivery/editDelivery/EditDelivery';
import ResetPassword from 'src/features/user/ResetPassword';
import Login from '../features/user/Login';
import Logout from '../features/user/Logout';
import Register from '../features/user/Register';
import DeliveryTracker from '../features/deliveryTracker/DeliveryTracker';
import TiffanyDeliveryTracker from '../features/deliveryTracker/TiffanyDeliveryTracker';
import BlankNoToolbarLayout from '../layouts/blank/NoToolbarBlank';
import BannerBlank from '../layouts/blank/BannerBlank';
import CenteredBlock from '../layouts/blank/CenteredBlock';
import { Onboarding } from 'src/features/onboarding/Onboarding';
import { Settings } from 'src/features/settings/Settings';
import { AcceptInvitation } from 'src/features/acceptInvitation/AcceptInvitation';
import { DeliveryDetails } from 'src/features/delivery/DeliveryDetails';
import PodWithoutAuth from 'src/features/podWithoutAuth/PodWithoutAuth';
import IdleTimer from 'src/features/user/IdleTimer';
import DiorDeliveryTracker from 'src/features/deliveryTracker/DiorDeliveryTracker';

function DashboardRoute({ component: Component, ...rest }: RouteProps) {
  return Component ? (
    <>
      <PrivateRoute
        {...rest}
        render={(props) => (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        )}
      />
      <IdleTimer />
    </>
  ) : null;
}

function BlankRoute({ component: Component, ...rest }: RouteProps) {
  return Component ? (
    <Route
      {...rest}
      render={(props) => (
        <BlankLayout>
          <Component {...props} />
        </BlankLayout>
      )}
    />
  ) : null;
}

function AppRoutes() {
  return (
    <Router>
      <Switch>
        <BlankRoute exact path='/login' component={Login} />
        <BlankRoute exact path='/register' component={Register} />
        <BlankRoute exact path='/resetPassword' component={ResetPassword} />
        <Route exact path={['/tracking/dior', '/tracking/dior']}>
          <BannerBlank bgColor='#F5F4F4'>
            <DiorDeliveryTracker />
          </BannerBlank>
        </Route>
        <Route exact path={['/tracking', '/tracking/:id']}>
          <BannerBlank>
            <DeliveryTracker />
          </BannerBlank>
        </Route>
        <Route exact path={['/tracking-tco', '/tracking-tco/:id']}>
          <BannerBlank bgColor='#F5F4F4'>
            <TiffanyDeliveryTracker />
          </BannerBlank>
        </Route>
        <Route exact path={['/tracking/dior', '/tracking/dior/:id']}>
          <BannerBlank bgColor='#F5F4F4'>
            <DiorDeliveryTracker />
          </BannerBlank>
        </Route>
        <Route path='/pod/:id'>
          <CenteredBlock>
            <PodWithoutAuth />
          </CenteredBlock>
        </Route>

        <DashboardRoute path='/deliveries/new' component={NewDelivery} />
        <DashboardRoute
          path='/deliveries/edit/:orderId'
          component={EditDelivery}
        />
        <DashboardRoute path='/deliveries/:id' component={DeliveryDetails} />
        <DashboardRoute path='/deliveries' component={AllDeliveries} />
        <DashboardRoute path='/dashboard' component={Dashboard} />
        <DashboardRoute path='/dashboard/dispatching' component={Dashboard} />
        <DashboardRoute exact path='/logout' component={Logout} />

        <Route exact path='/accept-invitation/:invitationKey'>
          <BlankNoToolbarLayout>
            <AcceptInvitation />
          </BlankNoToolbarLayout>
        </Route>

        <DashboardRoute path='/settings' component={Settings} />

        <PrivateRoute exact path='/onboarding'>
          <BlankNoToolbarLayout>
            <Onboarding />
          </BlankNoToolbarLayout>
        </PrivateRoute>

        <Route path='/' render={() => <Redirect to='/onboarding' />} />
      </Switch>
    </Router>
  );
}

export default AppRoutes;
