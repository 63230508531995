import { useEffect } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import Box from '@mui/material/Box';

import { setServerErrors } from 'src/utilities/formHooksHelpers';
import { useAppSelector } from 'src/app/hooks';
import LoadingButton from 'src/components/LoadingButton';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import AddressForm from 'src/components/form/AddressForm';
import { RegistrationFormProps } from 'src/features/user/register/types';
import { RegisterFormModel } from 'src/features/user/model';
import { selectRegistration } from 'src/features/user/registrationSlice';
import useStyles from './styles';
import { FormPhoneField } from 'src/components/form/FormPhoneField';
import FormAbnField from 'src/components/form/FormAbnField';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { AcceptEulaField } from 'src/components/form/AcceptEulaField';

export function BusinessInformationForm({
  onSubmit,
  selectedPlace,
  setSelectedPlace,
}: RegistrationFormProps) {
  const { status, errors } = useAppSelector(selectRegistration);
  const form = useFormContext<RegisterFormModel>();
  const {
    formState: { errors: validationErrors },
    setError,
    handleSubmit,
  } = form;

  const classes = useStyles();

  useEffect(() => {
    if (errors !== undefined) {
      setServerErrors<RegisterFormModel>({
        setError,
        errors,
        include: ['abn', 'organizationName', 'companyEmail', 'phoneNumber'],
      });
    }
  }, [errors, setError]);

  const onHandleSubmit = (event: any) => {
    handleSubmit((values) => onSubmit(1, values))(event);
  };

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        onSubmit={(event: any) => onHandleSubmit(event)}
        style={{ width: '100%' }}
        sx={{ mt: 3 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitledFormTextField<RegisterFormModel>
              id='organizationName'
              TextField={{
                type: 'text',
                label: 'Company name',
                placeholder: 'Enter company name',
              }}
              rules={{
                required: 'Please provide company name.',
                minLength: {
                  value: 2,
                  message: 'Company name must have at least 2 characters.',
                },
                maxLength: {
                  value: 32,
                  message: 'Company name must have less than 32 characters.',
                },
              }}
            />
          </Grid>

          <AddressForm<RegisterFormModel>
            idPrefix='address'
            value={selectedPlace}
            onPlaceSelect={setSelectedPlace}
            addressSearch={{
              title: '*Company address',
              placeholder: 'Search for address',
              helperText: '',
            }}
            disableAddressVerify
          />

          <Grid item xs={12}>
            <FormAbnField<RegisterFormModel>
              id='abn'
              errorData={validationErrors.abn}
              TextField={{
                label: 'Company ABN',
                placeholder: 'Enter company ABN',
                fullWidth: true,
                InputProps: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchSharpIcon fontSize='small' color='action' />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormEmailField<RegisterFormModel>
              id='companyEmail'
              TextField={{
                label: 'Company e-mail',
                placeholder: 'Enter company e-mail address',
              }}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <FormPhoneField<RegisterFormModel>
              id='phoneNumber'
              TextField={{
                label: 'Company phone number',
              }}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <AcceptEulaField<RegisterFormModel> fieldId='acceptEula' />
          </Grid>
        </Grid>

        <Grid container justifyContent='center' className={classes.submitBox}>
          <LoadingButton
            id='registerSubmitButton'
            loading={status === 'loading'}
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            sx={{ paddingTop: '16px', paddingBottom: '16px' }}
          >
            Create an account
          </LoadingButton>
        </Grid>
      </Box>
    </FormProvider>
  );
}

export default BusinessInformationForm;
