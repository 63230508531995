import { TextFieldProps } from '@mui/material';
import { Path, RegisterOptions } from 'react-hook-form';

import TitledFormTextField from './TitledFormTextField';

export function FormEmailField<T>(props: {
  id: Path<T>;
  TextField?: TextFieldProps;
  required?: boolean;
  rules?: Omit<
    RegisterOptions<T, Path<T>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}) {
  return (
    <TitledFormTextField
      id={props.id}
      rules={{
        required: props.required ? 'Please provide an e-mail address.' : false,
        pattern: {
          value: /^[^@]+@[^@]+\.[^@]+$/,
          message: 'Invalid email address.',
        },
        maxLength: {
          value: 100,
          message: 'E-mail address must have less than 100 characters.',
        },
        ...props.rules,
      }}
      TextField={{
        type: 'email',
        autoComplete: 'email',
        label: 'E-mail address',
        placeholder: 'Enter e-mail address',
        ...props.TextField,
      }}
    />
  );
}
