import React from 'react';
import moment from 'moment';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
} from '@react-pdf/renderer';
import { GetParcelModel, getStatusName } from 'src/types/delivery/Delivery';
import { formatAddress } from 'src/components/table';
import { GetPodByTrackingIdQueryResult } from 'src/services/api';
import logo from 'src/assets/logotypes/dip-primary-black.png';

interface PodFileProps {
  parcel: GetParcelModel;
  pod: GetPodByTrackingIdQueryResult;
}

const styles = StyleSheet.create({
  document: {
    minHeight: '800px',
  },
  page: {
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: '#f7f8ff',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  logo: {
    width: 100,
    marginRight: 40,
  },
  title: {
    fontSize: 22,
    fontWeight: 800,
  },
  block: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    padding: 20,
    width: '50%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    fontSize: 12,
    lineHeight: 1.35,
    marginBottom: 1,
  },
  label: {
    backgroundColor: '#000',
    color: '#fff',
    fontWeight: 800,
    fontSize: 12,
    padding: '3px 5px 1px',
    minWidth: 140,
  },
  value: {
    padding: '3px 5px 1px',
    color: '#363636',
    fontSize: 12,
    fontWeight: 300,
  },
  fullWidth: {
    minWidth: '100%',
  },
  marginBottom: {
    marginBottom: 15,
  },
  disclaimer: {
    padding: 20,
    fontSize: 11,
    lineHeight: 1.35,
    fontWeight: 300,
    color: '#363636',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '200px',
    objectFit: 'contain',
  },
});

export const PodFile = (props: PodFileProps) => {
  const {
    orderId,
    id,
    parcelLocation,
    trackingId,
    deliveryType,
    size,
    referenceNumber1,
    referenceNumber2,
    status,
    paymentStatus,
    paymentMethod,
    deliveryEnd,
  } = props.parcel;
  const { filesUrls, name, pickedUpAt } = props.pod;
  return (
    <Document style={styles.document}>
      <Page size='A4' style={styles.page}>
        <View style={styles.header}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.title}>Proof of Delivery</Text>
        </View>
        <View>
          <View style={styles.block}>
            <View style={styles.column}>
              <View style={styles.row}>
                <Text style={styles.label}>Order Id (Connote No)</Text>
                <Text style={styles.value}>{orderId}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Parcel Id</Text>
                <Text style={styles.value}>{id}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Service</Text>
                <Text style={styles.value}>{deliveryType}</Text>
              </View>
            </View>
          </View>

          <View style={styles.block}>
            <View style={styles.column}>
              <View style={styles.row}>
                <Text style={[styles.label, styles.fullWidth]}>
                  Sender details
                </Text>
              </View>
              <View style={[styles.row, styles.marginBottom]}>
                <Text style={styles.value}>
                  {formatAddress(parcelLocation?.pickupAddress)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.label, styles.fullWidth]}>
                  Recipient details
                </Text>
              </View>
              <View style={[styles.row, styles.marginBottom]}>
                <Text style={styles.value}>
                  {formatAddress(parcelLocation?.shippingAddress)}
                </Text>
              </View>
              <View style={[styles.row, styles.marginBottom]}>
                <Text style={styles.label}>No. of items</Text>
                <Text style={styles.value}>1</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Sender Ref 1</Text>
                <Text style={styles.value}>{referenceNumber1}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Sender Ref 2</Text>
                <Text style={styles.value}>{referenceNumber2}</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Dimensions (cm)</Text>
                <Text style={styles.value}>
                  {size.dimensions.x} x {size.dimensions.y} x{' '}
                  {size.dimensions.z}
                </Text>
              </View>
              <View style={[styles.row, styles.marginBottom]}>
                <Text style={styles.label}>Dead weight (kg)</Text>
                <Text style={styles.value}>{size.weightInKg}</Text>
              </View>
            </View>

            <View style={styles.column}>
              <View style={[styles.row, styles.marginBottom]}>
                <Text style={styles.label}>Picked Up Date</Text>
                <Text style={styles.value}>
                  {moment(pickedUpAt).format('DD.MM.YYYY, h:mm A')}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Delivery Status</Text>
                <Text style={styles.value}>
                  {getStatusName(status, paymentStatus, paymentMethod)}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Date</Text>
                <Text style={styles.value}>
                  {moment(deliveryEnd).format('DD/MM/YYYY')}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Time</Text>
                <Text style={styles.value}>
                  {moment(deliveryEnd).format('LT')}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>Signed by</Text>
                <Text style={styles.value}>{name || '-'}</Text>
              </View>
              {filesUrls && filesUrls.length > 0 && (
                <View style={[styles.row, styles.marginBottom]}>
                  <Text style={[styles.label]}>Signature image</Text>
                  <Text style={styles.value}>
                    <Link src={`${window.location.origin}/pod/${trackingId}`}>
                      {`/pod/${trackingId}`}
                    </Link>
                  </Text>
                </View>
              )}
              {trackingId && (
                <View style={styles.row}>
                  <Text style={styles.label}>Tracking link</Text>
                  <Text style={styles.value}>
                    <Link
                      src={`${window.location.origin}/tracking/${trackingId}`}
                    >
                      {`/tracking/${trackingId}`}
                    </Link>
                  </Text>
                </View>
              )}
            </View>
          </View>
          <Text style={styles.disclaimer}>
            Deliver In Person has provided the information contained in this
            Proof of Delivery to you for your business purposes only. You must
            only use this information to establish that the freight in question
            was delivered and this information is not to be passed on to any
            other party unless you do so pursuant to the provisions of the
            Privacy Act 1988.
          </Text>
        </View>
      </Page>
    </Document>
  );
};
