import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  clearState,
  fetchOrganizatonIdAsync,
  selectOrganization,
  setOrganizationInfo,
} from 'src/features/organization/organizationSlice';

export function Organization() {
  const dispatch = useAppDispatch();
  const { orgId } = useAppSelector(selectOrganization);

  useEffect(() => {
    dispatch(fetchOrganizatonIdAsync());
  }, [dispatch]);

  useEffect(() => {
    if (!orgId) return;
    return onSnapshot(
      query(
        collection(getFirestore(), `/organizations`),
        where('id', '==', orgId)
      ),
      (querySnapshot) => {
        dispatch(setOrganizationInfo(querySnapshot.docs[0].data()));
      }
    );
  }, [dispatch, orgId]);

  return <></>;
}
