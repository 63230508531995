import useStyles, { styleOptions } from './Payment.styles';
import { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffectAsync } from 'src/components/hooks/useEffectAsync';
import { PaymentMethodService } from 'src/services/api';
import { toast } from 'react-toastify';
import React from 'react';
import unknownCardLogo from 'src/assets/cards/unknown.svg';
import { cardLogos } from './cardLogos';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from 'src/components/LoadingButton';
import { PaymentComponentOptions } from './types';

export const CheckoutForm = ({
  onSuccess,
  onError,
  disabled,
  payment,
  onCancel,
}: PaymentComponentOptions) => {
  const classes = useStyles();

  const [pending, setPending] = useState<boolean>(false);
  const [paymentProcessed, setPaymentProcessed] = useState<boolean>(false);
  const [cardsLoading, setCardsLoading] = useState<boolean>(false);
  const [cards, setCards] = useState<any>(null);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [removeCardId, setRemoveCardId] = useState<string | null>(null);

  const stripe = useStripe();
  const elements = useElements();

  useEffectAsync(async () => {
    fetchCards();
  }, []);

  const fetchCards = async () => {
    setCardsLoading(true);
    PaymentMethodService.getPaymentMethods()
      .then((response: any) => {
        response && setCards(response.paymentMethods);
        setCardsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Could not fetch saved cards data.', {
          hideProgressBar: true,
        });
        setCardsLoading(false);
      });
  };

  const removeCard = (paymentMethodId: string) => {
    setCardsLoading(true);
    PaymentMethodService.detachPaymentMethod(paymentMethodId)
      .then((response) => {
        toast.success('Successfully deleted the card.', {
          hideProgressBar: true,
        });
      })
      .catch((e) => {
        console.error(e);
        toast.error('Could not delete the card.', {
          hideProgressBar: true,
        });
      })
      .finally(() => {
        setCardsLoading(false);
        fetchCards();
      });
  };

  const handleCardSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCardId((event.target as HTMLInputElement).value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements || !payment?.paymentIntent) {
      return;
    }
    const card = selectedCardId || elements.getElement(CardElement);
    if (!card) {
      throw new Error('card is null');
    }

    setPending(true);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      payment?.paymentIntent,
      {
        save_payment_method: true,
        // @ts-ignore
        payment_method: selectedCardId || { card },
      }
    );
    if (error) {
      onError(error?.message);
      setPending(false);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      setPaymentProcessed(true);
      setPending(false);
      onSuccess();
    }
  };

  const getCardImage = (brandName: string) => {
    const logo = cardLogos.find((logo) => logo.brand === brandName);
    if (logo) {
      return logo.image;
    } else {
      return unknownCardLogo;
    }
  };

  const onRemoveConfirmed = () => {
    removeCardId && removeCard(removeCardId);
    setRemoveCardId(null);
  };

  return (
    <form onSubmit={handleSubmit} id='payments-form'>
      {payment && (
        <DialogTitle>
          Please {cards?.length ? 'select' : 'provide'} a credit card
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        {payment && (
          <>
            {!cardsLoading && cards && cards.length > 0 && (
              <Box>
                <Typography variant='h4' className={classes.cardTitle}>
                  Previously used cards:
                </Typography>
                <FormControl sx={{ width: '100%' }}>
                  <RadioGroup
                    value={selectedCardId}
                    onChange={handleCardSelect}
                  >
                    {cards.map((card: any, index: number) => (
                      <FormControlLabel
                        id={`payment-cards-row-${index}-radio`}
                        className={classes.cardControlLabel}
                        value={card.paymentMethodId}
                        control={<Radio size='small' />}
                        label={
                          <Grid container alignItems='center' columnSpacing={1}>
                            <Grid item xs={3} className={classes.cardLogo}>
                              <img
                                src={getCardImage(card.brand)}
                                alt={card.brand}
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <Grid
                                container
                                alignItems='center'
                                columnSpacing={4}
                              >
                                <Grid item className={classes.cardDataDetails}>
                                  xxxx xxxx xxxx {card.lastFourNumbers}
                                  <br />
                                  <span>{card.brand}</span>
                                </Grid>
                                <Grid item className={classes.cardRemove}>
                                  <IconButton
                                    id={`payment-cards-row-${index}-delete-icon`}
                                    size='small'
                                    aria-label='close'
                                    onClick={() =>
                                      setRemoveCardId(card.paymentMethodId)
                                    }
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            <Dialog
              open={!!removeCardId}
              onClose={() => setRemoveCardId(null)}
              PaperProps={{
                sx: {
                  maxWidth: '470px',
                },
              }}
            >
              <DialogTitle>
                Remove card
                <IconButton
                  aria-label='close'
                  onClick={() => setRemoveCardId(null)}
                  className={classes.dialogExitIcon}
                  sx={{ position: 'absolute' }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to remove saved card?
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  id='payment-card-remove-dialog-cancel-button'
                  variant='outlined'
                  color='inherit'
                  onClick={() => setRemoveCardId(null)}
                >
                  Cancel
                </Button>
                <Button
                  id='payment-card-remove-dialog-confirm-button'
                  variant='contained'
                  color='primary'
                  onClick={onRemoveConfirmed}
                  autoFocus
                >
                  Remove
                </Button>
              </DialogActions>
            </Dialog>
            {cards && (
              <Typography variant='h4' className={classes.cardTitle}>
                New card:
              </Typography>
            )}
            <CardElement
              id='payments-form-stipe-new-card'
              options={styleOptions}
              onFocus={() => setSelectedCardId(null)}
              onReady={(element) => element.focus()}
            />
          </>
        )}
        {!payment && (
          <CircularProgress
            color='secondary'
            className={classes.circularProgress}
            size={22}
          />
        )}
      </DialogContent>
      {payment && (
        <DialogActions className={classes.dialogActions}>
          <Button
            id='payment-cancel-button'
            variant='outlined'
            color='inherit'
            onClick={onCancel}
          >
            Cancel
          </Button>
          <LoadingButton
            id='payment-confirm-button'
            loading={!!disabled || pending || paymentProcessed || cardsLoading}
            variant='contained'
            type='submit'
            disabled={!stripe || !elements}
            color='primary'
          >
            {'Pay and ship'}
          </LoadingButton>
        </DialogActions>
      )}
    </form>
  );
};
