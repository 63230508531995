import { Box, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import FormSection from 'src/components/form/FormSection';
import { FormValue } from 'src/components/form/FormValue';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import LoadingBar from 'src/components/LoadingBar';
import LoadingButton from 'src/components/LoadingButton';
import { EditIcon } from 'src/features/delivery/components/icons';
import {
  changePasswordAsync,
  fetchUserInfo,
  selectSettings,
  updateUserDetailsAsync,
} from '../settingsSlice';
import { SettingsAccountInfoModel } from '../types';

const useStyles = makeStyles((theme) => ({
  editButton: {
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
    border: '1px solid #BFBFBF !important',
  },
  editIcon: {
    height: '18px !important',
    width: '18px !important',
    marginLeft: '8px',
  },
  formButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

type SectionKey = 'user' | 'password';

export function SettingsAccount() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    currentUser: user,
    status,
    errorMessage,
  } = useAppSelector(selectSettings);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  const [sectionInEdit, setSectionInEdit] = useState<SectionKey | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const form = useForm<SettingsAccountInfoModel>({
    defaultValues: {},
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const { setValue } = form;

  const toastError = useCallback(() => {
    toast.error(
      errorMessage === 'validation_error'
        ? 'Please correct your form and try again.'
        : errorMessage || 'An unhandled error occured.',
      { hideProgressBar: true }
    );
  }, [errorMessage]);

  useEffect(() => {
    if (!submitted) return;

    if (status === 'succeeded') {
      switch (sectionInEdit) {
        case 'user':
          toast.success('User details has been updated.', {
            hideProgressBar: true,
          });
          break;

        case 'password':
          toast.success('Password has been updated.', {
            hideProgressBar: true,
          });
          break;
      }

      setSectionInEdit(null);
      setSubmitted(false);
    } else if (status === 'failed') {
      toastError();
    }
  }, [status, errorMessage, toastError, sectionInEdit, submitted]);

  const validateForm = (onValid: () => void): Promise<void> => {
    return form.handleSubmit(onValid, () =>
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      })
    )();
  };

  const resetForm = useCallback(() => {
    if (!user) {
      return;
    }

    setValue('firstName', user.firstName);
    setValue('lastName', user.lastName);
  }, [setValue, user]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const onSubmit = () => {
    const formValues = form.getValues();

    switch (sectionInEdit) {
      case 'user':
        validateForm(() => {
          dispatch(
            updateUserDetailsAsync({
              userId: user!.id!,
              firstName: formValues.firstName,
              lastName: formValues.lastName,
            })
          );
          setSubmitted(true);
        });
        break;

      case 'password':
        validateForm(() => {
          dispatch(
            changePasswordAsync({
              userId: user!.id!,
              oldPassword: formValues.oldPassword,
              newPassword: formValues.password,
              confirmPassword: formValues.confirmPassword,
            })
          );
          setSubmitted(true);
        });
        break;
    }
  };

  const onCancel = () => {
    resetForm();
    setSubmitted(false);
    setSectionInEdit(null);
  };

  const editButton = (sectionKey: SectionKey, label?: string) => {
    if (sectionInEdit === sectionKey) return undefined;

    return (
      <Button
        color='inherit'
        variant='outlined'
        id='my-account-button-edit'
        className={classes.editButton}
        onClick={() => setSectionInEdit(sectionKey)}
      >
        {!label && (
          <>
            Edit <EditIcon className={classes.editIcon} />
          </>
        )}
        {label}
      </Button>
    );
  };

  if (status === 'loading' && !user) return <LoadingBar />;

  return (
    <Box sx={{ maxWidth: '600px' }}>
      <FormProvider {...form}>
        <FormSection
          title='Your details'
          headerChildren={editButton('user')}
          fullSize
        >
          {sectionInEdit !== 'user' ? (
            <>
              <FormValue label='First name' value={user?.firstName} />
              <FormValue label='Last name' value={user?.lastName} />
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TitledFormTextField<SettingsAccountInfoModel>
                    id='firstName'
                    TextField={{
                      type: 'text',
                      label: 'First name',
                      placeholder: 'Enter your first name',
                    }}
                    rules={{
                      required: 'Please provide first name.',
                      minLength: {
                        value: 2,
                        message: 'First name must have at least 2 characters.',
                      },
                      maxLength: {
                        value: 16,
                        message:
                          'First name must have less than 16 characters.',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TitledFormTextField<SettingsAccountInfoModel>
                    id='lastName'
                    TextField={{
                      type: 'text',
                      label: 'Last name',
                      placeholder: 'Enter your last name',
                    }}
                    rules={{
                      required: 'Please provide last name.',
                      minLength: {
                        value: 2,
                        message: 'Last name must have at least 2 characters.',
                      },
                      maxLength: {
                        value: 16,
                        message: 'Last name must have less than 16 characters.',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formButtons}>
                  <Button
                    variant='outlined'
                    color='inherit'
                    onClick={onCancel}
                    id='my-account-button-cancel'
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={status === 'loading'}
                    variant='contained'
                    onClick={onSubmit}
                    id='my-account-button-save'
                  >
                    Save changes
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          )}
        </FormSection>
        {/* <FormSection title="Password" fullSize>
                {editButton("password", "Change password") || (<></>)}
                {sectionInEdit === "password"
                    ? <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TitledFormTextField<SettingsAccountInfoModel>
                                id="oldPassword"
                                TextField={{
                                    type: "password",
                                    label: "Old password",
                                }}
                                pattern={passwordPattern}
                                rules={{
                                    required: "You must specify a password",
                                    minLength: {
                                        value: 8,
                                        message: "Password must have at least 8 characters"
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TitledFormTextField<SettingsAccountInfoModel>
                                id="password"
                                TextField={{
                                    type: "password",
                                    label: "New password",
                                }}
                                pattern={passwordPattern}
                                rules={{
                                    required: "You must specify a password",
                                    minLength: {
                                        value: 8,
                                        message: "Password must have at least 8 characters"
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TitledFormTextField<SettingsAccountInfoModel>
                                id="confirmPassword"
                                TextField={{
                                    type: "password",
                                    label: "Retype password",
                                }}
                                pattern={passwordPattern}
                                rules={{
                                    required: "You must specify a password",
                                    minLength: {
                                        value: 8,
                                        message: "Password must have at least 8 characters"
                                    },
                                    validate: (value: any) => value === watch('password') || "The passwords do not match"
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.formButtons}>
                            <Button variant="outlined" color="inherit" onClick={onCancel}>Cancel</Button>
                            <LoadingButton loading={status === "loading"} variant="contained" onClick={onSubmit}>Save changes</LoadingButton>
                        </Grid>
                    </Grid>
                    : (<></>)
                }
            </FormSection> */}
      </FormProvider>
    </Box>
  );
}
