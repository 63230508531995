import { useEffect } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';

import Grid from '@mui/material/Grid/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useAppSelector } from 'src/app/hooks';
import { RegistrationFormProps } from 'src/features/user/register/types';
import { RegisterFormModel } from 'src/features/user/model';
import { selectRegistration } from 'src/features/user/registrationSlice';
import { setServerErrors } from 'src/utilities/formHooksHelpers';

import useStyles from './styles';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import { ArrowRightIcon } from 'src/features/delivery/components/icons';
import { FormEmailField } from 'src/components/form/FormEmailField';

export function BasicInformationForm({ onSubmit }: RegistrationFormProps) {
  const { errors } = useAppSelector(selectRegistration);
  const form = useFormContext<RegisterFormModel>();
  const { setError, handleSubmit, watch, getValues } = form;
  const classes = useStyles();

  useEffect(() => {
    if (errors !== undefined) {
      setServerErrors<RegisterFormModel>({
        setError,
        errors: errors,
        include: ['email', 'password'],
      });
    }
  }, [errors, setError]);

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        onSubmit={(event: any) => {
          handleSubmit(
            (values) => onSubmit(0, values),
            (errors) => {
              if (
                errors.firstName ||
                errors.lastName ||
                errors.email ||
                errors.password ||
                errors.confirmPassword
              )
                return;

              onSubmit(0, getValues());
            }
          )(event);
        }}
        style={{ width: '100%' }}
        sx={{ mt: 3 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitledFormTextField<RegisterFormModel>
              id='firstName'
              TextField={{
                label: 'First name',
                placeholder: 'Enter your first name',
              }}
              rules={{
                required: 'Please provide first name.',
                minLength: {
                  value: 2,
                  message: 'First name must have at least 2 characters.',
                },
                maxLength: {
                  value: 16,
                  message: 'First name must have less than 16 characters.',
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TitledFormTextField<RegisterFormModel>
              id='lastName'
              TextField={{
                label: 'Last name',
                placeholder: 'Enter your last name',
              }}
              rules={{
                required: 'Please provide last name.',
                minLength: {
                  value: 2,
                  message: 'Last name must have at least 2 characters.',
                },
                maxLength: {
                  value: 16,
                  message: 'Last name must have less than 16 characters.',
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormEmailField<RegisterFormModel>
              id='email'
              TextField={{
                label: 'E-mail address',
                placeholder: 'Enter e-mail address',
              }}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TitledFormTextField<RegisterFormModel>
              id='password'
              TextField={{
                type: 'password',
                label: 'Password',
                placeholder: 'Enter password',
                autoComplete: 'password',
              }}
              rules={{
                required: 'Please specify a password.',
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters.',
                },
                maxLength: {
                  value: 50,
                  message: 'Password must have less than 50 characters.',
                },
              }}
              onPaste={(e) => e.preventDefault()}
            />
          </Grid>

          <Grid item xs={12}>
            <TitledFormTextField<RegisterFormModel>
              id='confirmPassword'
              TextField={{
                type: 'password',
                label: 'Confirm password',
                placeholder: 'Retype password',
              }}
              rules={{
                required: 'Please retype a password.',
                validate: (value: any) =>
                  value === watch('password') || 'The passwords do not match.',
              }}
              onPaste={(e) => e.preventDefault()}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent='center' className={classes.submitBox}>
          <Button
            id='registerNextStepButton'
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            sx={{ paddingTop: '16px', paddingBottom: '16px' }}
          >
            Next step <ArrowRightIcon />
          </Button>
        </Grid>
      </Box>
    </FormProvider>
  );
}

export default BasicInformationForm;
