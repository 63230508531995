import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 150px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function LoadingScreen(props: any) {
  const classes = useStyles();
  return (
    <Grid container direction='column' className={classes.root}>
      <CircularProgress />
    </Grid>
  );
}

export default LoadingScreen;
