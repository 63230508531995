import { createSvgIcon } from '@mui/material';

export const ArrowUpIcon = createSvgIcon(
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M18.7394 16.4118L12.5 10.1275L6.26056 16.4118L5 15.1421L12.5 7.58824L20 15.1421L18.7394 16.4118Z'
    fill='black'
  />,
  'ArrowUp'
);

export const ArrowDownIcon = createSvgIcon(
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M6.26056 7.58823L12.5 13.8725L18.7394 7.58823L20 8.85785L12.5 16.4118L5 8.85785L6.26056 7.58823Z'
    fill='black'
  />,
  'ArrowDown'
);
