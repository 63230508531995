import { Path, UseFormSetError } from 'react-hook-form';

export interface SetServerErrorsProps<T> {
  errors: { [key in keyof Partial<T>]: string[] | undefined };
  setError: UseFormSetError<T>;
  include?: Array<keyof Partial<T>>;
}

export function setServerErrors<T>({
  errors,
  setError,
  include,
}: SetServerErrorsProps<T>) {
  for (const [key, value] of Object.entries<string[] | string | undefined>(
    errors
  )) {
    if (include && !include?.find((propName) => propName === key)) continue;
    setError(key as Path<T>, {
      type: 'server',
      message: (Array.isArray(value) ? value?.join('\n') : value) || undefined,
    });
  }
}
