/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateInvitedUserAccountCommandParams } from '../models/CreateInvitedUserAccountCommandParams';
import type { Organization } from '../models/Organization';
import type { RegisterOrganizationCommandParams } from '../models/RegisterOrganizationCommandParams';
import type { UpdateBillingInfoCommandParams } from '../models/UpdateBillingInfoCommandParams';
import type { UpdateBrandingParams } from '../models/UpdateBrandingParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

  /**
   * Creates a new account during registration process
   * @param requestBody
   * @returns Organization Organization created
   * @throws ApiError
   */
  public static registerUser(
    requestBody: RegisterOrganizationCommandParams,
  ): CancelablePromise<Organization> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/register',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation failed`,
      },
    });
  }

  /**
   * Updates organization branding information
   * @param organizationId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static updateBranding(
    organizationId: string,
    requestBody: UpdateBrandingParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/{organizationId}/branding',
      path: {
        'organizationId': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation failed`,
      },
    });
  }

  /**
   * Creates an account for the invited user within organization
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static register(
    requestBody: CreateInvitedUserAccountCommandParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/createUserAccount',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized invitation code`,
        422: `Params validation error`,
      },
    });
  }

  /**
   * Updates organization billing information
   * @param organizationId ID of the organization of which the billing information should be updated
   * @param requestBody
   * @returns any Billing information updated successfully
   * @throws ApiError
   */
  public static updateBilling(
    organizationId: string,
    requestBody: UpdateBillingInfoCommandParams,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/organizations/{organizationId}/billing',
      path: {
        'organizationId': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        422: `Params validation error`,
      },
    });
  }

}