import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStateWithStatus } from 'src/app/hooks';
import { RootState } from 'src/app/store';
import { DeliveryService } from 'src/features/delivery/deliveryAPI';
import { HttpService } from 'src/services/httpService';
import { WebParcelProjection } from 'src/services/api/models/WebParcelProjection';

type FetchStatus = 'idle' | 'fetchDone' | 'fetchError';

export interface EditDeliveryState extends IStateWithStatus {
  fetchStatus: FetchStatus;
  order?: WebParcelProjection[] | undefined;
}

const initialState: EditDeliveryState = {
  status: 'idle',
  errorMessage: undefined,
  errors: undefined,
  fetchStatus: 'idle',
};

export const fetchOrderAsync = createAsyncThunk<
  Array<WebParcelProjection>,
  string
>('editDelivery/fetchOrder', async (orderId, thunkAPI) => {
  try {
    const response = await DeliveryService.fetchSelectedOrder(orderId);
    if (response) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.error('Error', e.message);
    return thunkAPI.rejectWithValue({ status: e.message });
  }
});

export const updateOrderAsync = HttpService.wrapApiAction(
  'editDelivery/update',
  DeliveryService.updateOrder
);

export const editDeliverySlice = createSlice({
  name: 'editDelivery',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.errors = undefined;
      state.errorMessage = undefined;
      state.order = undefined;
      return state;
    },
  },
  extraReducers: (builder) => {
    HttpService.registerApiAction(builder, updateOrderAsync);

    builder
      .addCase(fetchOrderAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderAsync.fulfilled, (state, { payload }) => {
        state.fetchStatus = 'fetchDone';
        state.status = 'idle';
        state.order = payload;
      })
      .addCase(fetchOrderAsync.rejected, (state, action) => {
        state.fetchStatus = 'fetchError';
        state.order = undefined;
        state.errorMessage = action.error as string;
      });
  },
});

export const { clearState } = editDeliverySlice.actions;
export const selectEditDelivery = (state: RootState) => state.editDelivery;
export default editDeliverySlice.reducer;
