import moment from 'moment';
import { Address, DeliveryTime } from 'src/services/api';

export function formatAddress(address?: Address): JSX.Element {
  const { line1, line2, state, postcode, city, country } = address || {};
  let addressLine;

  if (line2 && line2.length > 4) {
    addressLine = (
      <>
        {line2}
        <br />
        {line1}
      </>
    );
  } else if (line2 && line2.length <= 4) {
    addressLine = `${line2} ${line1}`;
  } else {
    addressLine = line1;
  }

  return (
    <>
      {addressLine}, {city}, {state}, {postcode}
    </>
  );
}

export function formatReferenceNumbers(model: {
  referenceNumber1?: string | null;
  referenceNumber2?: string | null;
  referenceNumber3?: string | null;
}): JSX.Element {
  return (
    <>
      {[model.referenceNumber1, model.referenceNumber2, model.referenceNumber3]
        .filter((x) => !!x)
        .map((x, i, arr) => (
          <>
            {x}
            {arr.length - 1 === i ? null : <br />}
          </>
        ))}
    </>
  );
}

export function formatDateString(date: string): JSX.Element {
  return (
    <>
      {moment(date).format('DD/MM/YYYY')} <br />
      {moment(date).format('LT')}
    </>
  );
}

export const getLocalTimeFromTimeObject = (timeObject: DeliveryTime) => {
  const { hour, minute, utcOffset } = timeObject;
  const localDate = moment()
    .utcOffset(utcOffset)
    .set({ hour: hour, minute: minute })
    .local();
  return localDate;
};

export const getLocalTimeFromTimestamp = (timestamp: number) => {
  const utcDate = moment(timestamp, 'X');
  const offset = moment().utcOffset();
  const localDate = utcDate.utcOffset(offset);
  return localDate;
};
