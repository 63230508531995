import { createTheme } from '@mui/material/styles';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const hProps: TypographyStyleOptions = {
  fontFamily: "'Lato', sans-serif",
  fontWeight: 'bold',
  paddingBottom: '0.75rem',
  paddingTop: '1.0rem',
};

export default createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: '8px',
          padding: '6px 20px',
          minWidth: '104px',
        },
        colorInherit: '#F5F4F4',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #DFDFDF',
          '& .MuiTabs-indicator': {
            height: '4px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '18px',
          color: '#808080',
          '&.Mui-selected': {
            color: '#000000',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          marginTop: '50px',
          marginLeft: '32px',
          marginRight: '32px',
          marginBottom: '32px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          marginTop: '50px',
          marginLeft: '32px',
          marginRight: '32px',
          padding: 0,
          paddingBottom: '16px',
          fontSize: '24px !important',
          fontWeight: '800 !important',
          lineHeight: '28px !important',
          textAlign: 'center',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginLeft: '32px',
          marginRight: '32px',
          marginBottom: '24px',
          padding: 0,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          marginLeft: '32px',
          marginRight: '32px',
          marginBottom: '32px',
          '& button': {
            height: '48px',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: '8px',
          color: '#ffffff',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '42px',
          color: '#ffffff',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: 1,
          minWidth: '110px !important',
          border: '1px solid #373737 !important',
          borderRadius: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '16px',
          margin: 0,
          borderBottom: '1px solid #DFDFDF',

          '&:last-of-type': {
            borderBottom: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    h1: {
      ...hProps,
      fontSize: 28,
      lineHeight: '34px',
      fontWeight: 800,
    },
    h2: {
      ...hProps,
      fontSize: 22,
    },
    h3: {
      ...hProps,
      fontSize: 18,
      lineHeight: '22px',
      fontWeight: 800,
    },
    h4: {
      ...hProps,
      fontWeight: 800,
      fontSize: '13px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      marginBottom: '8px',
    },
    h5: {
      ...hProps,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    h6: {
      ...hProps,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14px',
      padding: '4px 0',
    },
    body1: {
      fontSize: 14,
    },
    allVariants: {
      textTransform: 'none',
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: '18px',
      color: '#494949',
    },
  },
  palette: {
    primary: {
      light: '#FFD08A',
      main: '#ffbb5f',
      dark: '#FEA624',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#020202',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    error: {
      main: '#CC4232',
    },
    success: {
      main: '#4BB543',
    },
    background: {
      default: '#fff',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});
