/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckPostalCodeResponse } from '../models/CheckPostalCodeResponse';
import type { CheckSameAreaResponse } from '../models/CheckSameAreaResponse';
import type { GetAbnDetailsQueryResult } from '../models/GetAbnDetailsQueryResult';
import type { GetPrsedAddressResult } from '../models/GetPrsedAddressResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LookupService {

  /**
   * Checks if request postal code is inside area of operation
   * @param postalCode Postal code to check
   * @param suburb Postal code to check
   * @returns CheckPostalCodeResponse Ok
   * @throws ApiError
   */
  public static checkPostalCode(
    postalCode: string,
    suburb: string,
  ): CancelablePromise<CheckPostalCodeResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/lookup/address/postal-code/{postalCode}/{suburb}',
      path: {
        'postalCode': postalCode,
        'suburb': suburb,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Checks if request postal code is inside area of operation
   * @param postalCode Postal code to check
   * @param suburb Postal code to check
   * @param warehouseId
   * @returns CheckSameAreaResponse Ok
   * @throws ApiError
   */
  public static checkIfAddressesAreInTheSameZone(
    postalCode: string,
    suburb: string,
    warehouseId: string,
  ): CancelablePromise<CheckSameAreaResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/lookup/address/areaOfOperation/{postalCode}/{suburb}/{warehouseId}',
      path: {
        'postalCode': postalCode,
        'suburb': suburb,
        'warehouseId': warehouseId,
      },
      errors: {
        401: `Unauthorized`,
      },
    });
  }

  /**
   * Fetches business data for a given ABN from ABR
   * @param abn ABN to check
   * @returns GetAbnDetailsQueryResult
   * @throws ApiError
   */
  public static getAbnData(
    abn: string,
  ): CancelablePromise<GetAbnDetailsQueryResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/lookup/abn/{abn}',
      path: {
        'abn': abn,
      },
      errors: {
        404: `ABN does not exist`,
        422: `Validation error`,
      },
    });
  }

  /**
   * @param address
   * @returns GetPrsedAddressResult Address
   * @throws ApiError
   */
  public static getAddress(
    address: string,
  ): CancelablePromise<GetPrsedAddressResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/lookup/address/parse/{address}',
      path: {
        'address': address,
      },
    });
  }

}