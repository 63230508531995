import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  FragileIcon,
} from 'src/features/delivery/components/icons';
import ModeIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const useStyles = makeStyles(() => ({
  inlineInformation: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 3px 0',
  },
}));

export type ParcelInformationParams = {
  value: {
    requiresAttention: boolean;
    containsAlcohol?: boolean;
  };
};

export const ParcelInformation = ({ value }: ParcelInformationParams) => {
  const classes = useStyles();
  return (
    <>
      {!value.requiresAttention && !value.containsAlcohol ? (
        <>-</>
      ) : (
        <>
          {value.requiresAttention && (
            <Box className={classes.inlineInformation}>
              <FragileIcon /> Package requires attention
            </Box>
          )}
          {value.containsAlcohol && (
            <Box className={classes.inlineInformation}>
              <ModeIcon /> Requires signature
            </Box>
          )}
        </>
      )}
    </>
  );
};
