import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormProvider, useForm } from 'react-hook-form';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import FormSection from 'src/components/form/FormSection';
import { FormValue } from 'src/components/form/FormValue';
import {
  selectSettings,
  updateBillingInformationAsync,
  updateBrandInformationAsync,
} from '../settingsSlice';
import LoadingBar from 'src/components/LoadingBar';
import { formatAddress } from 'src/components/table';
import { EditIcon } from 'src/features/delivery/components/icons';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import { SettingsCompanyInfoFormModel } from '../types';
import AddressForm from 'src/components/form/AddressForm';
import FormAbnField from 'src/components/form/FormAbnField';
import { FormPhoneField } from 'src/components/form/FormPhoneField';
import { getPlaceTypeFromLatLng, PlaceType } from 'src/components/maps';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { toast } from 'react-toastify';
import LoadingButton from 'src/components/LoadingButton';
import { selectOrganization } from 'src/features/organization/organizationSlice';

const useStyles = makeStyles((theme) => ({
  editButton: {
    paddingTop: '6px !important',
    paddingBottom: '6px !important',
    border: '1px solid #BFBFBF !important',
  },
  editIcon: {
    height: '18px !important',
    width: '18px !important',
    marginLeft: '8px',
  },
  formButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
}));

type SectionKey = 'billingInformation' | 'brandInformation';

export function SettingsCompanyInfo() {
  const classes = useStyles();

  const { organization } = useAppSelector(selectOrganization);
  const { status, errorMessage, errors } = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const [sectionInEdit, setSectionInEdit] = useState<SectionKey | null>();
  const [selectedPlace, setSelectedPlace] = useState<PlaceType | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const form = useForm<SettingsCompanyInfoFormModel>({
    defaultValues: { billing: {}, branding: {} },
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const {
    formState: { errors: validationErrors },
    clearErrors,
    setValue,
  } = form;

  const resetForm = useCallback(() => {
    if (!organization) {
      return;
    }
    clearErrors();

    setValue('billing.organizationName', organization.organizationName);
    setValue('billing.abn', organization.abn);
    setValue('billing.companyEmail', organization.companyEmail);
    setValue('billing.phoneNumber', organization.companyPhone);
    setValue('billing.address', organization.billingAddress);
    setValue('branding.displayName', organization.branding.displayName);
    setValue('branding.email', organization.branding.email);
    setValue('branding.phone', organization.branding.phone);

    getPlaceTypeFromLatLng(organization.billingAddress)
      .then((e) => {
        setSelectedPlace(e);
      })
      .catch((e) => {
        if (e.message) toast.error(e.message, { hideProgressBar: true });
      });
  }, [setValue, organization]);

  useEffect(() => {
    if (!organization) return;
    resetForm();
  }, [resetForm, organization]);

  const toastError = useCallback(() => {
    Object.values(errors).forEach((value: any) => {
      toast.error(value, { hideProgressBar: true });
    });
  }, [errorMessage, errors]);

  useEffect(() => {
    if (!submitted) return;

    if (status === 'succeeded') {
      switch (sectionInEdit) {
        case 'brandInformation':
          toast.success('Branding information has been updated.', {
            hideProgressBar: true,
          });
          setSectionInEdit(null);
          setSubmitted(false);
          break;

        case 'billingInformation':
          toast.success('Billing information has been updated', {
            hideProgressBar: true,
          });
          setSectionInEdit(null);
          setSubmitted(false);
          break;
      }
    } else if (status === 'failed') {
      toastError();
    }
  }, [status, errorMessage, toastError, sectionInEdit, submitted]);

  const validateForm = (onValid: () => void): Promise<void> => {
    return form.handleSubmit(onValid, () =>
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      })
    )();
  };

  const editButton = (sectionKey: SectionKey) => {
    if (sectionInEdit === sectionKey) return undefined;

    return (
      <Button
        id={`${sectionKey}-button-edit`}
        color='inherit'
        variant='outlined'
        className={classes.editButton}
        onClick={() => setSectionInEdit(sectionKey)}
      >
        Edit <EditIcon className={classes.editIcon} />
      </Button>
    );
  };

  const onSubmit = () => {
    const formValues = form.getValues();

    switch (sectionInEdit) {
      case 'brandInformation':
        validateForm(() => {
          dispatch(
            updateBrandInformationAsync({
              ...formValues.branding,
              organizationId: organization!.id,
            })
          );
          setSubmitted(true);
        });
        break;

      case 'billingInformation':
        validateForm(() => {
          dispatch(
            updateBillingInformationAsync({
              abn: formValues.billing.abn.replace(/\D/g, ''),
              companyPhone: formValues.billing.phoneNumber,
              companyEmail: formValues.billing.companyEmail,
              organizationName: formValues.billing.organizationName,
              billingAddress: {
                ...formValues.billing.address,
              },
              organizationId: organization!.id,
            })
          );
          setSubmitted(true);
        });
        break;
    }
  };

  const onCancel = () => {
    resetForm();
    setSectionInEdit(null);
  };

  if (status === 'loading' && !organization) return <LoadingBar />;

  if (!organization) return null;

  const formValues = form.getValues();

  return (
    <Box sx={{ maxWidth: '600px' }}>
      <FormProvider {...form}>
        <FormSection
          title='Billing information'
          headerChildren={editButton('billingInformation')}
          fullSize
        >
          <Box sx={{ marginBottom: '24px' }}>
            We use this information for billing purposes. Please update them
            immediately if they have changed.
          </Box>

          {sectionInEdit !== 'billingInformation' ? (
            <>
              <FormValue
                label='Company name'
                value={organization.organizationName || 'No data'}
              />

              <FormValue label='Address'>
                {formatAddress(organization.billingAddress as any)}
              </FormValue>

              <FormValue
                label='ABN number'
                value={organization.abn || 'No data'}
              />

              <FormValue
                label='Company e-mail'
                value={organization.companyEmail || 'No data'}
              />

              <FormValue
                label='Company phone'
                value={organization.companyPhone || 'No data'}
              />
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TitledFormTextField<SettingsCompanyInfoFormModel>
                  id='billing.organizationName'
                  TextField={{
                    type: 'text',
                    label: 'Company name',
                    placeholder: 'Enter company name',
                  }}
                  rules={{
                    required: 'Please provide company name.',
                    minLength: {
                      value: 2,
                      message: 'Company name must have at least 2 characters.',
                    },
                    maxLength: {
                      value: 32,
                      message:
                        'Company name must have less than 32 characters.',
                    },
                  }}
                />
              </Grid>

              <AddressForm<SettingsCompanyInfoFormModel>
                idPrefix='billing.address'
                value={selectedPlace}
                onPlaceSelect={setSelectedPlace}
                addressSearch={{
                  title: '*Company address',
                  placeholder: 'Search for address',
                  helperText: '',
                }}
              />

              <Grid item xs={12}>
                <FormAbnField<SettingsCompanyInfoFormModel>
                  id='billing.abn'
                  errorData={errors?.abn || validationErrors?.billing?.abn}
                  TextField={{
                    label: 'Company ABN',
                    placeholder: 'Enter company ABN',
                    fullWidth: true,
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchSharpIcon fontSize='small' color='action' />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormEmailField<SettingsCompanyInfoFormModel>
                  id='billing.companyEmail'
                  TextField={{
                    label: 'Company e-mail',
                    placeholder: 'Enter company e-mail address',
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <FormPhoneField<SettingsCompanyInfoFormModel>
                  id={'billing.phoneNumber'}
                  TextField={{
                    label: 'Company phone number',
                    placeholder: '+61 000 000 000',
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12} className={classes.formButtons}>
                <Button
                  variant='outlined'
                  color='inherit'
                  onClick={onCancel}
                  id='billingInformation-button-cancel'
                >
                  Cancel
                </Button>
                <LoadingButton
                  id='billingInformation-button-save'
                  loading={status === 'loading'}
                  variant='contained'
                  onClick={onSubmit}
                >
                  Save changes
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </FormSection>

        <FormSection
          title='Brand information'
          headerChildren={editButton('brandInformation')}
          fullSize
        >
          <Box sx={{ marginBottom: '24px' }}>
            Brand information is your public contact information that will
            appear on your parcel label. It is to ensure your customers can
            contact you using the email and phone number as provided below.
          </Box>

          {sectionInEdit !== 'brandInformation' ? (
            <>
              <FormValue
                label='Display name'
                value={organization.branding.displayName || 'No data'}
              />

              <FormValue
                label='Brand e-mail'
                value={organization.branding.email || 'No data'}
              />

              <FormValue
                label='Brand phone'
                value={organization.branding.phone || 'No data'}
              />
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TitledFormTextField<SettingsCompanyInfoFormModel>
                  id='branding.displayName'
                  TextField={{
                    type: 'text',
                    label: 'Display name',
                    placeholder: 'Enter company name',
                  }}
                  rules={{
                    required: 'Please provide display name.',
                    minLength: {
                      value: 2,
                      message: 'Display name must have at least 2 characters.',
                    },
                    maxLength: {
                      value: 32,
                      message:
                        'Display name must have less than 32 characters.',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormEmailField<SettingsCompanyInfoFormModel>
                  id='branding.email'
                  TextField={{
                    variant: 'outlined',
                    label: 'Brand e-mail',
                    placeholder: 'Enter brand e-mail address',
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <FormPhoneField<SettingsCompanyInfoFormModel>
                  id='branding.phone'
                  TextField={{
                    label: 'Brand phone',
                    placeholder: '+61 000 000 000',
                  }}
                  required
                />
              </Grid>

              <Grid item xs={12} className={classes.formButtons}>
                <Button
                  variant='outlined'
                  color='inherit'
                  onClick={onCancel}
                  id='brandInformation-button-cancel'
                >
                  Cancel
                </Button>
                <LoadingButton
                  id='brandInformation-button-save'
                  loading={status === 'loading'}
                  variant='contained'
                  onClick={onSubmit}
                >
                  Save changes
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </FormSection>

        <FormSection title='Payment method' fullSize>
          <FormControl>
            <RadioGroup
              value={
                organization?.salesAgreementSigned
                  ? 'periodicPayment'
                  : 'instantPayment'
              }
            >
              <FormControlLabel
                id='paymentMethod-instantPayment'
                disabled={organization?.salesAgreementSigned}
                value='instantPayment'
                control={<Radio />}
                label='At the checkout'
              />
              <FormHelperText>
                Deliver In Person takes payment by credit card (or debit cards
                with a credit facility) through the secure{' '}
                <a href='https://stripe.com/' target='_blank'>
                  Stripe
                </a>{' '}
                payment gateway. We accept Mastercard, Visa and American
                Express. We do not currently accept PayPal.
              </FormHelperText>
              <FormControlLabel
                id='paymentMethod-periodicPayment'
                disabled={!organization?.salesAgreementSigned}
                value='periodicPayment'
                control={<Radio />}
                label='Regular billing cycle'
              />
              <FormHelperText>
                We can invoice your business if a signed agreement is in place.
                If you’d like this option, contact us at{' '}
                <a href='mailto:sales@deliverinperson.com'>
                  sales@deliverinperson.com
                </a>{' '}
                to arrange.
              </FormHelperText>
            </RadioGroup>
          </FormControl>
        </FormSection>
      </FormProvider>
    </Box>
  );
}
