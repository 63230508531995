import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-body': {
      fontSize: '13px !important',
      [theme.breakpoints.down('xl')]: {
        fontSize: '12px !important',
      },
    },
  },
  toolbar: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
    justifyContent: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiButton-label': {
      fontSize: '12px',
      fontWeight: '400',
    },
  },
  btnIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: '20px',
    height: '20px',
    display: 'inline-block',
  },
  circularProgressBox: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(1),
  },
  dialogExitIcon: {
    right: '8px !important',
    top: '8px !important',
  },
  dialogActions: {
    '& button': {
      width: '50%',
    },
  },
  tooltipIcon: {
    fontSize: '15px !important',
    color: '#000000d9',
    transform: 'translate(2px, 2px)',
  },
}));
