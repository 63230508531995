import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Parcel } from 'src/types/delivery/Delivery';
import { RootState } from '../../app/store';
import { fetchRecentDeliveries } from './deliveryAPI';

type StateStatus = 'idle' | 'loading' | 'failed' | 'succeeded';
export interface DeliveryState {
  status: StateStatus;
  recentDeliveries?: Parcel[];
  error?: string | undefined;
}

const initialState: DeliveryState = {
  recentDeliveries: undefined,
  status: 'idle',
};
export const fetchRecentDeliveriesAsync = createAsyncThunk(
  'delivery/fetchRecent',
  async (_, thunkAPI) => {
    try {
      const response = await fetchRecentDeliveries();
      if (response !== undefined) {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.error('Error', e.message);
      thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.error = undefined;
      state.recentDeliveries = undefined;
      return state;
    },
    setDeliveries: (state, { payload }) => {
      state.recentDeliveries = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentDeliveriesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRecentDeliveriesAsync.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.recentDeliveries = payload;
      })
      .addCase(fetchRecentDeliveriesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message;
      });
  },
});

export const { clearState, setDeliveries } = deliverySlice.actions;
export const selectDelivery = (state: RootState) => state.delivery;
export default deliverySlice.reducer;
