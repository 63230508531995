import { makeStyles } from '@mui/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginRight: '1rem',
    marginLeft: '1rem',
    marginTop: '2px',
  },
}));

export interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
}

export default function LoadingButton({
  children,
  loading,
  ...defaultProps
}: LoadingButtonProps) {
  const classes = useStyles();

  return (
    <Button {...defaultProps} disabled={loading || defaultProps.disabled}>
      {loading ? (
        <CircularProgress
          color='secondary'
          className={classes.circularProgress}
          size={22}
        />
      ) : (
        children
      )}
    </Button>
  );
}
