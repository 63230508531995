import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/store';
import { OrganizationReadonlyService } from 'src/services/readonly/organization';
import { Warehouse } from 'src/services/api/models/Warehouse';
import { WarehouseService } from 'src/services/warehouseService';

type StateStatus = 'idle' | 'loading' | 'failed' | 'succeeded';

export interface WarehouseState {
  warehouses: Warehouse[];
  selectedWarehouseId?: string;
  status: StateStatus;
  errorMessage?: string | undefined;
  errors?: any | undefined;
}

const initialState: WarehouseState = {
  warehouses: [],
  selectedWarehouseId: localStorage.getItem('selectedWarehouse') || undefined,
  status: 'loading',
  errorMessage: undefined,
  errors: undefined,
};

export const fetchWarehousesAsync = createAsyncThunk<Warehouse[] | undefined>(
  'warehouse/fetch',
  async (_, thunkAPI) => {
    try {
      const response = await OrganizationReadonlyService.getWarehouses();
      if (response !== undefined) {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e: any) {
      console.error('Error', e.message);
      thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateWarehouseAsync =
  WarehouseService.updateWarehouseAsync('warehouse');

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    changeWarehouseSelection: (state, action: PayloadAction<string>) => {
      state.selectedWarehouseId = action.payload;
      localStorage.setItem('selectedWarehouse', action.payload);
    },
    clearState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehousesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWarehousesAsync.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        if (payload) {
          state.warehouses = payload;

          if ((payload || []).length > 0) {
            if (
              !state.selectedWarehouseId ||
              payload.filter((p) => p.id === state.selectedWarehouseId)
                .length === 0
            ) {
              state.selectedWarehouseId = state.warehouses[0].id;
            }
          }
        }
      })
      .addCase(fetchWarehousesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error?.message;
      });
  },
});

export const { changeWarehouseSelection, clearState } = warehouseSlice.actions;
export const selectWarehouse = (state: RootState) => state.warehouse;
export default warehouseSlice.reducer;
