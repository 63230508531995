import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { Dialog } from '@mui/material';
import { useEffectAsync } from 'src/components/hooks/useEffectAsync';
import { CreatePaymentIntentResponse } from 'src/services/api';
import React from 'react';
import { CheckoutForm } from './CheckoutForm';
import { PaymentComponentOptions } from './types';

export const PaymentComponent = (props: PaymentComponentOptions) => {
  const { paymentDialogOpen, onPaymentDialogClose } = props;
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  useEffectAsync(async () => {
    if (!props.payment?.publishableKey) return;

    setStripePromise(await loadStripe(props.payment.publishableKey));
  }, [props.payment]);

  if (!stripePromise) return null;

  const options = {
    clientSecret: props.payment?.paymentIntent,
  };

  return (
    <Dialog
      open={paymentDialogOpen}
      onClose={(event: any, reason: string) =>
        reason !== 'backdropClick' && onPaymentDialogClose()
      }
      disableEscapeKeyDown
    >
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm {...props} />
      </Elements>
    </Dialog>
  );
};
