import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStateWithStatus } from 'src/app/hooks';
import { RootState } from 'src/app/store';
import {
  GetTeamMembersQueryResult,
  OrganizationUsersService,
} from 'src/services/api';
import { HttpService } from 'src/services/httpService';
import { OrganizationReadonlyService } from 'src/services/readonly/organization';
import { WarehouseService } from 'src/services/warehouseService';
import { OnboardingService } from '../onboarding/onboardingAPI';
import { SettingsService } from './settingsAPI';
import { UserModel } from './types';

export interface SettingsState extends IStateWithStatus {
  orgId?: string;
  users: GetTeamMembersQueryResult['teamMembers'];
  currentUser?: UserModel;
}

const initialState: SettingsState = {
  status: 'idle',
  users: [],
};

export const updateBrandInformationAsync = HttpService.wrapApiAction(
  'settings/updateBrandInfo',
  OnboardingService.updateBrandInformation
);

export const updateBillingInformationAsync = HttpService.wrapApiAction<
  void,
  SettingsService.UpdateBillingInformationRequest
>('settings/updateBillingInfo', SettingsService.updateBillingInformation);

export const updateUserDetailsAsync = HttpService.wrapApiAction<
  void,
  SettingsService.UpdateUserDetailsRequest
>(
  'settings/updateUserDetails',
  SettingsService.updateUserDetails,
  ({ dispatch }) => dispatch(fetchUserInfo())
);

export const updatePrinterSettingsAsync = HttpService.wrapApiAction<
  void,
  SettingsService.UpdatePrinterSettingsRequest
>(
  'settings/updatePrinterSettings',
  SettingsService.updatePrinterSettings,
  ({ dispatch }) => dispatch(fetchUserInfo())
);

export const changePasswordAsync = HttpService.wrapApiAction<void, any>(
  'settings/changePassword',
  async (model) => Promise.reject(model)
);

export const fetchUserInfo = createAsyncThunk(
  'settings/fetchUserInfo',
  async () => {
    return await OrganizationReadonlyService.getCurrentUserInfo();
  }
);

export const fetchOrganizationUsersAsync = createAsyncThunk(
  'settings/fetchOrganizationUsers',
  async (model?: string) => {
    if (!model) return [];

    return (await OrganizationUsersService.getTeamMembers(model)).teamMembers;
  }
);

export const sendInvitationsAsync = HttpService.wrapApiAction<
  void,
  OnboardingService.InvitationsRequest
>(
  'settings/sendInvitations',
  OnboardingService.sendInvitations,
  ({ dispatch, getState }) =>
    dispatch(fetchOrganizationUsersAsync(getState().organization.orgId))
);

export const deleteUserAsync = HttpService.wrapApiAction<
  void,
  SettingsService.DeleteUserRequest
>('settings/deleteUser', SettingsService.deleteUser, ({ dispatch, getState }) =>
  dispatch(fetchOrganizationUsersAsync(getState().organization.orgId))
);

export const deleteInvitationAsync = HttpService.wrapApiAction<
  void,
  SettingsService.DeleteInvitationRequest
>(
  'settings/deleteInvitation',
  async (model) => await SettingsService.deleteInvitation(model),
  ({ dispatch, getState }) =>
    dispatch(fetchOrganizationUsersAsync(getState().organization.orgId))
);

export const resendInvitationAsync = HttpService.wrapApiAction<
  void,
  SettingsService.ResendInvitationRequest
>('settings/resendInvitation', SettingsService.resendInvitation);

export const createWarehouseAsync =
  WarehouseService.createWarehouseAsync('settings');

export const updateWarehouseAsync =
  WarehouseService.updateWarehouseAsync('settings');

export const deleteWarehouseAsync =
  WarehouseService.deleteWarehouseAsync('settings');

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    clearState: (state) => {
      state.status = 'idle';
      state.errorMessage = undefined;
      state.errors = undefined;
    },
    setOrgId: (state, action: PayloadAction<string>) => {
      state.orgId = action.payload;
    },
  },
  extraReducers: (builder) => {
    HttpService.registerApiAction(builder, createWarehouseAsync);
    HttpService.registerApiAction(builder, updateWarehouseAsync);
    HttpService.registerApiAction(builder, deleteWarehouseAsync);
    HttpService.registerApiAction(builder, sendInvitationsAsync);
    HttpService.registerApiAction(builder, deleteUserAsync);
    HttpService.registerApiAction(builder, deleteInvitationAsync);
    HttpService.registerApiAction(builder, resendInvitationAsync);
    HttpService.registerApiAction(builder, updateBrandInformationAsync);
    HttpService.registerApiAction(builder, updateBillingInformationAsync);
    HttpService.registerApiAction(builder, updateUserDetailsAsync);
    HttpService.registerApiAction(builder, changePasswordAsync);

    HttpService.registerAsyncAction(
      builder,
      fetchUserInfo,
      (payload, state) => {
        state.currentUser = payload;
      }
    );

    HttpService.registerApiAction(
      builder,
      fetchOrganizationUsersAsync,
      (payload, state) => {
        state.users = payload;
      }
    );
  },
});

export const { setOrgId, clearState } = settingsSlice.actions;
export const selectSettings = (state: RootState) => state.settings;
export default settingsSlice.reducer;
