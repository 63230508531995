import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography/Typography';
import DownloadIcon from 'src/assets/icons/download.svg';
import { GetPodByTrackingIdQueryResult } from 'src/services/api';
import { GetParcelModel } from '../../../types/delivery/Delivery';
import { PodFile } from './PodFile';
import { PDFDownloadLink } from '@react-pdf/renderer';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '320px',
    backgroundColor: '#fff',
    padding: '1rem 2rem',
  },
  imageWrapper: {
    display: 'block',
    margin: '2rem 0',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '25rem',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  groupDownloadButton: {
    '&.MuiButton-root': {
      marginBottom: 15,
      minWidth: 200,
      '& a': {
        color: '#000',
        textDecoration: 'none',
      },
    },
  },
  groupDownloadButtonIcon: {
    height: '1.25rem',
    width: 'auto',
  },
}));

interface PodModalProps {
  parcel?: GetParcelModel;
  pod?: GetPodByTrackingIdQueryResult;
}

export const PodContent = ({ parcel, pod }: PodModalProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant='h2' textAlign='center'>
        Proof of Delivery
      </Typography>
      {pod?.senderBusinessName && (
        <Typography textAlign='center'>
          Delivery from
          <br />
          <b>{pod?.senderBusinessName}</b>
        </Typography>
      )}

      {pod?.filesUrls &&
        pod?.filesUrls.length > 0 &&
        pod.filesUrls.map((url) => (
          <>
            <a
              href={url}
              key={url}
              target='_blank'
              className={classes.imageWrapper}
            >
              <img
                className={classes.image}
                src={url}
                alt='proof of delivery'
              />
            </a>
          </>
        ))}
      {pod && parcel && (
        <>
          <br />
          <Button
            className={classes.groupDownloadButton}
            variant='outlined'
            color='inherit'
            endIcon={
              <img
                src={DownloadIcon}
                className={classes.groupDownloadButtonIcon}
                alt='download'
              />
            }
          >
            <PDFDownloadLink
              document={<PodFile parcel={parcel} pod={pod} />}
              fileName='Proof of Delivery.pdf'
            >
              {({ error }) =>
                error
                  ? 'There was en error in loading POD report'
                  : 'Download POD report'
              }
            </PDFDownloadLink>
          </Button>
        </>
      )}
    </Box>
  );
};
