import { useEffect, useState } from 'react';
import { FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import {
  fetchWarehousesAsync,
  selectWarehouse,
} from 'src/features/warehouse/warehouseSlice';
import {
  selectFilter,
  changeWarehouseFilterSelection,
} from 'src/features/dashboard/filters/filtersSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import useStyles from './Filters.styles';
import LoadingButton from 'src/components/LoadingButton';
import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function Filters() {
  const dispatch = useAppDispatch();
  const { status, warehouses } = useAppSelector(selectWarehouse);
  const { warehouseId } = useAppSelector(selectFilter);

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchWarehousesAsync());
  }, []);

  if (status === 'loading') {
    return <LoadingButton loading={true} className={classes.filterLoading} />;
  }

  return (
    <>
      <FormControl className={classes.root} variant='standard'>
        <InputLabel id='warehouse-select-label'>Warehouse filter</InputLabel>
        <Select
          onChange={(e) =>
            dispatch(changeWarehouseFilterSelection(e.target.value as string))
          }
          labelId='warehouse-select-label'
          id='warehouse-select'
          size="small"
          label='Warehouse filter'
          value={warehouseId}
          disabled={warehouses.length < 2}
          IconComponent={FilterListIcon}
          displayEmpty
        >
          <MenuItem key='warehouses' value=''>
            all warehouses
          </MenuItem>
          {warehouses.map((w) => (
            <MenuItem key={`warehouseItem_${w.id}`} value={w.id}>
              {w.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
