import { AsyncThunk } from '@reduxjs/toolkit';
import { fetchWarehousesAsync } from 'src/features/warehouse/warehouseSlice';
import {
  CreateWarehouseCommandParams,
  DeleteWarehouseCommandParams,
  UpdateWarehouseCommandParams,
  Warehouse,
  WarehousesService,
} from './api';
import { HttpService } from './httpService';

export namespace WarehouseService {
  export interface WarehouseWriteModel
    extends CreateWarehouseCommandParams,
      UpdateWarehouseCommandParams {}

  export function createWarehouseAsync(
    sliceName: string
  ): AsyncThunk<Warehouse | undefined, CreateWarehouseCommandParams, any> {
    return HttpService.wrapApiAction(
      `${sliceName}/createWarehouse`,
      WarehousesService.createWarehouse,
      (thunkAPI) => thunkAPI.dispatch(fetchWarehousesAsync())
    );
  }

  export interface UpdateWarehouseRequest extends UpdateWarehouseCommandParams {
    /** Id of modified warehouse */
    id: string;
  }

  export async function updateWarehouse(
    request: UpdateWarehouseRequest
  ): Promise<void> {
    return await WarehousesService.updateWarehouse(request.id, request);
  }

  export function updateWarehouseAsync(
    sliceName: string
  ): AsyncThunk<void | undefined, UpdateWarehouseRequest, any> {
    return HttpService.wrapApiAction(
      `${sliceName}/updateWarehouse`,
      WarehouseService.updateWarehouse,
      (thunkAPI) => thunkAPI.dispatch(fetchWarehousesAsync())
    );
  }

  export interface DeleteWarehouseRequest extends DeleteWarehouseCommandParams {
    id: string;
  }

  export async function deleteWarehouse(
    request: DeleteWarehouseRequest
  ): Promise<void> {
    return await WarehousesService.deleteWarehouse(request.id, request);
  }

  export function deleteWarehouseAsync(
    sliceName: string
  ): AsyncThunk<void | undefined, DeleteWarehouseRequest, any> {
    return HttpService.wrapApiAction(
      `${sliceName}/deleteWarehouse`,
      WarehouseService.deleteWarehouse,
      (thunkAPI) => thunkAPI.dispatch(fetchWarehousesAsync())
    );
  }
}
