/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DropOffAtHubParams } from '../models/DropOffAtHubParams';
import type { DropoffAtHubResponse } from '../models/DropoffAtHubResponse';
import type { DropoffParcelsParams } from '../models/DropoffParcelsParams';
import type { GetParcelForForcefulPickupParams } from '../models/GetParcelForForcefulPickupParams';
import type { GetParcelForForcefulPickupResult } from '../models/GetParcelForForcefulPickupResult';
import type { GetPodByIdQueryResult } from '../models/GetPodByIdQueryResult';
import type { GetPodByIdQueryResultV2 } from '../models/GetPodByIdQueryResultV2';
import type { ParcelCommandParams } from '../models/ParcelCommandParams';
import type { PickupParcelsMasterDriverResult } from '../models/PickupParcelsMasterDriverResult';
import type { PickupParcelsParams } from '../models/PickupParcelsParams';
import type { SenderParcelProjection } from '../models/SenderParcelProjection';
import type { ValidateCodeParams } from '../models/ValidateCodeParams';
import type { ValidateCodeResult } from '../models/ValidateCodeResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParcelsService {

  /**
   * Deletes a parcel with a given ID.
   * @param id ID of the parcel to delete
   * @returns void
   * @throws ApiError
   */
  public static remove(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/parcels/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * @deprecated
   * Updates existing parcel
   * @param id ID of the parcel to update
   * @param requestBody
   * @returns SenderParcelProjection Updated
   * @throws ApiError
   */
  public static updateParcel(
    id: string,
    requestBody: ParcelCommandParams,
  ): CancelablePromise<SenderParcelProjection> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/parcels/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Rejects a parcel on courier's behalf.
   * @param id ID of the assigned parcel to be rejected on behalf of the courier
   * @returns void
   * @throws ApiError
   */
  public static reject(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/{id}/reject',
      path: {
        'id': id,
      },
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * Accepts parcel
   * @param id Id of parcel to accept
   * @returns void
   * @throws ApiError
   */
  public static acceptParcel(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/{id}/accept',
      path: {
        'id': id,
      },
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * Forcefully pickup parcel by the courier
   * @param id Internal or external ID of the parcel to be forcefully pickup
   * @returns void
   * @throws ApiError
   */
  public static pickupForcefully(
    id: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/{id}/pickupForcefully',
      path: {
        'id': id,
      },
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * Changes status to picked up
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static pickupParcel(
    requestBody: PickupParcelsParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/pickup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * @param requestBody
   * @returns PickupParcelsMasterDriverResult Ok
   * @throws ApiError
   */
  public static pickupParcelMasterDriver(
    requestBody: PickupParcelsParams,
  ): CancelablePromise<PickupParcelsMasterDriverResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/pickup/masterDriver',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * Assigns the selected parcels to the specified hub.
   * @param hubId
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static dropOffParcelsAtHub(
    hubId: string,
    requestBody: DropOffAtHubParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/dropoff/hubs/{hubId}',
      path: {
        'hubId': hubId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Assigns the selected parcels to the specified hub.
   * @param hubId
   * @param requestBody
   * @returns DropoffAtHubResponse Ok
   * @throws ApiError
   */
  public static dropOffParcelsAsMasterDriverAtHub(
    hubId: string,
    requestBody: DropOffAtHubParams,
  ): CancelablePromise<DropoffAtHubResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/dropoff/hubs/{hubId}/masterDriver',
      path: {
        'hubId': hubId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Changes status to dropped off
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public static dropoffParcel(
    requestBody: DropoffParcelsParams,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/dropoff',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
        422: `Unprocessable Entity`,
      },
    });
  }

  /**
   * Gets PDF label for parcel
   * @param id ID of the parcel
   * @returns any PDF label document
   * @throws ApiError
   */
  public static getLabel(
    id: string,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/parcels/{id}/label',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * @deprecated
   * Creates new parcel
   * @param requestBody
   * @returns SenderParcelProjection Created
   * @throws ApiError
   */
  public static createParcel(
    requestBody: ParcelCommandParams,
  ): CancelablePromise<SenderParcelProjection> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        422: `Validation error`,
      },
    });
  }

  /**
   * Validates QR code for the parcel
   * @param requestBody Code to validate
   * @returns ValidateCodeResult Ok
   * @throws ApiError
   */
  public static validateCode(
    requestBody: ValidateCodeParams,
  ): CancelablePromise<ValidateCodeResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/validateCode',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * Validates QR code for the parcel for forceful pickup
   * @param requestBody Code to validate
   * @returns GetParcelForForcefulPickupResult Ok
   * @throws ApiError
   */
  public static getParcelForForcefulPickup(
    requestBody: GetParcelForForcefulPickupParams,
  ): CancelablePromise<GetParcelForForcefulPickupResult> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/getForForcefulPickup',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error`,
        403: `Forbidden`,
        404: `Entity not found`,
        422: `Validation error`,
      },
    });
  }

  /**
   * @param id
   * @returns GetPodByIdQueryResult Ok
   * @throws ApiError
   */
  public static getProofOfDeliveryById(
    id: string,
  ): CancelablePromise<GetPodByIdQueryResult> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/parcels/{id}/pod',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * @param parcelId
   * @returns SenderParcelProjection
   * @throws ApiError
   */
  public static getParcel(
    parcelId: string,
  ): CancelablePromise<SenderParcelProjection> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/parcels/{parcelId}',
      path: {
        'parcelId': parcelId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static recalculateDeliveryTime(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/recalculateDeliveryTime',
      errors: {
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param parcelId
   * @returns void
   * @throws ApiError
   */
  public static recalculateDeliveryTimeForParcel(
    parcelId: string,
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/parcels/recalculateDeliveryTime/{parcelId}',
      path: {
        'parcelId': parcelId,
      },
      errors: {
        403: `Forbidden`,
      },
    });
  }

  /**
   * @param id
   * @returns GetPodByIdQueryResultV2 Ok
   * @throws ApiError
   */
  public static getProofOfDeliveryByIdV2(
    id: string,
  ): CancelablePromise<GetPodByIdQueryResultV2> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/parcels/{id}/pod',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

  /**
   * @param parcelId
   * @returns SenderParcelProjection
   * @throws ApiError
   */
  public static getParcelV2(
    parcelId: string,
  ): CancelablePromise<SenderParcelProjection> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/parcels/{parcelId}',
      path: {
        'parcelId': parcelId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Entity not found`,
      },
    });
  }

}