import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { AcceptEulaField } from 'src/components/form/AcceptEulaField';
import { FormPhoneField } from 'src/components/form/FormPhoneField';
import TitledFormTextField from 'src/components/form/TitledFormTextField';
import LoadingButton from 'src/components/LoadingButton';
import LoadingScreen from 'src/components/LoadingScreen';
import { CreateInvitedUserAccountCommandParams } from 'src/services/api';
import { setServerErrors } from 'src/utilities/formHooksHelpers';
import {
  createUserAccountAsync,
  selectAcceptInvitation,
  verifyInvitationKeyAsync,
} from './acceptInvitationSlice';

export function AcceptInvitation() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { verified, status, errors, errorMessage } = useAppSelector(
    selectAcceptInvitation
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { invitationKey } = useParams<{ invitationKey: string }>();

  const form = useForm<CreateInvitedUserAccountCommandParams>({
    defaultValues: {},
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const { watch, setError, setValue } = form;

  const validateForm = (onValid: () => void): Promise<void> => {
    return form.handleSubmit(onValid, () =>
      toast.error('Please correct your form and try again.', {
        hideProgressBar: true,
      })
    )();
  };

  const toastError = useCallback(() => {
    //TODO: Those backend codes should be validation errors
    if (errorMessage === 'Error: TOO_LONG') {
      toast.error('Phone number is too long.');
    } else if (errorMessage === 'Error: TOO_SHORT') {
      toast.error('Phone number is too short.');
    } else {
      toast.error(
        errorMessage === 'validation_error'
          ? 'Please correct your form and try again.'
          : 'An unhandled error occured.',
        { hideProgressBar: true }
      );
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!submitted || status === 'loading') return;

    setSubmitted(false);

    if (status === 'succeeded') {
      toast.success('Account created.', { hideProgressBar: true });
      history.push('/');
    } else if (status === 'failed') {
      toastError();
    }
  }, [status, history, toastError, submitted]);

  const onSubmit = () => {
    validateForm(() => {
      const formValues = form.getValues();
      dispatch(
        createUserAccountAsync({
          ...formValues,
          acceptEula:
            (formValues.acceptEula as any) === 'true' ||
            (formValues.acceptEula as any) === true,
        })
      );
      setSubmitted(true);
    });
  };

  useEffect(() => {
    if (!invitationKey) return;

    dispatch(verifyInvitationKeyAsync({ code: invitationKey }));
  }, [dispatch, invitationKey]);

  useEffect(() => {
    if (verified === true) {
      setLoading(false);
      setValue('invitationCode', invitationKey);
    } else if (verified === false) {
      toast.error('Invitation is not valid.');
      history.push('/');
    }
  }, [verified, setLoading, history, setValue, invitationKey]);

  useEffect(() => {
    if (errors) setServerErrors({ errors, setError });
  }, [errors, setError]);

  if (loading) return <LoadingScreen />;

  return (
    <Dialog
      open={true}
      PaperProps={{
        sx: {
          width: '470px',
        },
      }}
    >
      <DialogTitle sx={{ marginTop: '24px' }}>Accept invitation</DialogTitle>
      <DialogContent sx={{ overflow: 'visible' }}>
        <FormProvider {...form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TitledFormTextField<CreateInvitedUserAccountCommandParams>
                id='firstName'
                TextField={{
                  label: 'First name',
                  placeholder: 'Enter your first name',
                }}
                rules={{
                  required: 'Please provide first name.',
                  minLength: {
                    value: 2,
                    message: 'First name must have at least 2 characters.',
                  },
                  maxLength: {
                    value: 16,
                    message: 'First name must have less than 16 characters.',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TitledFormTextField<CreateInvitedUserAccountCommandParams>
                id='lastName'
                TextField={{
                  label: 'Last name',
                  placeholder: 'Enter your last name',
                }}
                rules={{
                  required: 'Please provide last name.',
                  minLength: {
                    value: 2,
                    message: 'Last name must have at least 2 characters.',
                  },
                  maxLength: {
                    value: 16,
                    message: 'Last name must have less than 16 characters.',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormPhoneField<CreateInvitedUserAccountCommandParams>
                id={'phoneNumber'}
                TextField={{
                  label: 'Phone number',
                  placeholder: '+61 000 000 000',
                }}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TitledFormTextField<CreateInvitedUserAccountCommandParams>
                id='password'
                TextField={{
                  type: 'password',
                  label: 'Password',
                  placeholder: 'Enter password',
                  autoComplete: 'password',
                }}
                rules={{
                  required: 'Please specify a password.',
                  minLength: {
                    value: 8,
                    message: 'Password must have at least 8 characters.',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Password must have less than 50 characters.',
                  },
                }}
                onPaste={(e) => e.preventDefault()}
              />
            </Grid>

            <Grid item xs={12}>
              <TitledFormTextField<any>
                id='confirmPassword'
                TextField={{
                  type: 'password',
                  label: 'Confirm password',
                  placeholder: 'Retype password',
                }}
                rules={{
                  required: true,
                  validate: (value: any) =>
                    value === watch('password') ||
                    'The passwords do not match.',
                }}
                onPaste={(e) => e.preventDefault()}
              />
            </Grid>

            <Grid item xs={12}>
              <AcceptEulaField<CreateInvitedUserAccountCommandParams> fieldId='acceptEula' />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color='primary'
          variant='contained'
          sx={{ paddingTop: '16px', paddingBottom: '16px' }}
          loading={status === 'loading'}
          onClick={onSubmit}
          fullWidth
        >
          Create an account
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
