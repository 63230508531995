import { getAuth } from '@firebase/auth';
import {
  collection,
  DocumentReference,
  getFirestore,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from 'firebase/firestore';

export interface GetDatabaseObjectProps {
  path: string;
}

export interface GetDatabaseQueryProps {
  path: string;
  uidField: string;
}

export async function getCollection<T>({
  path,
  uidField,
}: GetDatabaseQueryProps) {
  const q = query(
    collection(getFirestore(), path),
    where(uidField, '==', getAuth().currentUser?.uid)
  );
  const querySnapshot = await getDocs(q);
  const docs = querySnapshot.docs.map((d) => d.data() as T);
  return docs;
}

export function getRef({ path }: GetDatabaseObjectProps): DocumentReference {
  const docRef = doc(getFirestore(), path);
  return docRef;
}

export async function getValue<T>({
  path,
}: GetDatabaseObjectProps): Promise<T> {
  const docRef = doc(getFirestore(), path);
  const val = await getDoc(docRef);
  return val.data() as T;
}
