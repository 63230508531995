import { createSvgIcon } from '@mui/material/utils';

export const DuplicateIcon = createSvgIcon(
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M4.46509 15.3739H5.90507L5.90508 15.3739C6.25148 15.3739 6.53299 15.6554 6.53299 16.0018C6.53299 16.3482 6.25148 16.6297 5.90508 16.6297H4.4651C3.65615 16.6297 3 15.9735 3 15.1646V4.4651C3 3.65615 3.65617 3 4.4651 3H15.1646C15.5528 3 15.9254 3.15386 16.2006 3.42907C16.4759 3.70432 16.6297 4.07686 16.6297 4.4651V5.90508C16.6297 6.25148 16.3482 6.53299 16.0018 6.53299C15.6554 6.53299 15.3739 6.25148 15.3739 5.90508V4.4651C15.3739 4.40964 15.3519 4.35627 15.3121 4.31755C15.2734 4.27778 15.22 4.25581 15.1646 4.25581H4.46509C4.34998 4.2579 4.25789 4.34999 4.25579 4.4651V15.1646C4.25579 15.2797 4.34998 15.3739 4.46509 15.3739ZM8.83513 7.3703H19.5346C19.9229 7.3703 20.2954 7.52416 20.5707 7.79937C20.8459 8.07462 20.9997 8.44716 20.9997 8.8354V19.5349C20.9997 20.3438 20.3435 21 19.5346 21H8.83513C8.02617 21 7.37003 20.3438 7.37003 19.5349V8.8354C7.37003 8.02645 8.0262 7.3703 8.83513 7.3703ZM19.6822 19.6822C19.7219 19.6435 19.7439 19.5901 19.7439 19.5347V8.83519C19.7439 8.77973 19.7219 8.72635 19.6822 8.68763C19.6435 8.64787 19.5901 8.62589 19.5346 8.62589H8.83513C8.72002 8.62589 8.62583 8.72008 8.62583 8.83519V19.5347C8.62583 19.6498 8.72002 19.744 8.83513 19.744H19.5346C19.5901 19.744 19.6435 19.722 19.6822 19.6822Z'
    fill='black'
  />,
  'Duplicate'
);

export const DeleteIcon = createSvgIcon(
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M15.8862 5.35782H20.3084V5.35793C20.4899 5.35324 20.6651 5.42124 20.7952 5.54787C20.9252 5.6737 20.9992 5.84721 21 6.02933C21 6.41231 20.69 6.72261 20.3084 6.72261H19.5086V20.0866C19.5062 21.1426 18.6542 21.9977 17.602 22H6.41979C5.37228 21.9977 4.52103 21.1488 4.51403 20.0968V6.74518H3.6916C3.30997 6.74518 3 6.4349 3 6.0519C3 5.6689 3.30997 5.35782 3.6916 5.35782H8.11756L8.6861 3.44521C8.94467 2.59167 9.72583 2.00625 10.6144 2H13.3886C14.2772 2.00626 15.0584 2.59169 15.3169 3.44521L15.8862 5.35782ZM10.0234 3.84228L10.0226 3.84149L9.54448 5.35784L14.4525 5.35862L14.0086 3.84542C13.9354 3.57263 13.6885 3.38504 13.4081 3.38738H10.6145C10.3411 3.39675 10.1036 3.57965 10.0234 3.84228ZM18.078 20.2381H18.1262L18.1262 6.72241H5.89343V20.0638C5.89499 20.3569 6.12864 20.5945 6.41991 20.5992H17.5834C17.8069 20.5945 18.0047 20.4507 18.078 20.2381ZM9.3676 16.5162C9.36216 16.8953 9.05452 17.2001 8.6768 17.2001C8.29828 17.2001 7.99065 16.8953 7.9852 16.5162V10.9448C7.9852 10.5618 8.29517 10.2515 8.6768 10.2515C9.05841 10.2515 9.3676 10.5618 9.3676 10.9448V16.5162ZM12.6908 16.5162C12.6861 16.8953 12.3777 17.2001 12 17.2001C11.6223 17.2001 11.3139 16.8953 11.3092 16.5162V10.9448C11.3092 10.5618 11.6184 10.2515 12 10.2515C12.3816 10.2515 12.6908 10.5618 12.6908 10.9448V16.5162ZM15.3232 17.2001C15.7017 17.2001 16.0093 16.8953 16.0148 16.5162V10.9448C16.0148 10.5618 15.7048 10.2515 15.3232 10.2515C14.9416 10.2515 14.6324 10.5618 14.6324 10.9448V16.5162C14.6379 16.8953 14.9455 17.2001 15.3232 17.2001Z'
    fill='#CC4232'
  />,
  'Delete'
);

export const FragileIcon = createSvgIcon(
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M4.19256 3.08154H19.7881H19.7882C20.4507 3.08154 20.9808 3.61165 21 4.2741V19.889C21 20.5514 20.4699 21.0815 19.8074 21.0815H4.19256C3.53011 21.0815 3 20.5514 3 19.889V4.2741C3 3.61165 3.53011 3.08154 4.19256 3.08154ZM4.2872 19.7751H19.6935H19.7128V4.36878H4.2872V19.7751ZM11.3472 16.88V14.6841L11.3472 14.6839C10.4386 14.5516 9.5869 14.1538 8.90596 13.5091C7.99731 12.6574 7.48642 11.4656 7.48642 10.2161V7.30175C7.48642 6.58312 8.07271 5.99608 8.79209 5.99608L15.208 5.99682C15.9266 5.99682 16.5137 6.5831 16.5137 7.30249V10.0846C16.5137 12.4128 14.8295 14.3625 12.6336 14.6841L12.6344 16.88H14.2809C14.6402 16.88 14.9241 17.1639 14.9241 17.5232C14.9241 17.8825 14.6402 18.1664 14.2809 18.1664H9.70069C9.34138 18.1664 9.05747 17.8825 9.05747 17.5232C9.05747 17.1639 9.34138 16.88 9.70069 16.88H11.3472ZM11.044 7.28331L8.79204 7.26482L8.77274 10.2177C8.77274 11.1263 9.13201 11.9588 9.7945 12.5836C10.457 13.2083 11.3087 13.5115 12.2174 13.4545C13.9015 13.3406 15.2264 11.8642 15.2264 10.0853V7.30322L12.3497 7.284L11.9903 8.6843L12.5959 8.68356C12.8036 8.68356 12.9929 8.7782 13.1067 8.92977C13.2398 9.08058 13.2775 9.28908 13.2206 9.47835L12.6905 11.428C12.5959 11.7119 12.3497 11.9012 12.0657 11.9012C12.0096 11.9012 11.9526 11.9012 11.8957 11.882C11.5556 11.7874 11.3471 11.428 11.4418 11.0872L11.7634 9.95158H11.1763C10.9686 9.95158 10.7793 9.85694 10.6654 9.70537C10.5324 9.55456 10.4946 9.34606 10.5516 9.15679L11.044 7.28331Z'
    fill='black'
  />,
  'Fragile'
);

export const PercentIcon = createSvgIcon(
  <>
    <path
      d='M19.7881 3H4.19256C3.53011 3 3 3.53011 3 4.19256V19.8074C3 20.4699 3.53011 21 4.19256 21H19.8074C20.4699 21 21 20.4699 21 19.8074V4.19256C20.9808 3.53011 20.4507 3 19.7882 3H19.7881ZM19.6935 19.6935H4.2872V4.28723H19.7128V19.6935H19.6935Z'
      fill='black'
    />
    <path
      d='M10.9368 8.81V9.275C10.9368 9.945 10.7568 10.465 10.3968 10.835C10.0468 11.195 9.55676 11.375 8.92676 11.375C8.28676 11.375 7.79176 11.195 7.44176 10.835C7.09176 10.465 6.91676 9.945 6.91676 9.275V8.81C6.91676 8.15 7.09176 7.64 7.44176 7.28C7.79176 6.91 8.28676 6.725 8.92676 6.725C9.55676 6.725 10.0468 6.91 10.3968 7.28C10.7568 7.64 10.9368 8.15 10.9368 8.81ZM14.0718 6.8H15.1368L9.91676 17H8.85176L14.0718 6.8ZM9.94676 8.81C9.94676 8.43 9.85676 8.14 9.67676 7.94C9.49676 7.74 9.24676 7.64 8.92676 7.64C8.60676 7.64 8.35676 7.74 8.17676 7.94C7.99676 8.14 7.90676 8.43 7.90676 8.81V9.275C7.90676 9.665 7.99676 9.96 8.17676 10.16C8.35676 10.36 8.60676 10.46 8.92676 10.46C9.24676 10.46 9.49676 10.36 9.67676 10.16C9.85676 9.96 9.94676 9.665 9.94676 9.275V8.81ZM17.0718 14.51V14.975C17.0718 15.645 16.8918 16.165 16.5318 16.535C16.1818 16.895 15.6918 17.075 15.0618 17.075C14.4218 17.075 13.9268 16.895 13.5768 16.535C13.2268 16.165 13.0518 15.645 13.0518 14.975V14.51C13.0518 13.85 13.2268 13.34 13.5768 12.98C13.9268 12.61 14.4218 12.425 15.0618 12.425C15.7018 12.425 16.1968 12.61 16.5468 12.98C16.8968 13.34 17.0718 13.85 17.0718 14.51ZM16.0968 14.51C16.0968 13.73 15.7518 13.34 15.0618 13.34C14.7418 13.34 14.4918 13.44 14.3118 13.64C14.1318 13.84 14.0418 14.13 14.0418 14.51V14.975C14.0418 15.365 14.1318 15.66 14.3118 15.86C14.4918 16.06 14.7418 16.16 15.0618 16.16C15.7518 16.16 16.0968 15.765 16.0968 14.975V14.51Z'
      fill='black'
    />
  </>,
  'Percent'
);

export const WeightIcon = createSvgIcon(
  <path
    d='M4.21962 9.88502L2.07325 19.5524C2.006 19.8545 2.00966 20.1674 2.08397 20.4681C2.15828 20.7687 2.30136 21.0495 2.5027 21.2899C2.70405 21.5302 2.95857 21.724 3.24761 21.8571C3.53665 21.9902 3.85288 22.0592 4.17312 22.059H19.9132C20.2334 22.0592 20.5497 21.9902 20.8387 21.8571C21.1277 21.724 21.3823 21.5302 21.5836 21.2899C21.785 21.0495 21.928 20.7687 22.0023 20.4681C22.0766 20.1674 22.0803 19.8545 22.0131 19.5524L19.8667 9.88502C19.7635 9.42151 19.4988 9.00617 19.1166 8.70833C18.7345 8.41049 18.258 8.24817 17.7668 8.24847H15.7564C16.1355 7.61881 16.336 6.90381 16.3377 6.17552C16.3393 5.44724 16.142 4.73141 15.7657 4.10017C15.3893 3.46894 14.8472 2.9446 14.194 2.58C13.5408 2.21541 12.7995 2.02344 12.0449 2.02344C11.2904 2.02344 10.5491 2.21541 9.8959 2.58C9.24268 2.9446 8.70058 3.46894 8.32423 4.10017C7.94788 4.73141 7.75058 5.44724 7.75221 6.17552C7.75383 6.90381 7.95434 7.61881 8.33351 8.24847H6.31949C5.82829 8.24817 5.35185 8.41049 4.96969 8.70833C4.58753 9.00617 4.32278 9.42151 4.21962 9.88502ZM19.9132 19.9874H4.17312L6.31949 10.32H17.7668L19.9132 19.9874ZM9.89678 6.1769C9.89678 5.76718 10.0227 5.36666 10.2585 5.02599C10.4944 4.68533 10.8296 4.41981 11.2218 4.26301C11.614 4.10622 12.0455 4.0652 12.4619 4.14513C12.8782 4.22506 13.2607 4.42236 13.5609 4.71207C13.861 5.00179 14.0655 5.37091 14.1483 5.77275C14.2311 6.1746 14.1886 6.59112 14.0261 6.96966C13.8637 7.34819 13.5886 7.67172 13.2356 7.89935C12.8826 8.12698 12.4677 8.24847 12.0432 8.24847C11.4739 8.24847 10.928 8.03022 10.5254 7.64172C10.1229 7.25323 9.89678 6.72631 9.89678 6.1769Z'
    fill='#808080'
  />,
  'Weight'
);

export const AddIcon = createSvgIcon(
  <path
    fillRule='evenodd'
    clipRule='evenodd'
    d='M13 4.08203C13 3.52975 12.5523 3.08203 12 3.08203C11.4477 3.08203 11 3.52975 11 4.08203V11.082H4C3.44772 11.082 3 11.5297 3 12.082C3 12.6343 3.44772 13.082 4 13.082H11L11 20.082C11 20.6343 11.4477 21.082 12 21.082C12.5523 21.082 13 20.6343 13 20.082L13 13.082H20C20.5523 13.082 21 12.6343 21 12.082C21 11.5297 20.5523 11.082 20 11.082H13V4.08203Z'
    fill='black'
  />,
  'Add'
);

export const ArrowLeftIcon = createSvgIcon(
  <path
    d='M11.8155 18.988C11.9485 19.1455 12.1435 19.2404 12.3516 19.2492C12.5597 19.2588 12.762 19.1817 12.9097 19.0362C13.0566 18.8915 13.135 18.6922 13.126 18.4873C13.1162 18.2823 13.0199 18.0902 12.86 17.9592L7.53051 12.7037L19.2958 12.7037C19.6907 12.6836 20 12.3621 20 11.9723C20 11.5833 19.6907 11.2618 19.2958 11.2409L7.53051 11.2409L12.8681 5.99256C13.1472 5.70484 13.1472 5.25152 12.8681 4.9638C12.7302 4.82717 12.5417 4.75 12.3459 4.75C12.15 4.75 11.9624 4.82716 11.8236 4.9638L5.22196 11.466L5.22277 11.4652C5.08078 11.6002 5 11.7859 5 11.9796C5 12.1741 5.08079 12.3597 5.22277 12.4939L11.8155 18.988Z'
    fill='black'
  />,
  'ArrowLeft'
);

export const ArrowRightIcon = createSvgIcon(
  <path
    d='M13.1845 5.01201C13.0515 4.85448 12.8565 4.75963 12.6484 4.75081C12.4403 4.74116 12.238 4.81832 12.0903 4.9638C11.9434 5.10847 11.865 5.3078 11.874 5.51275C11.8838 5.7177 11.9801 5.90979 12.14 6.04079L17.4695 11.2963H5.70422C5.30928 11.3164 5 11.6379 5 12.0277C5 12.4167 5.30928 12.7382 5.70422 12.7591H17.4695L12.1319 18.0074C11.8528 18.2952 11.8528 18.7485 12.1319 19.0362C12.2698 19.1728 12.4583 19.25 12.6541 19.25C12.85 19.25 13.0376 19.1728 13.1764 19.0362L19.778 12.534L19.7772 12.5348C19.9192 12.3998 20 12.2141 20 12.0204C20 11.8259 19.9192 11.6403 19.7772 11.5061L13.1845 5.01201Z'
    fill='black'
  />,
  'ArrowRight'
);

export const EditIcon = createSvgIcon(
  <path
    d='M20.8577 5.24697L19.2462 3.63861C18.393 2.78713 17.009 2.78713 16.1748 3.63861L5.25435 14.5565C5.17851 14.6322 5.10267 14.7457 5.06475 14.8592L3.52906 20.0627C3.45322 20.3087 3.52906 20.5926 3.71865 20.7818C3.90824 20.971 4.19263 21.0467 4.4391 20.971L9.65287 19.4194C9.76663 19.3816 9.88038 19.3248 9.95622 19.2302L20.8767 8.33122C21.7109 7.47974 21.7109 6.09845 20.8577 5.24697ZM5.29226 19.1923L6.25918 15.9567C7.07443 16.5622 7.90863 17.4137 8.53428 18.2273L5.29226 19.1923ZM9.72871 17.3948C9.00826 16.4487 8.04134 15.4837 7.09338 14.7646L15.3217 6.55257C16.2696 7.21484 17.2745 8.23661 17.957 9.1827L9.72871 17.3948ZM19.8339 7.29052L18.9808 8.142C18.2603 7.19591 17.2934 6.2309 16.3455 5.51187L17.1986 4.66039C17.483 4.37656 17.938 4.37656 18.2224 4.66039L19.8339 6.26874C20.1183 6.55257 20.1183 7.02562 19.8339 7.29052Z'
    fill='black'
  />,
  'Edit'
);

export const UploadIcon = createSvgIcon(
  <>
    <path d='M20,6.52897986 L20,19.5010024 C20,20.8817143 18.8807119,22.0010024 17.5,22.0010024 L6.5,22.0010024 C5.11928813,22.0010024 4,20.8817143 4,19.5010024 L4,4.50100238 C4,3.1202905 5.11928813,2.00100238 6.5,2.00100238 L15.4720225,2.00100238 C15.6047688,1.99258291 15.7429463,2.03684187 15.8535534,2.14744899 L19.8535534,6.14744899 C19.9641605,6.25805611 20.0084195,6.39623363 20,6.52897986 Z M15,3.00100238 L6.5,3.00100238 C5.67157288,3.00100238 5,3.67257525 5,4.50100238 L5,19.5010024 C5,20.3294295 5.67157288,21.0010024 6.5,21.0010024 L17.5,21.0010024 C18.3284271,21.0010024 19,20.3294295 19,19.5010024 L19,7.00100238 L15.5,7.00100238 C15.2238576,7.00100238 15,6.77714475 15,6.50100238 L15,3.00100238 Z M16,3.70810916 L16,6.00100238 L18.2928932,6.00100238 L16,3.70810916 Z M12,10.7071068 L12,17.5 C12,17.7761424 11.7761424,18 11.5,18 C11.2238576,18 11,17.7761424 11,17.5 L11,10.7071068 L8.85355339,12.8535534 C8.65829124,13.0488155 8.34170876,13.0488155 8.14644661,12.8535534 C7.95118446,12.6582912 7.95118446,12.3417088 8.14644661,12.1464466 L11.1464466,9.14644661 C11.3417088,8.95118446 11.6582912,8.95118446 11.8535534,9.14644661 L14.8535534,12.1464466 C15.0488155,12.3417088 15.0488155,12.6582912 14.8535534,12.8535534 C14.6582912,13.0488155 14.3417088,13.0488155 14.1464466,12.8535534 L12,10.7071068 Z' />
  </>,
  'Upload'
);
