import React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography, CircularProgress, Tooltip } from '@mui/material';
import {
  DispatchingParcel,
  getStatusClassName,
  getStatusName,
  ParcelDeliveryType,
  ParcelStatus,
} from 'src/types/delivery/Delivery';

import useStyles from '../Dashboard.styles';
import DisplayTable, {
  formatAddress,
  formatDateString,
  formatReferenceNumbers,
  GridColDef,
  SpacerGridCol,
} from 'src/components/table';
import noPackagesImg from 'src/assets/no-packages.png';
import { MuiNavigationLink } from 'src/components/MuiLink';
import { useParcelSubscriptionForOrg } from 'src/components/hooks/useSubscription';
import InfoIcon from '@mui/icons-material/Info';
import { getLocalTimeFromTimestamp } from 'src/components/table/valueFormatters';
import moment from 'moment';

export default function Dispatching() {
  const classes = useStyles();
  const cursors = useRef<Map<number, string>>(new Map());
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [dispatchItems, setDispatchItems] = useState<DispatchingParcel[]>([]);

  const {
    value: deliveries,
    count,
    isLoading,
    lastUpdateTimestamp,
  } = useParcelSubscriptionForOrg<DispatchingParcel>(
    `projections/web/webParcelProjections`,
    itemsPerPage,
    cursors.current.get(page),
    [
      {
        field: 'status',
        operator: 'in',
        value: [ParcelStatus.ReadyForPickup, ParcelStatus.DriverAssigned],
      },
    ]
  );

  const parcels = useMemo(() => {
    return deliveries;
  }, [deliveries]);

  const onRowsPerPageChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage((page) => {
      cursors.current.set(
        (page = page + 1),
        deliveries[deliveries.length - 1].creationDate
      );
      return 0;
    });
  };

  const onPageChanged = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage((page) => {
      cursors.current.set(
        (page = page + 1),
        deliveries[deliveries.length - 1].creationDate
      );
      return newPage;
    });
  };

  useEffect(() => {
    if (!parcels) return;
    setDispatchItems(Object.values<DispatchingParcel>(parcels));
  }, [lastUpdateTimestamp]);

  const columns: GridColDef<DispatchingParcel>[] = [
    {
      field: 'courierData.fullName',
      headerName: 'Courier',
      width: '10%',
      valueGetter: (row: DispatchingParcel, groupIndex?: number) =>
        row.courierData?.fullName || (
          <Box sx={{ color: '#BFBFBF' }}>not yet assigned</Box>
        ),
    },
    { field: 'orderId', headerName: 'Order ID', width: '9%' },
    {
      field: 'id',
      headerName: 'Package ID',
      width: '9%',
      valueGetter: (row: DispatchingParcel, groupIndex?: number) => {
        const id = groupIndex ? row.courierParcels[groupIndex - 1].id! : row.id;
        return (
          <MuiNavigationLink navigationTarget={`/deliveries/${id}`}>
            {id}
          </MuiNavigationLink>
        );
      },
    },
    {
      field: 'trackingId',
      headerName: 'Tracking ID',
      width: '6%',
      valueGetter: (row: DispatchingParcel) => {
        return (
          <a target='_blank' href={`/tracking/${row.trackingId}`}>
            {row.trackingId}
          </a>
        );
      },
    },
    {
      field: 'referenceNumber',
      headerName: 'Reference No.',
      width: '9%',
      valueGetter: (row: DispatchingParcel) => formatReferenceNumbers(row),
    },
    {
      field: 'pickupAddress',
      headerName: 'Pickup address',
      width: '14%',
      valueGetter: (row: DispatchingParcel) =>
        formatAddress(row.parcelLocation?.pickupAddress),
    },
    {
      field: 'dropoffAddress',
      headerName: 'Dropoff address',
      width: '14%',
      valueGetter: (row: DispatchingParcel) =>
        formatAddress(row.parcelLocation?.shippingAddress),
    },
    { field: 'receiverData.fullName', headerName: 'Receiver', width: '10%' },
    {
      field: 'creationDate',
      headerName: 'Creation time',
      valueGetter: (row: DispatchingParcel) =>
        formatDateString(row.creationDate),
    },
    {
      field: 'deliveryType',
      headerName: 'Delivery Type',
      width: '7%',
      valueGetter: (row: any) => {
        const deliveryType =
          row.deliveryType === ParcelDeliveryType.Sameday
            ? 'Same-Day'
            : 'Next-Day';
        const cutoffTime = getLocalTimeFromTimestamp(
          row?.deadlineTime?.cutoff?.seconds
        );
        const dueTime = getLocalTimeFromTimestamp(row?.deadline?.seconds);
        const cutoffTimeFormatted = moment(cutoffTime).format('h:mm A');
        const dueTimeFormatted =
          moment(dueTime).format('DD/MM/YYYY h:mm A') || 'No ETA';

        const tooltipContent = (
          <span>
            Cutoff: {cutoffTimeFormatted} <br /> Delivery before:{' '}
            {dueTimeFormatted}
          </span>
        );
        const tooltip = (
          <Tooltip title={tooltipContent}>
            <span>
              {deliveryType}
              <InfoIcon className={classes.tooltipIcon} />
            </span>
          </Tooltip>
        );
        return tooltip;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      labelClassName: (row) =>
        getStatusClassName(row.status, row.paymentStatus, row.paymentMethod),
      valueGetter: (row: DispatchingParcel) =>
        getStatusName(row.status, row.paymentStatus, row.paymentMethod),
    },
  ];

  const groupByColumns: GridColDef<DispatchingParcel>[] = [
    {
      field: 'courierData.fullName',
      headerName: 'Courier',
      valueGetter: (row: DispatchingParcel, groupIndex?: number) =>
        row.courierData?.fullName || (
          <Box sx={{ color: '#BFBFBF' }}>not yet assigned</Box>
        ),
    },
    new SpacerGridCol(7),
    {
      field: 'status',
      headerName: 'Status',
      labelClassName: (row) =>
        getStatusClassName(row.status, row.paymentStatus, row.paymentMethod),
      valueGetter: (row: DispatchingParcel) =>
        getStatusName(row.status, row.paymentStatus, row.paymentMethod),
    },
  ];

  return (
    <>
      {isLoading ? (
        <>
          <Typography
            variant='body1'
            className={classes.circularProgressBox}
            component={'div'}
          >
            <CircularProgress />
          </Typography>
        </>
      ) : (
        <>
          {dispatchItems.length > 0 ? (
            <Box>
              <DisplayTable<DispatchingParcel>
                idColumn={'id'}
                rows={dispatchItems}
                columns={columns}
                groupBy={'courierParcels'}
                groupByColumns={groupByColumns}
                totalItems={count}
                currentPage={page}
                handleChangePage={onPageChanged}
                rowsPerPage={itemsPerPage}
                handleChangeRowsPerPage={onRowsPerPageChanged}
              />
            </Box>
          ) : (
            <Box
              m={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img src={noPackagesImg} alt='No deliveries' />
              <Typography variant='h2'>No deliveries to dispatch</Typography>
              <Typography
                variant='subtitle2'
                color='#808080'
                sx={{ marginBottom: 3 }}
              >
                You will find all orders ready for dispatch here.
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}
