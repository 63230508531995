import { useAppSelector } from 'src/app/hooks';
import { selectAppUser } from 'src/features/user/appUserSlice';

export function RoleRestricted<P>(
  WrappedComponent: React.ReactElement<P>,
  requiredRole: string
) {
  const { userOrganizationRole } = useAppSelector(selectAppUser);
  const isOwner = userOrganizationRole?.includes(requiredRole);

  if (!isOwner) {
    return null;
  } else {
    return WrappedComponent;
  }
}
