import React from 'react';
import throttle from 'lodash/throttle';
import { TextField, Grid, Typography, Box, Autocomplete } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import { useStyles as textFieldStyles } from '../form/TitledFormTextField';
import { loadScript } from 'src/layouts/dashboard/Dashboard';
import { LocationIcon } from './LocationIcon';
import { useStyles } from './PlaceAutocompleteField.styles';
import {
  PlaceAutocompleteFieldProps,
  PlacesFetchCallbackType,
  PlaceType,
} from './types';
import { getLatLng } from './helpers';
import { config } from 'src/config';

declare global {
  interface Window {
    google: typeof google;
  }
}

const autocompleteService: {
  current: google.maps.places.AutocompleteService | null;
} = { current: null };

export function PlaceAutocompleteField({
  onSelect,
  label,
  placeholder,
  helperText,
  hasError,
  initialValue: value,
  countryRestrictions,
}: PlaceAutocompleteFieldProps) {
  const classes = useStyles();
  const textFieldClasses = textFieldStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);

  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBa8nM57eY8w5mWNK1uSvOpYbyKcKtKbuc&libraries=places',
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: PlacesFetchCallbackType
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback);
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    const componentRestrictions = {
      country: countryRestrictions ?? ['au', 'pl'],
    };

    fetch(
      {
        input: inputValue,
        location: new google.maps.LatLng({
          lat: config.addressPreference.lat,
          lng: config.addressPreference.lng,
        }),
        radius: config.addressPreference.radius,
        componentRestrictions: componentRestrictions,
      },
      (results) => {
        if (active) {
          let newOptions = [] as PlaceType[];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...(results as PlaceType[])];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, countryRestrictions]);

  const handleChange = async (newValue: PlaceType | null) => {
    if (newValue !== null) {
      const coords = await getLatLng({ placeId: newValue?.place_id });
      newValue.location = coords;
    }
    setOptions(newValue ? [newValue, ...options] : options);
    onSelect && onSelect(newValue);
  };

  return (
    <Autocomplete
      id='google-map-demo'
      style={{ width: '100%' }}
      getOptionLabel={(option) =>
        typeof option === 'string'
          ? option || ''
          : option.description || option.formatted_address || ''
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(_, v) => handleChange(v)}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <Box
          className={textFieldClasses.inputCol}
          onClick={(e) => e.stopPropagation()}
          onBlur={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Box className={textFieldClasses.textFieldName}>{label}</Box>
          <Box className={textFieldClasses.inputGroup}>
            <TextField
              {...params}
              placeholder={placeholder}
              error={hasError}
              helperText={helperText}
              size='small'
              variant='outlined'
              fullWidth
              className={textFieldClasses.textField}
            />
          </Box>
        </Box>
      )}
      renderOption={(props, option) => {
        if (!option || !option.structured_formatting) return null;

        const matches =
          option.structured_formatting.main_text_matched_substrings;
        let parts = undefined;

        if (matches) {
          parts = parse(
            option.structured_formatting.main_text,
            matches &&
              matches.map((match) => [
                match?.offset,
                match?.offset + match?.length,
              ])
          );
        }

        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item>
                <LocationIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts &&
                  parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                <Typography variant='body2' color='textSecondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
