import { createSlice } from '@reduxjs/toolkit';
import { IStateWithStatus } from 'src/app/hooks';
import { RootState } from 'src/app/store';
import {
  CreateInvitedUserAccountCommandParams,
  InvitationsService,
  OrganizationsService,
  VerifyInvitationCommandParams,
  VerifyInvitationResponse,
} from 'src/services/api';
import { HttpService } from 'src/services/httpService';

export interface AcceptInvitationState extends IStateWithStatus {
  verified?: boolean;
}

const initialState: AcceptInvitationState = {
  status: 'idle',
  errorMessage: undefined,
  errors: undefined,
};

export const verifyInvitationKeyAsync = HttpService.wrapApiAction<
  VerifyInvitationResponse,
  VerifyInvitationCommandParams
>('acceptInvitation/verify', InvitationsService.verifyInvitation);

export const createUserAccountAsync = HttpService.wrapApiAction<
  void,
  CreateInvitedUserAccountCommandParams
>('acceptInvitation/createUserAccount', OrganizationsService.register);

export const acceptInvitationSlice = createSlice({
  name: 'acceptInvitation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    HttpService.registerApiAction(
      builder,
      verifyInvitationKeyAsync,
      (payload, state) => {
        state.verified = true;
      },
      (payload, state) => {
        state.verified = false;
      }
    );

    HttpService.registerApiAction(
      builder,
      createUserAccountAsync,
      () => {},
      (payload, state) => {
        state.errorMessage = payload?.body?.errorMessage;
      }
    );
  },
});

export const selectAcceptInvitation = (state: RootState) =>
  state.acceptInvitation;
export default acceptInvitationSlice.reducer;
